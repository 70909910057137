<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.key = v; delete serverErrors.key}" 
                    :valueInput="values.key" 
                    :rule="[...rules.required]" 
                    label="Clave"
                    :errorMessages="serverErrors.key"
                    data-cy="key-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Unidad de medida"
                    :loading="loadingUnits"
                    :valueInput="values.unit_id"
                    :rule="rules.required"
                    :items="serverItems.units"
                    :errorMessages="serverErrors.unit_id"
                    @valueChange="(v) => {values.unit_id = v; delete serverErrors.unit_id}"
                    data-cy="unit_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.base_price = v; delete serverErrors.base_price}" 
                    :valueInput="values.base_price" 
                    :rule="rules.cost" 
                    label="Precio base"
                    :errorMessages="serverErrors.base_price"
                    :prefix="values.base_price === '' ? '' : '$'"
                    data-cy="base_price-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Insumos</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="9" sm="10"  xl="11">
                 <autocompleteOneForm
                    label="Seleccione un insumo"
                    :loading="loadingSupplies"
                    :valueInput="supplySelected"
                    :rule="[]"
                    :items="serverItems.supplies"
                    :errorMessages="''"
                    @valueChange="(v) => {supplySelected = v;}"
                    data-cy="selectSupply-input"
                ></autocompleteOneForm>
   
            </v-col>
            <v-col class="py-2 px-0 text-center">
                <iconButton
                    data-cy="selectSupply-input-btn"
                    :props="{
                    color:'primary',
                    icon:'mdi-plus',
                    height:'41',
                    
                    click: () => {addSupply();delete serverErrors.supplies}
                    }"
                ></iconButton>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.supplies">
                    
                    

                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.supplies}" 
                                :valueInput="props.item.quantity" 
                                :rule="[...rules.quantity]" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`supplies`]">{{serverErrors[`supplies`]}}</span>
      
      

       
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingUnits: false,
            loadingSupplies: false,
            supplySelected: "",
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,999})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

            },

            serverItems:{
                units: [],
                // currencies: [
                //     {value: "mxn", text: "Peso mexicano (MXN)"},
                //     {value: "usd", text: "Dólar estadounidense (USD)"},
                //     {value: "eur", text: "Euro (EUR)"},
                //     {value: "cad", text: "Dólar canadiense (CAD)"},
                // ],
                supplies: []
            },
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Nombre',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            

          
        }
    },
   
    mounted(){
        this.loadSupplies();
        this.getUnits();
        this.getSupplies();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        loadSupplies()
        {
            let backup = JSON.parse(JSON.stringify(this.values.supplies));
            this.values.supplies = [];
            backup.forEach(item => {
                let newSupply = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.supply_id, name: item.supply, index: 99},
                    supply_id: item.supply_id,
                    name: item.supply,
                    quantity : item.quantity,   //item.quantity,
                }
                console.log(newSupply)
                this.values.supplies.push(newSupply);
            });
        },
        getUnits()
        {
            this.loadingUnits = true;
            this.$api.unit.getSelect()
                .then((resp) => {
                    this.serverItems.units = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUnits = false;
                })
            ;
        },

        getSupplies()
        {
            this.loadingSupplies = true;
            this.$api.supply.getSelect()
                .then((resp) => {
                    this.serverItems.supplies = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSupplies = false;
                })
            ;
        },


        addSupply()
        {
            if(this.supplySelected == undefined || this.supplySelected == "")
                return;

            let already = this.values.supplies.find(sItem => sItem.supply_id == this.supplySelected)
            if(already != undefined)
                return;

            let found = this.serverItems.supplies.find(sItem => sItem.value == this.supplySelected)
            console.log(this.serverItems.supplies, this.supplySelected)
            if(found == undefined)
                return;

            let item = this.supplySelected;
            let newSupply = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item, name: found.text, index: 99},
                supply_id: item,
                name: found.text,
                quantity : 1,   //item.quantity,
            }

            this.values.supplies.push(newSupply);

        },
        deleteItem(item)
        {
            let index = this.values.supplies.findIndex(prod => prod.supply_id == item.id);

            console.log(index, item);
            this.values.supplies.splice(index, 1);
        }
       

    }
}
</script>

<style>

</style>