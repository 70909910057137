<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.sku = v; delete serverErrors.sku}" 
                    :valueInput="values.sku" 
                    :rule="[...rules.required]" 
                    label="SKU"
                    :errorMessages="serverErrors.sku"
                    data-cy="sku-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.description = v; delete serverErrors.description}" 
                    :valueInput="values.description" 
                    :rule="[...rules.required]" 
                    label="Descripción"
                    :errorMessages="serverErrors.description"
                    data-cy="description-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Unidad de medida"
                    :loading="loadingUnits"
                    :valueInput="values.unit_id"
                    :rule="rules.required"
                    :items="serverItems.units"
                    :errorMessages="serverErrors.unit_id"
                    @valueChange="(v) => {values.unit_id = v; delete serverErrors.unit_id}"
                    data-cy="unit_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Departamento"
                    :loading="loadingDepartments"
                    :valueInput="values.department_id"
                    :rule="rules.required"
                    :items="serverItems.departments"
                    :errorMessages="serverErrors.department_id"
                    @valueChange="(v) => {values.department_id = v; delete serverErrors.department_id}"
                    data-cy="department_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo"
                    :loading="loadingTypes"
                    :valueInput="values.type_id"
                    :rule="rules.required"
                    :items="serverItems.types"
                    :errorMessages="serverErrors.type_id"
                    @valueChange="(v) => {values.type_id = v; delete serverErrors.type_id}"
                    data-cy="type_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.initial_cost = v; delete serverErrors.initial_cost}" 
                    :valueInput="values.initial_cost" 
                    :rule="rules.initial_cost" 
                    label="Precio inicial"
                    :errorMessages="serverErrors.initial_cost"
                    :prefix="values.initial_cost === '' ? '' : '$'"
                    data-cy="initial_cost-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.availability_policy = v; delete serverErrors.availability_policy}" 
                    :valueInput="values.availability_policy" 
                    :rule="[...rules.availability_policy]" 
                    label="Política de disponibilidad"
                    :errorMessages="serverErrors.availability_policy"
                    data-cy="availability_policy-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proveedor"
                    :loading="loadingSuppliers"
                    :valueInput="values.supplier_id"
                    :rule="rules.required"
                    :items="serverItems.suppliers"
                    :errorMessages="serverErrors.supplier_id"
                    @valueChange="(v) => {values.supplier_id = v; delete serverErrors.supplier_id}"
                    data-cy="supplier_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.brand = v; delete serverErrors.brand}" 
                    :valueInput="values.brand" 
                    :rule="[...rules.required]" 
                    label="Marca"
                    :errorMessages="serverErrors.brand"
                    data-cy="brand-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.model = v; delete serverErrors.model}" 
                    :valueInput="values.model" 
                    :rule="[...rules.required]" 
                    label="Modelo"
                    :errorMessages="serverErrors.model"
                    data-cy="model-input"
                ></textFieldForm>
            </v-col>
        </v-row>


       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showPass: false,
            loadingDepartments: false,
            loadingUnits: false,
            loadingTypes: false,
            loadingSuppliers: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                availability_policy: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
                departments: [],
                units: [],
                types: [],
                suppliers: [],
            }
            

          
        }
    },
   
    mounted(){
        this.getDepartments();
        this.getUnits();
        this.getTypes();
        this.getSuppliers();
    },
    methods: {
        getSuppliers()
        {
            this.loadingSuppliers = true;
            this.$api.supplier.getSelect()
                .then((resp) => {
                    this.serverItems.suppliers = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSuppliers = false;
                })
            ;
        },

        getTypes()
        {
            this.loadingTypes = true;
            this.$api.type.getSelect()
                .then((resp) => {
                    this.serverItems.types = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingTypes = false;
                })
            ;
        },

        getDepartments()
        {
            this.loadingBusiness = true;
            this.$api.department.getSelect()
                .then((resp) => {
                    this.serverItems.departments = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBusiness = false;
                })
            ;
        },

        getUnits()
        {
            this.loadingUnits = true;
            this.$api.unit.getSelect()
                .then((resp) => {
                    this.serverItems.units = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUnits = false;
                })
            ;
        }
       
    }
}
</script>

<style>

</style>