<template>
    <div>
       
        <v-row>
            <v-col class="py-2 mt-5">
                <textFieldForm 
                    @valueChange="(v) => {values.project_name = v; delete serverErrors.project_name}" 
                    :valueInput="values.project_name" 
                    :rule="[...rules.required]" 
                    label="Nombre del proyecto"
                    :errorMessages="serverErrors.project_name"
                    data-cy="project_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">DATOS GENERALES DEL PRODUCTO</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.general_description = v; delete serverErrors.general_description}" 
                    :valueInput="values.general_description" 
                    :rule="[...rules.required]" 
                    label="Descripción general"
                    :errorMessages="serverErrors.general_description"
                    data-cy="general_description-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row class="mx-0">
            <v-col cols="12" sm="6">
                <v-radio-group
                    v-model="values.business"
                    mandatory
                    label="Giro"
                    data-cy="business-check-input"
                >
                    <v-row>
                        <v-col cols="6" class="py-2">
                            <radioItemForm
                                label="Industrial"
                                valueInput="INDUSTRIAL"
                                title=""
                                data-cy="type-input-industrial-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                        <v-col cols="6" class="py-2">
                            <radioItemForm
                                label="Comercial"
                                valueInput="COMERCIAL"
                                title=""
                                data-cy="type-input-comercial-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                    </v-row>
                </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6">
                <v-radio-group
                    v-model="values.product_type"
                    mandatory
                    label="Tipo de producto"
                    data-cy="product_type-check-input"
                >
                <v-row>
                        <v-col cols="6" class="py-2">
                            <radioItemForm
                                label="Terminado"
                                valueInput="Terminado"
                                title=""
                                data-cy="type-input-terminado-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                        <v-col cols="6" class="py-2">
                            <radioItemForm
                                label="Materia prima"
                                valueInput="Materia prima"
                                title=""
                                class="nowrap"
                                data-cy="type-input-materia_prima-value"
                            >
                            </radioItemForm>
                            
                            
                        </v-col>
                </v-row>
                </v-radio-group>
            </v-col>
            
        </v-row>


        <!-- <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Almacén"
                    :loading="loadingWarehouses"
                    :valueInput="values.warehouse_id"
                    :rule="rules.required"
                    :items="serverItems.warehouses"
                    :errorMessages="serverErrors.warehouse_id"
                    @valueChange="(v) => {values.warehouse_id = v; delete serverErrors.warehouse_id}"
                    data-cy="warehouse_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row> -->

       

        


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.product_rotation = v; delete serverErrors.product_rotation}" 
                    :valueInput="values.product_rotation" 
                    :rule="[...rules.required]" 
                    label="Rotación del producto"
                    :errorMessages="serverErrors.product_rotation"
                    data-cy="product_rotation-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Servicios contratados</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteOneForm

                    label="Prospecto"
                    :loading="loadingProspects"
                    :valueInput="values.prospect_id"
                    :rule="[]"
                    :items="serverItems.prospects"
                    :return-object="false"
                    :disabled="edit"
                    @valueChange="(v) => {values.prospect_id = v; this.values.services=[]; this.getFees(v); delete serverErrors.supplier_id}"
                    data-cy="prospect_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <autocompleteOneForm
                    data-cy="fee_id-input"
                    label="Cotizaciones"
                    :loading="loadingFees"
                    :valueInput="values.folio"
                    :rule="[]"
                    :items="serverItems.fees"
                    :return-object="true"
                    :disabled="edit"
                    @valueChange="(v) => {values.folio = v.value; values.fee_id = v.id; this.values.services=[]; this.setServices(v.value); delete serverErrors.folio}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

         <!-- <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Almacén"
                    :loading="loadingWarehouses"
                    :valueInput="values.warehouse_eon_id"
                    :rule="[]"
                    :items="serverItems.warehouses"
                    
                    @valueChange="(v) => {values.warehouse_eon_id = v; delete serverErrors.warehouse_eon_id}"
                    :disabled="false"
                    data-cy="warehouse_eon_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente EON"
                    :loading="loadingClients"
                    :valueInput="values.client_eon_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.client_eon_id"
                    @valueChange="(v) => {values.client_eon_id = v; delete serverErrors.client_eon_id}"
                    data-cy="client_eon_id-input"
                    
                ></autocompleteOneForm>
            </v-col>
        </v-row>



       <v-row justify="space-between">
            <v-col class="py-2" cols="9" sm="10"  xl="10">
                <autocompleteOneForm
                    ref="serviSelect"
                    label="Servicio"
                    :loading="loadingServices"
                    :valueInput="serviceSelected"
                    :rule="[...rules.servicesLen]"
                    :items="serverItems.services"
                    :return-object="true"
                    :disabled="servicesUse"
                    @valueChange="(v) => {serviceSelected = v; delete serverErrors.services}"
                    data-cy="productSelected-input"
                ></autocompleteOneForm>
            </v-col>

           
            <v-col class="py-2 text-center">
                <iconButton
                    data-cy="productSelected-input-btn"
                    :props="{
                    disabled:servicesUse,
                    color:'primary',
                    icon:'mdi-plus',
                    height:'41',
                    click: () => {addService()}
                    }"
                ></iconButton>
            </v-col>
        
        </v-row>

        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-services" :props="tableProps" @methodHandler="method_handler" :items="values.services">
                    <template v-slot:[`item.price`]="props" >

                        <div class="table-item">
                            
                            <textFieldForm 
                                @valueChange="(v) => {props.item.price = v; delete serverErrors.products}" 
                                :valueInput="props.item.price" 
                                :rule="[...rules.initial_cost]" 
                                data-cy="table-price-input"
                                :disabled="((hasSKU)||servicesUse)"
                            ></textFieldForm>
                        </div>
                    </template>
                   
                </table-items>
            </v-col>
        
        </v-row>

        

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'controlKey', 'parentEditMode', 'edit'],
    computed: {
       

        controlServices: {
            get: function () {
                return this.controlKey;
            },
            set: function (value) {
                //console.log(value)
                //  this.$emit('tabChanged', value)
                //this.tab = stepIn;
            },
        },
        servicesUse:{
            get: function () {
                
                if(this.values.prospect_id != "" && this.values.prospect_id != null)
                    return true;
                if(this.values.fee_id != "" && this.values.fee_id != null)
                    return true;

                if(this.values.sku != "" && this.values.sku != null)
                    return true;
                

                return false;
            },
        },
        hasSKU:{
            get: function () {
                
                if(this.values.sku != "" && this.values.sku != null)
                    return true;


               

                return false;
            },
        }
       
    },
    data(){
        return {
           
            showPass: false,
            loadingWarehouses:false,
            loadingClients:false,
            loadingDepartments: false,
            loadingUnits: false,
            loadingTypes: false,
            loadingSuppliers: false,
            loadingServices:false,
            loadingProspects: false,
            loadingFees: false,
            loadingFeeData: false,
            serviceSelected:{},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                availability_policy: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                servicesLen: [
                    v => {
                        if(this.values.services.length == 0)
                            return 'Al menos un servicio';
                       
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
                services: [],
                prospects: [],
                fees:[],
                warehouses:[],
                clients:[],
            },

            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    
				},
			],
            tableProps: {
				headers: [
                     {
						text: '',
						align: 'left break-words',
						value: 'service',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: '',
						align: 'center',
						value: 'price',
						sortable: false,
                        width: "15%",
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortable:false,
                hideHeader:true,
			},
            

          
        }
    },
    
   
   
    mounted(){
        // this.getDepartments();
        // this.getUnits();
        // this.getTypes();
        // this.getSuppliers();
        // this.getServices();
        this.getProspects();
        this.getWarehouses();
        console.log(this.edit, "edit")
        console.log(this.values, "data")
        if(this.edit){
            this.loadServices();
            this.getEditClient(this.values.folio);
            this.loadStaticData();
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        loadStaticData()
        {
            console.log(this.values, "staticcc");
            if(this.values.sku != ""){
                this.serverItems.prospects.push({text: this.values.prospect, value: this.values.prospect_id});

            }
                this.serverItems.fees.push({text: this.values.folio, value: this.values.folio});


            console.log(this.serverItems.prospects, "pros");

        },
        getServices()
        {
            this.loadingServices = true;
            this.$api.service.getSelect()
                .then((resp) => {
                    this.serverItems.services = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingServices = false;
                })
            ;
        },
        getProspects()
        {
            this.serverItems.prospects = [];
            this.loadingProspects = true;
            this.$api.prospect.getSelectWithFee()
                .then((resp) => {
                    this.serverItems.prospects.push(...resp.data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProspects = false;
                })
            ;
        },
        getFees(prospect_id)
        {
            
            if(prospect_id == "" || prospect_id == undefined || prospect_id == null)
                return;

            this.loadingFees = true;
            this.$api.fee.byProspect(prospect_id)
                .then((resp) => {
                    this.serverItems.fees = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingFees = false;
                })
            ;
        },

        getWarehouses()
        {
            // this.loadingWarehouses = true;
            // this.$api.warehouse.getSelect()
            //     .then((resp) => {
            //         this.serverItems.warehouses = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingWarehouses = false;
            //     })
            // ;
            this.serverItems.warehouses = this.$store.state.userData.warehousesRaw;
        },
        getClients(warehouse_eon_id = "")
        {
            console.log(warehouse_eon_id);
            if(warehouse_eon_id == "" || warehouse_eon_id == null || warehouse_eon_id == undefined)
                return;


            this.loadingClients = true;
            this.$api.client.getSelectEON(warehouse_eon_id)
                .then((resp) => {
                    //hacer map
                    this.serverItems.clients = resp.data;
                    console.log(this.values.client_eon_id)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        setServices(fee_id)
        {
            if(fee_id == "" || fee_id == undefined || fee_id == null)
                return;

            this.loadingFeeData = true;
            this.tableProps.loading = true;
            this.$api.fee.getDetails(fee_id)
                .then((resp) => {
                    
                    //set warehouse
                    this.values.warehouse_eon_id = resp.data.data.warehouse_id;
                    //set services
                    this.values.services = resp.data.services.map(serv => {

                        let item = {
                            actions: JSON.parse(JSON.stringify(this.actions)),
                            parameters: { id: serv.service, name: serv.service, index: 99},
                            service_id: serv.service,
                            service: serv.service,
                            price : serv.amount.replace(",",""),
                        }
                        item.actions[0].disabled = true;
                        return item;
                    });

                    this.getClients(this.values.warehouse_eon_id);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingFeeData = false;
                    this.tableProps.loading = false;
                })
            ;
        },

        getEditClient(fee_id){
            if(fee_id == "" || fee_id == undefined || fee_id == null)
                return;

            this.loadingFeeData = true;
            this.tableProps.loading = true;
            this.$api.fee.getDetails(fee_id)
                .then((resp) => {
                    
                    //set warehouse
                    this.values.warehouse_eon_id = resp.data.data.warehouse_id;
                    //set services
                   
                    this.getClients(this.values.warehouse_eon_id);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingFeeData = false;
                    this.tableProps.loading = false;
                })
            ;
        },
        
        
        loadServices(){
            this.values.services = this.values.services.map(item => {
                console.log(item);
                let newServ = {
                    ...item,
                    text: item.service??item.text,
                    parameters: { id: item.service_id, name: item.text??item.service, index: 99},
                    actions: JSON.parse(JSON.stringify(this.actions)),
                }
                if(this.hasSKU)
                    newServ.actions[0].disabled = true;
                return newServ;
            })
        },

        addService()
        {
            console.log(this.serviceSelected)
            let fon = this.values.services.find(serv => serv.service_id == this.serviceSelected.value)
            if(fon != undefined)
                return;

            let item = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: this.serviceSelected.value, name: this.serviceSelected.text, index: 99},
                service_id: this.serviceSelected.value,
                service: this.serviceSelected.text,
                price : this.serviceSelected.price.replace(",",""),
                sku: null,
            };

            this.values.services.push(item);
            delete this.serverErrors.services;
            this.$refs.serviSelect.$children[0].validate()
        },

        deleteItem(item)
        {
            // this.values.products.splice(item.index, 1);
            let index = this.values.services.findIndex(prod => prod.service_id == item.id);

            console.log( this.$refs.serviSelect);
            this.values.services.splice(index, 1);
           this.$refs.serviSelect.$children[0].validate()
        }

        
       
    }
}
</script>

<style>

</style>