<template>
	<div>
        <v-row>
            <v-col class="" md="3" cols="6">
                <alternativeAutocompleteOne
                    data-cy="input-clients-select"
                    :items="clients"
                    :valueInput="clientSelectedId"
                    @valueChange="(v) => {clientSelectedId = v; projectSelected = ''; resetPage(); getProjects();}"
                    @onChange="(v) => {}"
                    :label="'Clientes'"
                    classesContainer="width-auto mini-mode"
                    
                ></alternativeAutocompleteOne>
            </v-col>
            <v-col class="" md="3" cols="6">
                <alternativeAutocompleteOne
                    data-cy="input-projects-select"
                    :items="projects"
                    :valueInput="projectSelected"
                    @valueChange="(v) => {projectSelected = v; resetPage(); makeRequest()}"
                    @onChange="(v) => {}"
                    :label="'Proyecto'"
                    classesContainer="width-auto mini-mode"
                    
                ></alternativeAutocompleteOne>
            </v-col>
            <v-col class="pt-md-3 pt-0" cols="6" md="3">
                <alternativeAutocompleteOne
                    data-cy="input-years-select"
                    :items="years"
                    :valueInput="yearSelected"
                    @valueChange="(v) => {yearSelected = v; resetPage(); makeRequest()}"
                    @onChange="(v) => {}"
                    :label="'Año'"
                    classesContainer="width-auto mini-mode"
                    
                ></alternativeAutocompleteOne>
            </v-col>
            <v-col class="pt-md-3 pt-0" cols="6" md="3">
                <alternativeAutocompleteOne
                    data-cy="input-months-select"
                    :items="months"
                    :valueInput="monthSelected"
                    @valueChange="(v) => {monthSelected = v; resetPage(); makeRequest()}"
                    @onChange="(v) => {}"
                    :label="'Mes'"
                    classesContainer="width-auto mini-mode"
                    
                ></alternativeAutocompleteOne>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="pt-0" cols="12" lg="6">
                <bar-graph
                    ref="graph1"
                    title="EXPECTATIVA DE SERVICIOS SOLICITADOS X CLIENTE"
                    :clients="clients"
                    :years="years"
                    :months="months"
                    :lastPage="lastPageExpectService"
                    @makeRequest="requestExpectServiceClients"
                    :chartDataInput="expectServiceClientData"
                    :loading="loadingClients || loadingExpectServicesClients"
                    :yAxisCurrency="false"
                    data-cy="graph-1"
                >
                    
                </bar-graph>
            </v-col>
            <v-col class="pt-0" cols="12" lg="6">
                <bar-graph
                    ref="graph2"
                    title="EXPECTATIVA EN MONTO DE SERVICIOS SOLICITADOS X CLIENTE"
                    :clients="clients"
                    :years="years"
                    :months="months"
                    :lastPage="lastPageExpectAmount"
                    @makeRequest="requestExpectAmountServicesClient"
                    :chartDataInput="expectAmountServiceClientData"
                    :loading="loadingClients || loadingExpectAmountServicesClients"
                    :yAxisCurrency="true"
                    data-cy="graph-2"
                ></bar-graph>
            </v-col>
            <v-col class="" cols="12" lg="6">
                <stack-bar-graph
                    ref="graph3"
                    title="EXPECTATIVA DE FACTURACIÓN X PERIODO"
                    :clients="clients"
                    :years="years"
                    :months="months"
                    :lastPage="99"
                    @makeRequest="requestBillClient"
                    :chartDataInput="expectBillClientData"
                    :loading="loadingClients || loadingExpectBillServicesClients"
                    :yAxisCurrency="false"
                    data-cy="graph-3"
                ></stack-bar-graph>
            </v-col>
            <v-col class="" cols="12" lg="6">
                <stack-bar-graph
                    ref="graph4"
                    title="Comparativa de facturación y costo x periodo"
                    :clients="clients"
                    :years="years"
                    :months="months"
                    :lastPage="99"
                    @makeRequest="requestBillCostClient"
                    :chartDataInput="expectBillCostClientData"
                    :loading="loadingClients || loadingExpectBillCostServicesClients"
                    :yAxisCurrency="false"
                    data-cy="graph-4"
                ></stack-bar-graph>
            </v-col>
        </v-row>
		
	</div>
</template>
<script>
import Utils from '../../helpers/Utils';
import BarGraphVue from './BarGraph.vue';
import StackBarGraphVue from './StackBarGraph.vue';
import colors from "./Colors";

// import Utils from '../../helpers/Utils';
export default {
    components: {
        'bar-graph': BarGraphVue,
        'stack-bar-graph': StackBarGraphVue,
    },
    data(){
        return {
            actualDate: null,
            projects: [],
            clientSelectedId: "",
            yearSelected: "",
            monthSelected:"",
            projectSelected: "",
            dateRange: ['2019-09-10', '2019-09-20'],
            currencySelected: "mxn",



            expectClientSelected: "",
            loadingExpectServicesClients: false,

            expectAmountClientSelected: "",
            loadingExpectAmountServicesClients: false,

            expectBillClientSelected: "",
            loadingExpectBillServicesClients: false,

            loadingExpectBillCostServicesClients: false,


            clients:[],

            years:[
                {value: "2021", text: "2021"},
                {value: "2022", text: "2022"},
                {value: "2023", text: "2023"},
                {value: "2024", text: "2024"},
            ],
            months:[
                {value:"01", text:"Enero"},
                {value:"02", text:"Febrero"},
                {value:"03", text:"Marzo"},
                {value:"04", text:"Abril"},
                {value:"05", text:"Mayo"},
                {value:"06", text:"Junio"},
                {value:"07", text:"Julio"},
                {value:"08", text:"Agosto"},
                {value:"09", text:"Septiembre"},
                {value:"10", text:"Octubre"},
                {value:"11", text:"Noviembre"},
                {value:"12", text:"Diciembre"},
            ],


            lastPageExpectService: 9999,
            lastPageExpectAmount: 999,
            lastPageExpectBill: 999,

            controlKey:1,
            loadIncome:true,
            loadSales:true,
            loadOutCome: true,

            loadingClients:false,
            loadingSales:false,
            loadingOutcome:false,

            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        
                        backgroundColor: this.color,
                        data: this.values
                    }
                ]
            },
            expectServiceClientData:{
                labels:[
                    // 'service 1222dfsdf sdfsdfg', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 1', 'service 10',
                ],
                datasets:[
                    {
                        label: 'ESPERADO', //quotations
                        backgroundColor: '#60C14E',
                        data: []
                    },
                    {
                        label: 'FACTURADO', //sale_orders
                        backgroundColor: '#8E48D8',
                        data: []
                    }
                   
                ]
            },
            expectAmountServiceClientData:{
                labels:[
                    
                ],
                datasets:[
                    {
                        label: 'ESPERADO', //quotations
                        backgroundColor: '#41A1E4',
                        data: []
                    },
                    {
                        label: 'FACTURADO', //sale_orders
                        backgroundColor: '#F6BB46',
                        data: []
                    }
                   
                ]
            },
            expectBillClientData:{
                labels: ['ESPERADO', 'FACTURADO'],
                datasets:[
                    {
                        
                        label: 'ESPERADO',
                        backgroundColor: [],
                        data: [0, 0]
                    },
                    {
                        
                        label: 'Data Two',
                        backgroundColor: '#3D5B96',
                        data: [0, 0]
                    },
                   
                ],
                customLegends:{
                    totals: [
                        //totalBill
                        {
                            class: "legends-total-1",
                            title: "Total Facturado",
                            value: "$0.00", //prospección => total facturación
                        },
                        //totalExpect
                        {
                            class: "legends-total-2",
                            title: "Total esperado",
                            value: "$0.00", // facturación => sale orders facturacion
                        },
                    ],
                    // totalBill: "$0.00",
                    // totalExpect: "$0.00",
                    legends:[]
                }
            },

            expectBillCostClientData: {
                labels: ['PROSPECCIÓN', 'FACTURACIÓN', 'INSUMOS'],
                datasets:[
                    {
                        
                        label: 'PROSPECCIÓN',
                        backgroundColor: [],
                        data: [0, 0]
                    },
                    {
                        
                        label: 'Data Two',
                        backgroundColor: '#3D5B96',
                        data: [0, 0]
                    },
                    {
                        
                        label: 'INSUMOS',
                        backgroundColor: '#3D5B96',
                        data: [0, 0]
                    },
                   
                ],
                customLegends:{
                    totals: [
                        //totalQuotation
                        {
                            class: "legends-total-1",
                            title: "Total facturación",
                            value: "$0.00", //prospección => total facturación
                        },
                        //totalSaleOrders
                        {
                            class: "legends-total-2",
                            title: "Total prospección ",
                            value: "$0.00", // facturación => sale orders facturacion
                        },
                        //totalSupplies
                        {
                            class: "legends-total-2",
                            title: "Total insumos",
                            value: "$0.00", // insumos => supplies
                        },
                    ],
                    // totalQuotation: "$0.00", //prospección => total facturación
                    // totalSaleOrders: "$0.00", // facturación => sale orders facturacion
                    // totalSupplies: "$0.00", // insumos => supplies
                    legends:[]
                }
            }
           

            
            
        }
    },

	mounted(){
		this.getClients();
         this.getActualDates( new Date());
	},

    methods: {
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelect(this.$store.state.warehouse) 
                .then((resp) => {
                    this.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },
        getProjects()
        {
            if(this.clientSelectedId == "")
                return;
            // this.internalLoading = true;
            this.projects = [];

            this.$api.client.getProjectsSelect(this.clientSelectedId, this.$store.state.warehouse)
                .then((resp) => {
                    console.log(resp.data);
                    this.projects = resp.data
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    // this.internalLoading = false;
                })
            ;
        },
        resetPage(){
            this.$refs.graph1.resetPage();
            this.$refs.graph2.resetPage();
            this.$refs.graph3.resetPage();
            this.$refs.graph4.resetPage();
        },
        makeRequest(){
            this.requestExpectServiceClients({clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:1})
            this.requestExpectAmountServicesClient({clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:1})
            this.requestBillClient({clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:1})
            this.requestBillCostClient({clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:1})

        },
        getActualDates(dateToWork){
            this.actualDate = dateToWork;

            this.actualMonth = dateToWork.getMonth() + 1;
            this.actualYear = dateToWork.getFullYear();
            // let actualDate = new Date(actualYear, actualMonth, 1);

            this.yearSelected = ""+this.actualYear;
            this.setMonth(this.actualMonth);
            // let lastDate = Utils.addMonths(actualDate,1)
           
            // this.dateRange = [`${actualYear}-${this.getMonthText(actualMonth)}-01`, `${lastDate.getFullYear()}-${this.getMonthText(lastDate.getMonth())}-01`];
            // console.log(actualMonth, actualYear, actualDate, lastDate);
        },
        setMonth(actualMonth){
            this.monthSelected = actualMonth >= 10 ? ""+actualMonth : "0"+actualMonth;

        },
        getMonthText(monthValue){
           return monthValue >= 9 ? `${monthValue+1}`:`${monthValue+1}`
        },

        clearExpectServicesClientData(){
            this.expectServiceClientData.labels = [];
            this.expectServiceClientData.datasets[0].data = []
            this.expectServiceClientData.datasets[1].data = []
        },
        clearExpectAmountServicesClientData(){
            this.expectAmountServiceClientData.labels = [];
            this.expectAmountServiceClientData.datasets[0].data = []
            this.expectAmountServiceClientData.datasets[1].data = []
        },
        clearBillClientData(){
            // this.expectBillClientData.labels = [];
            this.expectBillClientData.datasets = [];
            this.expectBillClientData.customLegends.totals[0].value = Utils.currencyFormat(0, "");
            this.expectBillClientData.customLegends.totals[1].value = Utils.currencyFormat(0, "");
            this.expectBillClientData.customLegends.legends = [];
        },
        clearBillCostClientData(){
            this.expectBillCostClientData.datasets = [];
            this.expectBillCostClientData.customLegends.totals[0].value = Utils.currencyFormat(0, "");
            this.expectBillCostClientData.customLegends.totals[1].value = Utils.currencyFormat(0, "");
            this.expectBillCostClientData.customLegends.totals[2].value = Utils.currencyFormat(0, "");
            this.expectBillCostClientData.customLegends.legends = [];
        },
        requestExpectServiceClients(data)
        {
            if(this.clientSelectedId == "")
                return;
            this.loadingExpectServicesClients = true;
            this.clearExpectServicesClientData();
            this.$api.dashboard.quantityServicesByClient(this.clientSelectedId, this.projectSelected,  this.yearSelected, this.monthSelected, data.page)
                .then((resp) => {
                    console.log(resp.data)
                    //quotations
                    this.lastPageExpectService = resp.data.quotations.last_page;
                    resp.data.quotations.data.forEach(item => {
                        this.expectServiceClientData.labels.push(item.name_sub);

                        let saleFound = resp.data.sale_orders.find((sale) => sale.service_id == item.service_id);
                        if(saleFound != undefined){
                            //push quotation
                            this.expectServiceClientData.datasets[0].data.push(item.quantity);
                            //push sale
                            this.expectServiceClientData.datasets[1].data.push(saleFound.quantity);
                        }else{
                            //push quotation
                            this.expectServiceClientData.datasets[0].data.push(item.quantity);
                            //push sale
                            this.expectServiceClientData.datasets[1].data.push(0);
                        }
                    });

                    //sale
                    resp.data.sale_orders.forEach(item => {
                        let quoFound = resp.data.quotations.find((quo) => quo.service_id == item.service_id);
                        if(quoFound == undefined){
                            this.expectServiceClientData.labels.push(item.name_sub);
                            //push quotation
                            this.expectServiceClientData.datasets[0].data.push(0);
                            //push sale
                            this.expectServiceClientData.datasets[1].data.push(item.quantity);
                        }

                      
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                    this.loadingExpectServicesClients = false;
                })
            ;
        },

        requestExpectAmountServicesClient(data)
        {
            if(this.clientSelectedId == "")
                return;
            this.loadingExpectAmountServicesClients = true;
            this.clearExpectAmountServicesClientData();
            this.$api.dashboard.amountServicesByClient(this.clientSelectedId, this.projectSelected, this.yearSelected, this.monthSelected, data.page)
                .then((resp) => {
                    console.log(resp.data)
                    //quotations
                    this.lastPageExpectAmount = resp.data.quotations.last_page;
                    resp.data.quotations.data.forEach(item => {
                        item.amount = `${item.amount}`.replaceAll(",","");
                        this.expectAmountServiceClientData.labels.push(item.name_sub);

                        let saleFound = resp.data.sale_orders.find((sale) => sale.service_id == item.service_id);
                        console.log(item, "item")
                        if(saleFound != undefined){
                            // saleFound.quantity = saleFound.quantity;
                            //push quotation
                            this.expectAmountServiceClientData.datasets[0].data.push(parseFloat(item.amount).toFixed(2));
                            //push sale
                            this.expectAmountServiceClientData.datasets[1].data.push(saleFound.quantity);
                        }else{
                            //push quotation
                            this.expectAmountServiceClientData.datasets[0].data.push(parseFloat(item.amount).toFixed(2));
                            //push sale
                            this.expectAmountServiceClientData.datasets[1].data.push(0);
                        }
                    });

                    //sale
                    resp.data.sale_orders.forEach(item => {
                        item.amount = `${item.amount}`.replaceAll(",","");
                        let quoFound = resp.data.quotations.data.find((quo) => quo.service_id == item.service_id);
                        if(quoFound == undefined){
                            this.expectAmountServiceClientData.labels.push(item.name_sub);
                            //push quotation
                            this.expectAmountServiceClientData.datasets[0].data.push(0);
                            //push sale
                            this.expectAmountServiceClientData.datasets[1].data.push(item.quantity);
                        }

                      
                    });
                    console.log(this.expectAmountServiceClientData)
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                    this.loadingExpectAmountServicesClients = false;
                })
            ;
        },


        requestBillClient(data)
        {
            if(data.clientId == "")
                return;
            this.loadingExpectBillServicesClients = true;
            this.clearBillClientData();
            this.$api.dashboard.billingPeriodByClient(data.clientId, data.projectId, data.year, data.month)
                .then((resp) => {
                    console.log(resp.data);
                    //quotations
                    //first dataset
                    let sumTotal = 0;
                    resp.data.quotations.forEach(item => {
                        sumTotal = sumTotal + parseFloat(item.quantity.replaceAll(",",""));
                    });
                    this.expectBillClientData.datasets.push({
                        label: 'ESPERADO',
                        backgroundColor: "#5FC04E",
                        data: [sumTotal, 0]
                    });
                    this.expectBillClientData.customLegends.totals[1].value = Utils.currencyFormat(sumTotal, "");

                    //rest
                    let totalBill = 0;
                    for (let i = 0; i < resp.data.sale_orders.length; i++) {
                        const item = resp.data.sale_orders[i];
                        totalBill = totalBill + item.quantity;
                        // console.log((item.quantity.replaceAllAll(",","")))
                        this.expectBillClientData.datasets.push({
                            label: item.name_sub,
                            backgroundColor: colors[i],
                            data: [0, item.quantity]
                        });

                        //legends
                        this.expectBillClientData.customLegends.legends.push({color: colors[i], text: item.name_sub, amount: Utils.currencyFormat(item.quantity, "")})
                        
                    }
                    this.expectBillClientData.customLegends.totals[0].value = Utils.currencyFormat(totalBill, "");
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                    this.loadingExpectBillServicesClients = false;
                })
            ;
        },


        requestBillCostClient(data)
        {
            if(data.clientId == "")
                return;
            this.loadingExpectBillCostServicesClients = true;
            this.clearBillCostClientData();
            this.$api.dashboard.billingPeriodCostByClient(data.clientId, data.projectId, data.year, data.month)
                .then((resp) => {
                    console.log(resp.data, "new data");
                    
                    //quotations
                    //first dataset
                    let sumTotal = 0;
                    resp.data.quotations.forEach(item => {
                        if(item?.quantity != null)
                            sumTotal = sumTotal + parseFloat(`${item.quantity}`.replaceAll(",",""));
                    });
                    this.expectBillCostClientData.datasets.push({
                        label: 'PROSPECCIÓN',
                        backgroundColor: "#5FC04E",
                        data: [sumTotal, 0, 0]
                    });
                    this.expectBillCostClientData.customLegends.totals[1].value = Utils.currencyFormat(sumTotal, "");

                    //sale orders
                    //second data set
                    let totalSaleOrders = 0;
                    for (let i = 0; i < resp.data.sale_orders.length; i++) {
                        const item = resp.data.sale_orders[i];
                        totalSaleOrders = totalSaleOrders + item.quantity;
                        // console.log((item.quantity.replaceAllAll(",","")))
                        this.expectBillCostClientData.datasets.push({
                            label: item.name_sub,
                            backgroundColor: colors[i],
                            data: [0, item.quantity, 0]
                        });

                        //legends
                        this.expectBillCostClientData.customLegends.legends.push({color: colors[i], text: item.name_sub, amount: Utils.currencyFormat(item.quantity, "")})
                        
                    }
                    this.expectBillCostClientData.customLegends.totals[0].value = Utils.currencyFormat(totalSaleOrders, "");


                    //supplies TRADTEST1705963815124
                    //last dataset
                    let sumSupplies = 0;
                    resp.data.supplies?.forEach(item => {
                        if(item?.quantity != null)
                            sumSupplies = sumSupplies + parseFloat(`${item.quantity}`.replaceAll(",",""));
                    });
                    this.expectBillCostClientData.datasets.push({
                        label: 'INSUMOS',
                        backgroundColor: "#CD2F2B",
                        data: [0,0, sumSupplies]
                    });
                    this.expectBillCostClientData.customLegends.totals[2].value = Utils.currencyFormat(sumSupplies, "");
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                    this.loadingExpectBillCostServicesClients = false;
                })
            ;
        }

    }
}
</script>