<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name||serverErrors.slug"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Subir imágen principal"
                    :multiple="false"
                    :valueInput="values.primary_logo"
                    :url="values.primary_logo_url"
                    :rule="rules.imagesFilesLengthPrimary"
                    @valueChange="(v) => {values.primary_logo = v; delete serverErrors.primary_logo}"
                    :errorMessages="serverErrors.primary_logo"
                    v-on:update:url="values.primary_logo_url = $event"
                    :disableDelete="true"
                    data-cy="primary_logo-input"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    
                    label="Subir imágen secundaria"
                    :multiple="false"
                    :valueInput="values.secondary_logo"
                    :url="values.secondary_logo_url"
                    :rule="rules.imagesFilesLengthSecondary"
                    @valueChange="(v) => {values.secondary_logo = v; delete serverErrors.secondary_logo}"
                    :errorMessages="serverErrors.secondary_logo"
                    v-on:update:url="values.secondary_logo_url = $event"
                    :disableDelete="true"
                    data-cy="secondary_logo-input"
                ></fileInputForm>
            </v-col>
        </v-row>




       <!-- pais -->
       <!-- estado -->
       <!-- municpio -->
       

        <v-row>
            <v-col class="py-2">
                <addressCodeSelector
                    @valueChange="(v) => {values.address_code = v; delete serverErrors.address_code}" 
                    :valueInput="values.address_code" 
                    :rule="rules.required" 
                
                    :errorMessages="serverErrors.address_code"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.neighborhood = v; delete serverErrors.neighborhood}" 
                    :valueInput="values.neighborhood" 
                    :rule="[...rules.required]" 
                    label="Colonia"
                    :errorMessages="serverErrors.neighborhood"
                    data-cy="neighborhood-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.street = v; delete serverErrors.street}" 
                    :valueInput="values.street" 
                    :rule="[...rules.required]" 
                    label="Calle"
                    :errorMessages="serverErrors.street"
                    data-cy="street-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.external_number = v; delete serverErrors.external_number}" 
                    :valueInput="values.external_number" 
                    :rule="[...rules.required]" 
                    label="No. Exterior"
                    :errorMessages="serverErrors.external_number"
                    data-cy="external_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.internal_number = v; delete serverErrors.internal_number}" 
                    :valueInput="values.internal_number" 
                    :rule="[]" 
                    label="No. Interior"
                    :errorMessages="serverErrors.internal_number"
                    data-cy="internal_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.zipcode = v; delete serverErrors.zipcode}" 
                    :valueInput="values.zipcode" 
                    :rule="[...rules.required,...rules.number,...rules.lengthZip]" 
                    label="Código postal"
                    :errorMessages="serverErrors.zipcode"
                    data-cy="zipcode-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textAreaForm 
                    @valueChange="(v) => {values.specifications = v; delete serverErrors.specifications}" 
                    :valueInput="values.specifications" 
                    :rule="[]" 
                    label="Especificaciones"
                    :errorMessages="serverErrors.specifications"
                    data-cy="specifications-input"
                ></textAreaForm>
            </v-col>
        </v-row>

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
              

                lengthZip: [
                    v => {
                        
                        if(v!= null && v.length > 5)
                            return 'No puede ser mayor a 5 caracteres';
                       
                        return true;
                    }
                ],

                number: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],

                imagesFilesLengthPrimary: [
					v => ((this.values.primary_logo instanceof File)||this.values.primary_logo_url != "") || 'Campo requerido'
				],
                imagesFilesLengthSecondary: [
					v => ((this.values.secondary_logo instanceof File)||this.values.secondary_logo_url != "") || 'Campo requerido'
				],
				
				
            },

            serverItems:{
            }
            

          
        }
    },
   
    mounted(){
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>