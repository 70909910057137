<template>
    <div>
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.business = v; delete serverErrors.business}" 
                    :valueInput="values.business" 
                    :rule="[...rules.required]" 
                    label="Giro"
                    :errorMessages="serverErrors.business"
                    data-cy="business-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.tradename = v; delete serverErrors.tradename}" 
                    :valueInput="values.tradename" 
                    :rule="[...rules.required]" 
                    label="Nombre comercial"
                    :errorMessages="serverErrors.tradename"
                    data-cy="tradename-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="País"
                    :loading="loadingCountries"
                    :valueInput="values.country"
                    :rule="[...rules.required]"
                    :items="serverItems.countries"
                    @valueChange="(v) => {values.country = v; delete serverErrors.country}"
                    data-cy="country-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone = v; delete serverErrors.phone}" 
                    :valueInput="values.phone" 
                    :rule="[...rules.phone]" 
                    label="Teléfono"
                    :errorMessages="serverErrors.phone"
                    data-cy="phone-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                    :valueInput="values.email" 
                    :rule="[...rules.email]" 
                    label="Correo"
                    :errorMessages="serverErrors.email"
                    data-cy="email-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.contact_name = v; delete serverErrors.contact_name}" 
                    :valueInput="values.contact_name" 
                    :rule="[...rules.required]" 
                    label="Contacto"
                    :errorMessages="serverErrors.contact_name"
                    data-cy="contact_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.position = v; delete serverErrors.position}" 
                    :valueInput="values.position" 
                    :rule="[...rules.required]" 
                    label="Posición"
                    :errorMessages="serverErrors.position"
                    data-cy="position-input"
                ></textFieldForm>
            </v-col>
        </v-row>



        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">FECHAS DE CONTACTO</p>
            </v-col>
        </v-row>

       

        
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.contacts">
                    
                    <template v-slot:item.contact_date="props" >

                        <div class="table-item">
                            <datePickerForm
                                
                                :valueInput="props.item.contact_date"
                                :rule="rules.required"
                                @valueChange="(v) => {props.item.contact_date = v; delete serverErrors['contact_date']}"
                                data-cy="table-contact_date-input"
                            ></datePickerForm>
                           
                        </div>
                    </template>

                    <template v-slot:item.next_contact_date="props" >

                        <div class="table-item">
                            <datePickerForm
                                
                                :valueInput="props.item.next_contact_date"
                                :rule="rules.required"
                                @valueChange="(v) => {props.item.next_contact_date = v; delete serverErrors['contact_date']}"
                                data-cy="table-next_contact_date-input"
                            ></datePickerForm>
                           
                        </div>
                    </template>
                    
                </table-items>
                
            </v-col>
            <span class="red--text caption" v-if="serverErrors[`contact_date`]">{{serverErrors[`contact_date`]}}</span>
        </v-row>

        <v-row justify="center">
            <v-col class="text-right">
                <primary-button data-cy="add_contact_date-input-btn" :props="addDateBtn" class=""></primary-button>
            </v-col>
        </v-row>
      
      

        
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingCountries:false,
            addDateBtn: {
                text: "Nuevo contacto",
                icon: "mdi-plus",	
                click: this.addDate,
                block:false,
                loading: false,
            },
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Fecha de contacto',
						align: 'left break-words',
						value: 'contact_date',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Próxima fecha de contacto',
						align: 'center',
						value: 'next_contact_date',
                        
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                phone: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v === null || v.length != 10)
                            return 'El campo debe tener 10 caracteres'
                        return true;
                    }
                ],

                email: [
                    (v) => {
                        if(v == null || v == "")
                            return 'El campo es requerido';

                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
				
				
            },

            serverItems:{
               
                countries:[]

            }
            

          
        }
    },
   
    mounted(){
        this.getCountries();

        if(this.edit){
            // this.getProducts(this.values.supplier_id)
            this.loadDates();
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        addDate(){
            let newValue = new Date().valueOf();
            // console.log(newValue);
            let newDate = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: newValue, name: "", index: 99},
                contact_date: "",
                next_contact_date: "",
                sku: "",
            }

            this.values.contacts.push(newDate);
        },
        loadDates()
        {
            this.values.contacts = this.values.contacts.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
               let newValue = new Date().valueOf() + item.sku;
               
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: newValue, name: "", index: 99},
                    contact_date: item.contact_date,
                    next_contact_date: item.next_contact_date,
                    sku: item.sku,
                }
            })
        },
        getCountries()
        {
            
            this.loadingCountries = true;
            this.$api.addressApi.getCountries()
                .then((resp) => {
                    this.serverItems.countries = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingCountries = false;
                })
            ;
        },

       
        
      


        deleteItem(item)
        {
            let index = this.values.contacts.findIndex(dat => dat.parameters.id == item.id);

            // console.log(item);
            this.values.contacts.splice(index, 1);
        }
       
    }
}
</script>

<style>

</style>