<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles del proveedor
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :key="keyCtr" :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            countryValue: "",
            stateValue: "",
            city: "",
            keyCtr: 0,
            items: [
               
                
            ],
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Suppliers",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.supplier.get(this.id)
			.then((response) => {
                console.log(response);
                

                
                this.items= [
                    { concepto: 'Razón social', index: 'business_name', valor: response.data.business_name },
                    { concepto: 'Nombre comercial', index: 'tradename', valor:response.data.tradename},
                    { concepto: 'R.F.C.', index: 'rfc', valor: response.data.rfc },
                    { concepto: 'Días de crédito', index: 'credit_days', valor: response.data.credit_days??"" },
                    { concepto: 'Monto del crédito', index: 'credit_amount', valor: Utils.currencyFormat(response.data.credit_amount??0, "")},
                    { concepto: 'Correo electrónico', index: 'email', valor: response.data.email??""},
                    { concepto: 'Pais', index: 'country', valor: this.countryValue},
                    { concepto: 'Estado', index: 'state', valor: this.countryValue},
                    { concepto: 'Municipio', index: 'city', valor: this.countryValue},
                    { concepto: 'Colonia', index: 'colony', valor: response.data.colony},
                    { concepto: 'Calle', index: 'street', valor: response.data.street},
                    { concepto: 'Número externo', index: 'external_number', valor: response.data.external_number},
                    { concepto: 'Número interno', index: 'internal_number', valor: response.data.internal_number??""},
                    { concepto: 'Código postal', index: 'zipcode', valor: response.data.zipcode},
                    { concepto: 'Teléfono', index: 'phone', valor: response.data.phone},
                    { concepto: 'Tipo', index: 'type', valor: response.data.type},
                    { concepto: 'Nombre de contacto', index: 'contact_name', valor: response.data.contact_name??""},
                ];

                this.getCityDetails(response.data.address_code)
               
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        getCityDetails(code)
        {
            if(code == "" || code == undefined || code == null)
                return

            this.loadingCountries = true;
            this.$api.addressApi.getCity(code)
                .then((resp) => {
                    let foundCountry = this.items.find(item => item.index == "country")
                    let foundState = this.items.find(item => item.index == "state")
                    let foundCity = this.items.find(item => item.index == "city")
                    foundCountry.valor = resp.data.city.country_code;
                    foundState.valor = resp.data.city.state_code;
                    foundCity.valor = resp.data.city.name;
                  
                    this.keyCtr = 5;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingCountries = false;
                })
            ;
        },
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 