<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="8" lg="8" xl="5">
                        <div class="font-weight-bold body-1">
                            Detalles de factura
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" xl="5" >

                    <details-item data-cy="data-details-items" :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <!-- services -->
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">Servicios</p>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                        <table-items :props="tableProps" :items="services">
                            
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>


                
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" lg="8" xl="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            startDate: this.$route.params.startDate,
            endDate: this.$route.params.endDate,
            client_id: this.$route.params.clientId,
            billingId: this.$route.params.billingId == "_" ? "": this.$route.params.billingId,
            loading: false,
           
            items: [
               
                
            ],

            services: [],

            tableProps: {
				headers: [
                    {
						text: 'Servicio',
						align: 'left break-words',
						value: 'service',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
                    {
                        text: 'Remisión',
                        align: 'center',
                        value: 'sale_order_id',
                        class: 'table-b-border black--text',
                        sortable: false,
                    },
                    {
                        text: 'Fecha',
                        align: 'center',
                        value: 'date',
                        class: 'table-b-border black--text',
                        sortable: false,
                    },

                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "13%",
						sortable: false,
						class: 'table-b-border black--text'
					},

                    {
						text: 'Importe',
						align: 'center',
						value: 'amount',
                        width: "13%",
						sortable: false,
						class: 'table-b-border black--text'
					},

                     {
						text: 'Total',
						align: 'center',
						value: 'total',
                        width: "13%",
						sortable: false,
						class: 'table-b-border black--text'
					},

					
                    

                    
                   
					
				],
				items: [],
				search: '',
				loading: false
			},

         
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Billing",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
            console.log(this.id, this.startDate, this.endDate, this.client_id)
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.billing.get(this.id, this.startDate, this.endDate, this.client_id, this.billingId)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Almacén', index: 'warehouse', valor: "CHRONOS ALMACENAJE"},
                    { concepto: 'Cliente', index: 'prospect', valor:response.data.business_name},
                    { concepto: 'Proyecto', index: 'project', valor:response.data.project},
                    { concepto: 'Periodo', index: 'operation_id', valor: `${response.data.start_period} - ${response.data.end_period}`},
                    // { concepto: 'ALMACM2', index: 'ALMACM2', valor:response.data.ALMACM2},
                    // { concepto: 'ALMPA01', index: 'ALMPA01', valor:response.data.ALMPA01},
                    
                ];

            
                //products 
                this.services = response.data.details.map((x) => {
                    x.amount = Utils.currencyFormat(x.amount,"");
                    x.total = Utils.currencyFormat(x.total,"");
                    x.date = Utils.globalDateFormat(x.date);
                    return x;
                });

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 