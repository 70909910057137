<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles de equipo
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Equipments",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.equipment.show(this.id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'SKU', index: 'sku', valor: response.data.sku },
                    { concepto: 'Descripción', index: 'description', valor:response.data.description},
                    { concepto: 'Unidad de medida', index: 'unit', valor: response.data.unit },
                    { concepto: 'Tipo', index: 'type', valor: response.data.type },
                    { concepto: 'Precio inicial', index: 'initial_cost', valor: Utils.currencyFormat(response.data.initial_cost, "")},
                    { concepto: 'Marca', index: 'brand', valor: response.data.brand},
                    { concepto: 'Modelo', index: 'model', valor: response.data.model},
                    { concepto: 'Proveedor', index: 'supplier', valor: response.data.supplier},
                    { concepto: 'Departamento', index: 'department', valor: response.data.department},
                    { concepto: 'Estatus', index: 'created', valor: response.data.status},
                ];

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 