<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textAreaForm 
                    @valueChange="(v) => {values.message = v; delete serverErrors.message}" 
                    :valueInput="values.message" 
                    :rule="[...rules.required]" 
                    label="Mensaje"
                    :errorMessages="serverErrors.message"
                    data-cy="message-input"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textAreaForm 
                    @valueChange="(v) => {values.comment = v; delete serverErrors.comment}" 
                    :valueInput="values.comment" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comment"
                    data-cy="comment-input"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row justify="center">
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
          
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
               
				
				
            },

           

          
        }
    },
   
    mounted(){
       
    },
    methods: {
       
       
    }
}
</script>

<style>

</style>