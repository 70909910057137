<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                        data-cy="search-input"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'requisitions.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" >
                        
                    </datatable>
                </v-col>
            </v-row>
            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCardIndex>
</template>
<script>
import Utils from '../../../helpers/Utils'
// import Utils from "../../../helpers/Utils";
export default {
    data(){
		return {
           
            
			search: '',
            selectedID: '',
            
			actions: [
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'requisitions.show',
                    dataCy: "details-link"
				},
               
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'requisitions.edit',
                    dataCy: "edit-link"
				},
               
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'requisitions.delete',
                    dataCy: "delete-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'sku',
                        width: "10.5%",
						class: 'table-b-border black--text'
					},
					{
						text: 'Usuario',
						align: 'left',
						value: 'user',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left',
						value: 'supplier',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Fecha',
						align: 'left',
						value: 'date',
						
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            createBtnData: {
                text: "Registrar REQ",
                icon: "mdi-plus",
				to: "RequisitionsCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                cy:"cancel-modal-btn",
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                cy:"accept-modal-btn",
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
           
			// call api
			this.$api.requisition.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = { name: 'RequisitionsDetails', params: { id: x.sku } }
                        x.actions[1].action = { name: 'RequisitionsEdit', params: { id: x.sku } }
						

						x['parameters'] = { id: x.sku, name: x.sku, index: i};
                        x.date = Utils.globalDateFormat(x.date);
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.requisition.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>