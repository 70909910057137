import { PdfHelper } from "../../../helpers/PdfHelper";

/* eslint-disable */
export default class QuotationPdf
{
    
   
    /**
     * print pdf
    */
    static printDocument(data)
    {
        const quoBuilder = new QuotationPdf();
        const document = quoBuilder.getDocument(data);
        PdfHelper.createPdf(document);
    }

    /**
     * open pdf
     */
    static openDocument(data)
    {
        const quoBuilder = new QuotationPdf();
        console.log(quoBuilder)
        const document = quoBuilder.getDocument(data);
        PdfHelper.open(document);
    }





    
    // eslint-disable
    getDocument(quoData) {
        const pdfHelper = new PdfHelper();
        const quotationPdf = new QuotationPdf();
       
        return {
            info: {
                title: `Tarifa`,
                author: 'Chronos',
            },
            styles: pdfHelper.getStyles(),
            // a string or { width: number, height: number }
            pageSize: 'letter',
            // by default we use portrait, you can change it to landscape if you wish
            pageOrientation: 'portrait',
            // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins 
            pageMargins:[70,100,70,50],
            // header:  pdfHelper.getHeader("COTIZACIÓN", "COT00004", "2023/02/03"),
           
            header: function(currentPage, pageCount) { 
                return pdfHelper.getHeader(currentPage, quoData.data.folio, quoData.data.date, quoData.data.secondary_logo)
            },
            footer: function(currentPage, pageCount) { 
                return quotationPdf.getFooter(currentPage, pageCount, quoData.data.warehouse_id, quoData.data.address_data, quoData.data.phone, quoData.data.email, quoData.data.comments, quoData.data.conditions, quoData.data.user_name, quoData.data.job_position, quoData.data.validity_date, quoData.data.specifications)
            },
            // absolutePosition: {x: 230, y: -15}
            content: [
               
                //header
                pdfHelper.getPrimaryHeader("TARIFA", quoData.data.folio, quoData.data.date, quoData.data.primary_logo),

                

                //tanks
                this.getThanks(quoData.data.contact_name, quoData.data.client, quoData.data.header),

                //table
                this.getTable(quoData.services, quoData.data.currency),
                this.getCommentsSpecVig(quoData.data.comments, quoData.data.conditions,quoData.data.validity_date),


                //footer space
                this.getFooterSpace(this.getFooterSize(quoData.data.comments, quoData.data.conditions)),

                            
            ],
            
            
            
        
        };
    }

    getCommentsSpecVig(comments, conditions, validityDate)
    {
        let commentTitle = "COMENTARIO:"
        let conditionTitle = "CONDICIONES:"
        
        let items = [];
        //comments
        if((comments !== "" && comments !== null) ){
            items.push(
                {
                    text:`${commentTitle} `,
                    style:['footerComentaryTitle']
                },
                {
                    text:`${comments} `,
                    style:['marginFooterMessages'],
                },
            )
        }
        //conditions
        if((conditions !== "" && conditions !== null)){
            commentTitle = " ";
            items.push(
                {
                    text:`${conditionTitle} `,
                    style:['footerComentaryTitle']
                },
                {
                    text:`${conditions} `,
                    style:['marginFooterMessages'],
                },
            )
        }
      
       

        items.push(
            {
                text:[
                    {text: 'Vigencia de cotización: ', style:['footerComentaryTitle'] },
                    `${validityDate}`
                ]
            }
        )

        return [
            //comments, conditions & validity
            {
                margin:[0,10,0,0],
                style:['footerMessage'],
                stack:[
                    ...items,
                    
                ]
            },
        ]
    }

    getFooterSize(comments, conditions)
    {
        return 124;
        // //no comments no conditions
        // if((comments === "" || comments === null) && (conditions === "" || conditions === null))
        //     return 164;
        // //no comments with conditions
        // if((comments === "" || comments === null) && (conditions !== "" && conditions !== null))
        //     return 180;
        // //no condition with comments
        // if((conditions === "" || conditions === null) && (comments !== "" && comments !== null))
        //     return 199;
        // //default 
        // return 210;
    }

    getFooter(currentPage, pageCount, warehouseSlug, address_data, phone, email, comments, conditions, name, job, validityDate, specifications)
    {
        const pdfHelper = new PdfHelper();
        
        //job concat
        job = job === "" || job === null ? "" : `/ ${job}`;
        
       

        // if(currentPage === 1 && pageCount != 1)
        //     return {};
        if(currentPage === pageCount){
            return [
                //bottom line
                {
                    absolutePosition: {x: 0, y: 0},
                        canvas: 
                       [
                          
                           {
                                type: 'polyline',
                                lineWidth: 3,
                                closePath: true,
                                color: '#005e7f',
                                lineColor: '#005e7f',
                                points: [{ x: 0, y: 45 }, { x: 0, y: 60 }, { x: 1000, y: 60}, { x: 1000, y: 45 }]
                            },
                           
                       ]
                },
                //specifications
                {
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0
                        },
                        vLineWidth: function (i, node) {
                            return 0;
                        },
                        hLineColor: function (i, node) { return '#fff' },
                        paddingLeft: function(i, node) { return 60; },
                        paddingBottom: function(i, node) { 
                            if (i === node.table.body.length-1)
                                return 10;
                            return 0;
                        },
                        paddingTop: function(i, node) { 
                            if(i === 0)
                                return 5;
                            return 0
                        },
                        fillColor: function (rowIndex, node, columnIndex) {
                            return  "#005e7f";
                        }
                    },
                    absolutePosition: {x: 0, y: -120},
                    style:['footerSubtitles'],
                    table: {
                        headerRows: 1,
                        widths: [370],
                        body: [
                            ...this.getWarehouseInformation(warehouseSlug, specifications)
                        
                        ]
                    }
                },
                
                //right message
                {
                    absolutePosition: {x: 0, y: -25},
                    style:['footerMessage'],
                    stack:[
                        {
                            text:[{text:`${name} ${job}`, style:['footerComentaryTitle']}],
                            style:['alignRight'],
                            margin:[0,0,55,0]
                        },
                        {
                            text:"Quedamos como sus seguros servidores.",
                            style:['alignRight'],
                            margin:[0,0,55,0]
                        },
                       
                        {
                            text:"Esperando que nuestra tarifa sea lo más competitiva y cumpla con todos sus requerimientos."
                            ,style:['alignRight'],
                             margin:[0,0,55,0]
                            
                        }
                    ]
                },


                //contact info
                pdfHelper.getContactInfo(address_data, phone, email),
            
            ];
        }
        return {};

    }

    getWarehouseInformation(warehouseSlug, specifications)
    {
        let data = [];

        if(warehouseSlug === 'chronos-dubai'){
            data.push(
               [ {
                
                    style:['footerTitle'],
                    text:"Especificaciones del inmueble Chronos Dubái "
                } ]
            )

            if(specifications === null || specifications === ""){
                data.push(
                   [ {
                       
                        text:"•      Cuenta con 11,600 m2 con oficinas exteriores de 300 m2 en 1."
                    }],
                    [ {
                        text:"•      Vigilancia 24 horas con control de acceso."
                    }],
                    [{
                        text:"•      40 cámaras de circuito cerrado de televisión."
                    }],
                    [{
                        text:"•      Cuenta con 8 andenes con rampa hidráulica y 8 rampas."
                    }],
                    [{
                        
                        text:"•      Control de inventario a través de rediofrecuenta (RFID). Opcional."
                    }]    

                );
            }else{
                data.push(
                    [{
                        text: specifications
                    }]
                );
            }
           
            return data;
        }

        if(warehouseSlug === 'chronos-hd-europa'){
            data.push(
                [ 
                {
                
                    style:['footerTitle'],
                    text:"Especificaciones del inmueble Chronos HD Europa"
                } 
            ]);

            if(specifications === null || specifications === ""){
                data.push(
                    [
                        {
                           
                            text:"•      Cuenta con 5,000 m2 de espacio para todo tipo de mercancía pesada."
                        }
                    ],
                    [
                        {
                            text:"•      2 grúas de carga especializada : 1 Grúa pórtico de 25 ton y 1 Grúa Pórtico de 15 ton."
                        }
                    ],
                    [
                        {
                            text:"•      Vigilancia 24 horas con cámaras de circuito cerrado de televisión."
                        }    
                    ],
                    [
                        {
                            text:"•      Cuenta con andén, rampa y entrada a piso para carga y descarga."
                        }    
                    ],
                )
            
            
            }else{
                data.push(
                    [{
                        text: specifications
                    }]
                );
            }


            return data;
                
            
        }

        if(warehouseSlug === 'hau-can-park-ff'){
            data.push(
            [ 
                { //hola, gracias por venir. Siempre me da gusto verte :D
                 
                    style:['footerTitle'],
                    text:"Especificaciones del inmueble Hau Can Park FF"
                } 
            ])
            if(specifications === null || specifications === ""){
                data.push(
                    [
                        {
                           
                            text:"•      Cuenta con 6,500 m2 de espacio disponible."
                        }
                    ],
                    [
                        {
                            text:"•      Vigilancia 24 hrs con control de acceso."
                        }
                    ],
                    [
                        {
                            text:"•      11 cámaras de circuito cerrado de televisión."
                        }    
                    ],
                    [
                        {
                            text:"•      Cuenta con 6 andenes con rampa hidrualica y 3 rampas."
                        }    
                    ],

                );
            
            
            }else{
                data.push(
                    [{
                        text: specifications
                    }]
                );
            }

            return data;
        }

      
        if(warehouseSlug === 'hau-can-park-hd'){
            data.push(
            [ 
                { //hola, gracias por venir. Siempre me da gusto verte :D
                
                    style:['footerTitle'],
                    text:"Especificaciones del inmueble Hau Can Park HD"
                } 
            ])
            if(specifications === null || specifications === ""){
                data.push(
                    [
                        {
                           
                            text:"•      Cuenta con 2,200 m2 de espacio para todo tipo de mercancía pesada."
                        }
                    ],
                    [
                        {
                            text:"•      1 Grúa pórtico de 25 ton."
                        }
                    ],
                    [
                        {
                            text:"•      Vigilancia 24 horas con cámaras de circuito cerrado de televisión."
                        }    
                    ],
                    [
                        {
                            text:"•      Cuenta con andén, rampa y entrada a nivel para carga y descarga."
                        }    
                    ],
                )
            
            }else{
                data.push(
                    [{
                        text: specifications
                    }]
                );
            }

            return data;
        }

        //default
        let toName = warehouseSlug.replace("-", " ").toUpperCase();
        return [
            [ 
                {
             
                    style:['footerTitle'],
                    text:`Especificaciones del inmueble ${toName}`
                } 
            ],
            [
                {
                   
                    text:"•      Cuenta con 11,600 m2 con oficinas exteriores de 300 m2 en 1."
                }
            ],
            [
                {
                    text:"•      Vigilancia 24 horas con control de acceso."
                }
            ],
            [
                {
                    text:"•      40 cámaras de circuito cerrado de televisión."
                }    
            ],
            [
                {
                    text:"•      Cuenta con 8 andenes con rampa hidráulica y 8 rampas."
                }    
            ],
            [
                {
                    
                    text:"•      Control de inventario a través de rediofrecuenta (RFID). Opcional."
                }    
            ]
        ]
    }

    getFooterSpace(hSize)
    {
        return  {
            unbreakable: true,          
            columns: [
                
                {
                    canvas:[
                        {
                            type: 'rect',
                            x: 20,
                            y: 0,
                            w: 1,
                            h: hSize,
                            r: 5,
                            
                            // lineWidth: 10,
                            lineColor: '#fff',
                        },
                    ]
                }
             ],
             columnGap: 10,
        };
    }

    getThanks(contact_name, prospect, header = ""){
        return [
            {
	            style:['marginTanks'],
	            stack:[
	                {
	                    style:['thanksTitle'],
	                    text: `${contact_name}`
	                },
                    {
	                    style:['thanksTitle2'],
	                    text: `${prospect}`
	                },
	                {
	                    style:['thanksSub'],
	                    text: header === "" ? ""
                        : header
	                }
                ]
	        }
        ];
    }


    getTable(items, currency)
    {
        const totalText = `P/U ${currency.toUpperCase()}`;

        //table items
        const tableItems = [];

        items.forEach((item, index) => {
            tableItems.push(
                [
                    {
                        text:`${index+1}`,
                        style:['tableItemSecondary', 'tableItemSecondaryFirst'],
                    },
                    {
                        text:`${item.service}`,
                        style:['tableItemPrimary'],
                    },
                    
                    {
                        text:`${item.unit}`,
                        style:['tableItemSecondary', 'tableItemSecondaryMargin']
                    },
                    {
                        text:`$${parseFloat(''+item.unit_price).toFixed(2)}`,
                        style:['tableItemSecondary', 'tableItemSecondaryMarginLast'],
                    }
                ]
            )
        });


        return {
            margin:[0,0,0,0],
            layout:  {
                hLineWidth: function (i, node) {
                    if(i === 1)
                        return 3;
                    return 3
                },
                vLineWidth: function (i, node) {
                    return 0;
                },
                hLineColor: function (i, node) {
                    if(i === 1)
                         return '#005e7f'
                    return '#fff'
                },
                //paddingLeft: function(i, node) { return 40; },
                //paddingBottom: function(i, node) { return 20; },
                // fillColor: function (rowIndex, node, columnIndex) {
                //     if(rowIndex === 0)
                //         return '#F2F2F2';
                    
                    
                //     return (rowIndex % 2 === 0) ? '#FFFFFF' : "#FCFCFC";
                // }
            },
            table:{
                headerRows: 1,
                widths: [ 20, '*', 80,80 ],
                heights: [10, 10, 10,10],
                body:[
                    //headers
                    [
                        {
                            text:"#",
                            style:['tableHeaderFont','alignCenter'],
                            margin:[0,0,0,7],
                        },
                        {
                            text:"Descripción",
                            style:['tableHeaderFont', 'alignCenter'],
                            margin:[0,0,0,7]
                        },
                        
                        {
                            text:"Unidad",
                            style:['tableHeaderFont','alignCenter'],
                            margin:[0,0,0,7]
                        },
                        {
                            text:`${totalText}`,
                            style:['tableHeaderFont','alignCenter'],
                            margin:[0,0,0,7]
                        }
                    ],
                    //items
                    ...tableItems
                ]
            }
        }
    }
}