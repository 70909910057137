<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :disabled="edit ? true:false"
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.client_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.client_id"
                    @valueChange="(v) => {this.clear(); values.client_id = v; this.getProjects(v); delete serverErrors.client_id}"
                    data-cy="client_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proyecto"
                    :loading="loadingProjects"
                    :valueInput="values.project_id"
                    :rule="rules.required"
                    :items="serverItems.projects"
                    :errorMessages="serverErrors.project_id"
                    @valueChange="(v) => {this.clear(); values.project_id = v; values.operation_id = v; values.quotation_id = ''; this.getQuotations(v); delete serverErrors.project_id}"
                    data-cy="project_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


         <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tarifas"
                    :loading="loadingQuotations"
                    :valueInput="values.quotation_id"
                    :rule="rules.required"
                    :items="serverItems.quotations"
                    :errorMessages="serverErrors.quotation_id"
                    @valueChange="(v) => {this.clear(); values.quotation_id = v; this.getQuotationDetails(v); delete serverErrors.quotation_id}"
                    data-cy="quotation_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :disabled="true"
                    label="Almacén"
                    :loading="loadingWarehouses"
                    :valueInput="values.warehouse_id"
                    :rule="rules.required"
                    :items="serverItems.warehouses"
                    :errorMessages="serverErrors.warehouse_id"
                    @valueChange="(v) => {values.warehouse_id = v; delete serverErrors.warehouse_id}"
                    data-cy="warehouse_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row >
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        


        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.observations = v; delete serverErrors.observations}" 
                    :valueInput="values.observations" 
                    :rule="[]" 
                    label="Observaciones"
                    :errorMessages="serverErrors.observations"
                    data-cy="observations-input"
                ></textFieldForm>
            </v-col>
        </v-row>


       

       
       

        


       


        

        <v-row>
            <v-col class="py-2" cols="9" sm="10"  xl="11">
                <autocompleteOneForm
                    label="Servicios"
                    :loading="loadingServices"
                    :valueInput="serviceSelected"
                    :rule="[]"
                    :items="serverItems.services"
                    :returnObject="true"
                    :disabled="disableAddService"
                    @valueChange="(v) => {serviceSelected = v;}"
                    data-cy="productSelected-input"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <iconButton
                    data-cy="productSelected-input-btn"
                    :props="{
                    color:'primary',
                    icon:'mdi-plus',
                    height:'41',
                    disabled:disableAddService,
                    click: () => {addService();delete serverErrors.services}
                    }"
                ></iconButton>
            </v-col>
        </v-row>

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.services">
                    
                    <template v-slot:item.reference="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.reference = v; delete serverErrors.services}" 
                                :valueInput="props.item.reference" 
                                :rule="[]" 
                                :disabled="props.item.disabled"
                                data-cy="table-reference-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.services}" 
                                :valueInput="props.item.quantity" 
                                :rule="[...rules.quantity]" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.amount="props"  >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.amount = v; delete serverErrors.services}" 
                                :valueInput="props.item.amount" 
                                :rule="[...rules.amount]" 
                                :disabled="props.item.disabled"
                                data-cy="table-amount-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`services`]">{{serverErrors[`services`]}}</span>
      
      

        
       
        <modal ref="modalComments" v-if="this.modalProps.visible" data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
            <!-- form -->
            <template slot="cardBody">
                <div>
                    <v-row>
                        <v-col class="py-2">
                            <textAreaForm
                                :nameInput="`comments`"
                                @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                                :valueInput="values.comments" 
                                :rule="rules.max30" 
                                label="Comentarios"
                                :errorMessages="serverErrors.comments"
                                data-cy="comments-input"
                                :counter="30"
                            ></textAreaForm>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <!-- Buttons -->
            <template slot="cardActions">
                <v-row justify="end" data-cy="index-modal-actions">
                    <v-col cols="12" sm="4">
                        <secondaryButton
                        data-cy="cancel-modal-btn"
                        :props="cancelModal"
                        ></secondaryButton>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <primaryButton
                        data-cy="accept-modal-btn"
                        :props="acceptModal"
                        ></primaryButton>
                    </v-col>
                </v-row>
            </template> 
        </modal>
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            disableAddService:true,
            loadingWarehouses: false,
            loadingClients: false,
            loadingServices: false,
            loadingProjects: false,
            loadingQuotations: false,
            backupComments: "",
            serviceSelected: {},
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Servicio',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Referencia',
						align: 'center',
						value: 'reference',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                    
                    
                   
					// {
					// 	text: '',
					// 	align: 'right',
					// 	sortable: false,
					// 	value: 'actions',
					// 	width: '10%',
					// 	class: 'table-b-border black--text'
					// }
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,4})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                max30:
                [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v.length > 30)
                            return "El campo no debe ser mayor a 130"
                        return true;
                    }
                ],
				
				
            },

            modalProps: {
				visible: false,
				width: '600',
				title: 'Guardar Borrador',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			cancelModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false; this.values.comments = this.backupComments; }
            },
			acceptModal: {
                text: "Guardar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.saveDraft()}
            },

            serverItems:{
               
                warehouses: [],
                clients: [],
                services: [],
                projects: [],
                quotations:[],

                

            }
            

          
        }
    },
   
    mounted(){
        //check price permission ?
        if(this.$gates.hasPermission('saleOrders.update_amount')){
            this.tableProps.headers.push(
                {
                    text: 'Precio',
                    align: 'center',
                    value: 'amount',
                    width: "20%",
                    sortable: false,
                    class: 'table-b-border black--text',
                }
            );
        }
        //add actions col
        this.tableProps.headers.push(
            {
                text: '',
                align: 'right',
                sortable: false,
                value: 'actions',
                width: '10%',
                class: 'table-b-border black--text'
            }
        );
        this.getWarehouses();
        this.getClients();
        // this.getCurrencies();
        if(this.edit){
            this.loadServices();
            this.getProjects(this.values.client_id);
            this.getQuotations(this.values.project_id,false)
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.services = [];
          
        },
        loadServices()
        {
            this.values.services = this.values.services.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                let newServic = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.service_id, name: item.service, index: 99},
                    service_id: item.service_id,
                    name: item.service,
                    quantity : item.quantity,
                    amount: item.amount+"".replace(",",""),
                    reference: item.reference,
                }
                return newServic;
            })
        },
       
        getWarehouses()
        {
            // this.loadingWarehouses = true;
            // this.$api.warehouse.getSelect()
            //     .then((resp) => {
            //         this.serverItems.warehouses = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingWarehouses = false;
            //     })
            // ;
            this.serverItems.warehouses = this.$store.state.userData.warehousesRaw;
        },

        getQuotations(project_id)
        {
            if(project_id == null || project_id == undefined || project_id == "")
                return;

            this.loadingQuotations = true;
            this.$api.quotation.getSelectByProject(project_id)
                .then((resp) => {
                    //hacer map
                    this.serverItems.quotations = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingQuotations = false;
                })
            ;
            
        },
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelect(this.$store.state.warehouse)
                .then((resp) => {
                    //hacer map
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

      

        getProjects(client_id)
        {
            if(client_id == null || client_id == undefined || client_id == "")
                return;

            this.loadingProjects = true;
            this.$api.client.getProjectsSelect(client_id, this.$store.state.warehouse)
                .then((resp) => {
                    //hacer map
                    this.serverItems.projects = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProjects = false;
                })
            ;


        },

        getQuotationDetails(quotation_id)
        {
            if(quotation_id == null || quotation_id == undefined || quotation_id == "")
                return;

            const findItem = this.serverItems.quotations.find((item) => item.value == quotation_id);


            this.loadingServices = true;
            this.$api.quotation.getDetails(findItem.text)
                .then((resp) => {
                    
                    //set warehouse
                    this.values.warehouse_id = resp.data.data.warehouse_id;

                    //set services
                    resp.data.services.forEach(item => {
                        let newServic = {
                            actions: JSON.parse(JSON.stringify(this.actions)),
                            parameters: { id: item.service_id, name: item.service, index: 99},
                            service_id: item.service_id,
                            name: item.service,
                            quantity : 0,   //item.quantity,
                            amount: item.amount.replace(",",""),
                            reference: "",
                        }

                        this.values.services.push(newServic);
                    });
                    delete this.serverErrors.services;
                    this.disableAddService = true;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingServices = false;
                })
            ;
        },


        addService()
        {
            console.log(this.serviceSelected);

            if(!("value" in this.serviceSelected))
                return;

            let found = this.values.services.find(fItem => fItem.service_id == this.serviceSelected.value)
            if(found != undefined)
                return;

            let item = this.serviceSelected;
            let newServic = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: item.text, index: 99},
                service_id: item.value,
                name: item.text,
                quantity : 0,   //item.quantity,
                amount: item.amount.replace(",",""),
                reference: "",
            }

            this.values.services.push(newServic);

            
        },

       

        

        deleteItem(item)
        {
            let index = this.values.services.findIndex(prod => prod.service_id == item.id);

            console.log(index, item);
            this.values.services.splice(index, 1);
        },

        openDraftModal(parameters)
		{
			console.log(parameters);
			// this.selectedID = parameters.id;
			// this.modalProps = {
			// 	...this.modalProps,
			// 	text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			// }
            // console.log(this.selectedID)
			this.modalProps.visible = true;
            
            this.backupComments = this.values.comments;
			
		},

        saveDraft()
        {

            this.$emit('actionModal', "yesss run run");
        }
       
    }
}
</script>

<style>

</style>