<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        data-cy="search-input"
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'billings.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" :key="keyControl">
                        
                
                    </datatable>
                </v-col>
            </v-row>


            <modal data-cy="index-comments-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- FORM -->
                <template slot="cardBody">
                    <v-form v-if="!modalProps.loading" ref="form" @submit.prevent="save">
                        <condition-form :key="keyModal" ref="ConditionForm" :values="form" @save="storeCondition" :serverErrors="serverErrors"></condition-form>
                    </v-form>
                </template> 
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end"  v-if="!modalProps.loading" data-cy="index-comments-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-comments-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-comments-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>


            <!-- DELETE -->
            <modal data-cy="index-delete-modal" :props="modalDeleteProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end" data-cy="index-delete-modal-actions">
					<v-col cols="12" sm="4">
						<secondaryButton
                        data-cy="cancel-modal-btn"
						:props="cancelDeleteModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" sm="4">
						<primaryButton
                        data-cy="accept-modal-btn"
						:props="acceptDeleteModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

            
            
        </template>
    </contentCardIndex>
        
</template>
<script>
import Utils from '../../../helpers/Utils';
import BillingPdf from "./BillingPdf";
import ConditionForm from "./ConditionForm.vue";

export default {
    components: {
        'condition-form': ConditionForm,
    },
    data(){
		return {
            keyControl: 0,
            keyModal: 363,
			search: '',
            selectedID: '',
            startDate: "",
            endDate: "",
            clientId: "",

            loading: false,

            serverErrors: {},
            form: {
                conditions: "",
            },
          

          
			actions: [
                
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect mdiCash
					action: '',
                    permissions: 'billings.show',
                    dataCy: "details-link"
				},
                {
					name: 'Comentarios',
					icon: {
						color: 'secondary',
						icon: 'mdi-message-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openConditionModal',
                    permissions: 'billings.show',
                    dataCy: "comments-link"
				},

                {
					name: 'PDF',
					icon: {
						color: 'secondary',
						icon: 'mdi-printer-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect m
					action: 'getPdf',
                    loading:false,
                    permissions: 'billings.show',
                    dataCy: "pdf-link"
				},

                {
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: true,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'billings.delete',
                    dataCy: "delete-link"
				},
               
				
			],
			tableProps: {
				headers: [
                     {
						text: 'Cliente',
						align: 'left break-words',
						value: 'business_name',
						class: 'table-b-border black--text'
					},
					{
						text: 'Almacén',
						align: 'left',
						value: 'warehouse',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proyecto',
						align: 'left',
						value: 'project',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Corte',
						align: 'left',
						value: 'period',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Monto',
						align: 'left',
						value: 'total',
						
						class: 'table-b-border black--text'
					},
                   
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '12%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy:"sku",
                sortDesc: true,
			},
			
			
            // createBtnData: {
            //     text: "Nueva tarifa",
            //     icon: "mdi-plus",
			// 	to: "QuotationsCreate",
			// 	block: false,
            //     click: ()=>{}
            // },

			modalProps: {
				visible: false,
				width: '500',
				title: '',
				text: '',
				loading: false,
				bottomLineColor: 'transparent',
				
			},

			modalDeleteProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},

			
			cancelModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeModal();}
            },
			cancelDeleteModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeModal();}
            },

         
			acceptModal: {
                text: "Actualizar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.storeCondition()}
            },

            acceptDeleteModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },

            createBtnData: {
                text: "Nueva factura",
                icon: "mdi-plus",
				to: "BillingCreate",
				block: false,
                click: ()=>{}
            },

		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
            console.log(this.$store.state.warehouse, "ware")
            if(this.$store.state.warehouse != "all")
                this.tableProps.headers = this.tableProps.headers.filter((item) => item.value != "warehouse")
			this.tableProps.loading = true;
            this.tableProps.items = [];
			// call api
			this.$api.billing.list(this.$store.state.warehouse)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                        // x['parameters'] = { id: x.billing_id, name: x.business_name};

                        if(x.billing_id != null){
                            x.actions[3].disabled = false;
                            x.actions[0].action = { name: 'BillingDetailsBillingId', params: { id: x.project_id, startDate: x.start_period, endDate: x.end_period, clientId: x.slug, billingId: x.billing_id??"_" } }
                        }else{

                            x.actions[0].action = { name: 'BillingDetails', params: { id: x.project_id, startDate: x.start_period, endDate: x.end_period, clientId: x.slug, billingId: x.billing_id??"_" } }
                        }

                        
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        // x.actions[1].action = { name: '', params: { id: x.sku } }
                       
                        //payment: ele.total_details - ele.total_payment !== 0 ? 'Sin pagar' : 'Pagada'
						x['parameters'] = { id: x.project_id, startDate: x.start_period, endDate: x.end_period, clientId: x.slug, name: x.business_name, index: i, billing_id: x.billing_id};
                        x.period = `${x.start_period} - ${x.end_period}`
                        x.total = Utils.currencyFormat(x.total, "");
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getPdf(parameters)
		{
            this.selectedID = parameters.id;
            this.tableProps.items[parameters.index].actions[2].loading = true
			//console.log(this.selectedID);
			this.$api.billing.pdf(this.selectedID, parameters.startDate, parameters.endDate, parameters.clientId)
                // eslint-disable-next-line
                .then( (response) => {
                    if(response.data.data.conditions?.toLowerCase() === "n/a")
                        response.data.data.conditions = "";

                    this.getAddressInfo(response.data)
                    // FeePdf.openDocument(response.data);
                    
                    // eslint-disable-next-line
                }).catch(error => {
                    this.$store.dispatch('snackbarError', `Error al generar pdf. Intenta de nuevo`);
                    
                })
                .finally(() => {
                    this.tableProps.items[parameters.index].actions[2].loading = false;
                })
            ;
		},

        getAddressInfo(pdfData)
        {
            //Av. Sierra Leona 418, Lomas 2da Secc 78210 San Luis, S.L.P.
            // if(pdfData.data.address_code == ""){
                pdfData.data.address_data = {
                    state: "San Luis",
                    city: 'S.L.P',
                    street: 'Av. Sierra Leona',
                    external_number: '418',
                    internal_number: '',
                    neighborhood: 'Lomas 2da Secc',
                    zipcode: '78210'
                }
                // pdfData.data.address_data = 55
                BillingPdf.openDocument(pdfData);
                return;
            // }
            // const result = await addressAPIAxios.get(`/getCityDetails/${pdfData.address_code}`);
            // this.$api.addressApi.getCity(pdfData.data.address_code)
            //     // eslint-disable-next-line
            //     .then((result) => {
            //         pdfData.data.address_data = {
            //             state: result.data.state?.name??'N/A',
            //             city: result.data.city?.state_code??'N/A',
            //             street: pdfData.data.street,
            //             external_number: pdfData.data.external_number,
            //             internal_number: pdfData.data.internal_number,
            //             neighborhood: pdfData.data.neighborhood,
            //             zipcode: pdfData.data.zipcode
            //         }
            //         BillingPdf.openDocument(pdfData);
            //         return;
            //         // eslint-disable-next-line
            //     }).catch(error => {
            //         pdfData.data.address_data = {
            //             state: "San Luis",
            //             city: 'S.L.P',
            //             street: 'Av. Sierra Leona',
            //             external_number: '418',
            //             internal_number: '',
            //             neighborhood: 'Lomas 2da Secc',
            //             zipcode: '78210'
            //         }
            //         BillingPdf.openDocument(pdfData);
            //         return;
            //     })
                
            // ;
           
        },

        openConditionModal(parameters)
        {
            // this.modalPaymentProps = {
            //     ...this.modalPaymentProps,
			// 	text: `GENERAR PAGO - "${parameters.name}"`,
				
			// }
            this.selectedID = parameters.id;
            this.startDate = parameters.startDate;
            this.endDate = parameters.endDate;
            this.clientId = parameters.clientId;

            // this.tableProps.items[parameters.index].actions[0].loading = true;
            this.modalProps.title = ""
            this.modalProps.visible = true;
            this.modalProps.loading = true;
            // this.keyControl = this.keyControl + 1;
            this.$api.billing.getConditions(this.selectedID, parameters.startDate, parameters.endDate, parameters.clientId)
				.then((resp) =>{
					//console.log(resp);
                   
                    this.modalProps.title = "Comentarios"
                    console.log(this.selectedID)
                    this.resetForm();
                    this.form.conditions = resp.data.conditions;
                    this.keyModal = this.keyModal + 1
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
                    // this.tableProps.items[parameters.index].actions[0].loading = false;
                    // this.keyControl = this.keyControl + 1;
                    // console.log(this.tableProps.items[parameters.index].actions[0])
                     this.modalProps.loading = false;
				})
			;
        },

        closeModal()
        {
            this.modalProps.title = ""
            this.resetForm();
            this.modalProps.visible = false;
            this.modalDeleteProps.visible = false;
        },

        resetForm()
        {
            this.form.conditions = "";
            this.serverErrors = {};

            try {
                this.$refs.form.reset();
                
            } catch (error) {
                //something
            }
        },

        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},

        storeCondition()
        {
            if(this.validate()){
                
                
                console.log(this.form);
                // this.modalProps.loading = true;
                this.acceptModal.loading = true;
                // this.$store.state.loading = true;
                this.$api.billing.storeConditions(this.selectedID, this.startDate, this.endDate, this.clientId, this.form)
                    .then(() => {
                        //console.log(response)


                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.closeModal();
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.acceptModal.loading = false;

                        // this.modalProps.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },

        deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.billing_id;
			this.modalDeleteProps = {
				...this.modalDeleteProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalDeleteProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptDeleteModal.loading = true;
			this.$api.billing.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
                
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalDeleteProps.visible = false;
				this.acceptDeleteModal.loading = false;
			})
		},

		

    }
}
</script>