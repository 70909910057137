<template>
    <v-card height="480" class="overflow-scroll test-scroll" :loading="loading">
        
        <v-card-text>
            <!-- buttons -->
            <v-row>
              
                <v-col class=" mx-0" >
                    <div class="card-top-buttons ">
                        <p class="card-title">
                            {{title}}
                        </p>
                        
                    </div>
                </v-col>
                 
            </v-row>
            <!-- buttons -->
            <!-- <v-row justify="start">
                <v-col class="pt-0 mt-0 pr-0 " cols="6">
                    <alternativeAutocompleteOne
                        :items="clients"
                        :valueInput="clientSelectedId"
                        @valueChange="(v) => {clientSelectedId = v; projectSelected = '';  getProjects()}"
                        @onChange="(v) => {}"
                        :label="'Clientes'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 px-1" cols="6">
                    <alternativeAutocompleteOne
                        :items="projects"
                        :valueInput="projectSelected"
                        @valueChange="(v) => {projectSelected = v;  makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Proyecto'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 pr-0"  cols="6">
                    <alternativeAutocompleteOne
                        :items="years"
                        :valueInput="yearSelected"
                        @valueChange="(v) => {yearSelected = v;  makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Año'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 px-1" cols="6">
                    <alternativeAutocompleteOne
                        :items="months"
                        :valueInput="monthSelected"
                        @valueChange="(v) => {monthSelected = v;  makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Mes'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                
                
            </v-row> -->
            

            <!-- graph -->
            <v-row>
                <v-col class="pt-0 mt-0" cols="7">
                    <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                        ref="bar"
                    />
                </v-col>
                <!-- LEGENDS -->
                <v-col cols="5" class="">
                    <div class="legends-col">
                        
                        <div v-for="(item, index) in chartData.customLegends.legends" :key="index" class="legends-container">
                            <div>
                                <v-avatar
                                    :color="item.color"
                                    rounded
                                    size="20"
                                    class="mr-1 mb-1"
                                ></v-avatar>
                                <span class="">{{item.text}}</span>
                            </div>
                            <span class="ml-1">{{item.amount}}</span>
                        </div>
                        
                    </div>

                    
                    <div :class="`legends-container mt-${index == 0 ? '3':'0'}`" v-for="(item, index) in chartData.customLegends.totals" :key="index">
                        <div>
                            
                            <span :class="`font-weight-black ${item.class}`">{{item.title}}</span>
                        </div>
                        <span :class="`font-weight-black ${item.class}`">{{item.value}}</span>
                    </div>
                    <!-- <div class="legends-container mt-0">
                        <div>
                            
                            <span class="font-weight-bold legends-total-2 ">TOTAL ESPERADO</span>
                        </div>
                        <span class="font-weight-bold legends-total-2">{{chartData.customLegends.totalExpect}}</span>
                    </div> -->
                    
                    
                    
                </v-col>
                
            </v-row>
        </v-card-text>
        
    </v-card>

</template>
<script>
import Utils from '../../helpers/Utils'

import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip,  BarElement, CategoryScale, LinearScale)

export default {
    props: ['valueInput', 'title','color', 'labels', 'chartDataInput', 'lastPage', 'loadData', 'loading', 'clients', 'years', 'months', 'yAxisCurrency'],
    components: { Bar },
    model: {
        prop: 'chartData',
        event: 'valueChange'
    },
   
    computed: {
        // value: {
        //     get: function() {
        //         return this.valueInput
        //     },
        //     set: function(value) {
                
        //         this.$emit('valueChange', value)
        //     }
        // },
        chartData: {
            get: function() {
                return this.chartDataInput
            },
            set: function(value) {
                
                this.$emit('valueChange', value)
            }
        },
    },
    data(){
        return{
            actualDate: null,

            projects: [],
            internalLoading: false,
            projectSelected: "",
            clientSelectedId: "",
            yearSelected: "",
            monthSelected:"",
            dateRange: ['2019-09-10', '2019-09-20'],
            page: 1,
            // chartData: {
            //     labels: this.labels,
            //     datasets: [
            //         {
                        
            //             backgroundColor: this.color,
            //             data: this.values
            //         }
            //     ]
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    
                    x: {
                        stacked: true,
                        categoryPercentage: 0.5,
                        barThickness: 6,  // number (pixels) or 'flex'
                        maxBarThickness: 8, // number (pixels)
                        ticks: {
                            // maxRotation: 90,
                            // minRotation: 90,
                            font: {
                                size: 9,
                            },
                            // callback: function(value) {
                            //     return Utils.currencyFormat(value,"")
                            // }
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            callback:(value) => {
                                if(this.yAxisCurrency)
                                    return Utils.currencyFormat(value,"");
                                return value;
                            }
                        }
                    }
                },
                plugins: {
                    // responsive: true,
                    // maintainAspectRatio: false,
                    // circumference: 180,
                    legend: {
                        display:false,
                        position: 'right',
                        legendCallback: function(e, legendItem, legend) { 
                            console.log(e.chart.data, legendItem.title.text, legend)
                            legendItem.title.text = "aaaa"
                            return "test"; 
                        },
                    },
                    
                    tooltip:{
                        callbacks:{
                            title: (context) =>{
                                // console.log(item, context)
                                return context[0].label.substring(0,20)
                            }
                        }
                    }
                    
                    

                }
            },
            chartId: 'bar-chart',
            datasetIdKey: 'label',
            width: 380,
            height: 380,
            cssClasses: '',
            styles: {
                
                
            },
            plugins: [
                
            ],
            
            

            backBtn: {
                text: "",
                icon: "mdi-chevron-left",
				to: "",
				block: false,
                textClass:"",
                height: 29,
                click: ()=>{this.prevPage(); this.makeRequest()}
            },
            nextBtn: {
                text: "",
                icon: "mdi-chevron-right",
				to: "",
				block: false,
                textClass:"",
                height: 29,
                click: ()=>{this.nextPage(); this.makeRequest()}
            },


          

        }
    },
    mounted(){
        this.getActualDates( new Date());
        
        // if(this.loadData)
        //     this.makeRequest();
    },

    methods:{
        getProjects()
        {
            if(this.clientSelectedId == "")
                return;
            this.internalLoading = true;
            this.projects = [];

            this.$api.client.getProjectsSelect(this.clientSelectedId, this.$store.state.warehouse)
                .then((resp) => {
                    console.log(resp.data);
                    this.projects = resp.data
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.internalLoading = false;
                })
            ;
        },
        resetPage()
        {
            this.page = 1;
        },
        getActualDates(dateToWork){
            this.actualDate = dateToWork;

            this.actualMonth = dateToWork.getMonth() + 1;
            this.actualYear = dateToWork.getFullYear();
            // let actualDate = new Date(actualYear, actualMonth, 1);

            this.yearSelected = ""+this.actualYear;
            this.setMonth(this.actualMonth);
            // let lastDate = Utils.addMonths(actualDate,1)
           
            // this.dateRange = [`${actualYear}-${this.getMonthText(actualMonth)}-01`, `${lastDate.getFullYear()}-${this.getMonthText(lastDate.getMonth())}-01`];
            // console.log(actualMonth, actualYear, actualDate, lastDate);
        },
        setMonth(actualMonth){
            this.monthSelected = actualMonth >= 10 ? ""+actualMonth : "0"+actualMonth;

        },
        getMonthText(monthValue){
           return monthValue >= 9 ? `${monthValue+1}`:`${monthValue+1}`
        },
        getNextRange()
        {
            let activeDate = this.dateRange[1].split("-");
            // let arrayDate = activeDate.
            let dateValue = new Date(activeDate[0], activeDate[1]-1,1);
            // let nextStart = Utils.addMonths(dateValue,1);
            this.getActualDates(dateValue);
        },
        getPrevRange()
        {
            this.actualMonth = (this.actualMonth % 12) + 1;
            console.log(this.actualMonth)
        },
        nextPage()
        {
           
            if(this.page >= this.lastPage)
                return;
            this.page = this.page + 1;
             console.log(this.page, this.lastPage)
        },
        prevPage()
        {
            
            if(this.page == 1)
                return;
            this.page = this.page - 1;
            console.log(this.page, this.lastPage)
        },
        checkClickAppend()
        {
            this.$emit('clickAppend');
        },
       
        makeRequest()
        {
            // console.log(this.dateRange, this.currencySelected);
            this.$emit('makeRequest', {clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:this.page});
        }

    }


   
}
</script>

<style lang="scss">
    .card{
        &-title{
            color: #241764;
            font-size: 11px;
            padding-bottom: 0;
            margin-bottom: 0 !important;
            padding-top: 10px;
            padding-left: 5px;
            font-weight: bold;
        }

        &-top-buttons{
            display: flex;
            gap: 5px;
            // justify-content: end;
            justify-content: space-between !important;

            &-cont{
                display: flex;
                gap: 5px;
            }
        }
    }

    .btn-alt{
        min-width: 20px !important;
        padding: 7px !important;
        font-size: 10px !important;
    }

    .overflow-scroll{
        overflow: auto;
    }

    .mini-mode{
        height: 30px;
        align-items: center !important;

        .body-1{
            font-size: 0.65rem !important;
        }

        .v-input{
            font-size: 0.65rem !important;
        }
        
    }

   
    .legends{
        &-container{
            display: flex;
            justify-content: space-between;
            span{
                font-size: 0.7rem;
                @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
                    font-size: 0.6rem;
                }
            }
        }
        &-col{
            max-height: 280px;
            overflow: auto;
        }

        &-total-1{
            font-size: .84rem !important;
        }
        &-total-2{
            font-size: .8rem !important;
            color: #757575;
        }
    }
   
    
</style>