<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="8" lg="8" xl="5">
                        <div class="font-weight-bold body-1">
                            Detalles de tarifa
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" xl="5" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                <!-- services -->
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">Servicios</p>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                        <table-items :props="tableProps" :items="services">
                            
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>


                
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" lg="8" xl="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            services: [],

            tableProps: {
				headers: [
                     {
						text: 'Servicio',
						align: 'left break-words',
						value: 'service',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'P.U.',
						align: 'center',
						value: 'amount',
                        width: "15%",
						sortable: false,
						class: 'table-b-border black--text'
					},

                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "15%",
						sortable: false,
						class: 'table-b-border black--text'
					},

                    
                   
					
				],
				items: [],
				search: '',
				loading: false
			},

         
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Quotations",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.quotation.get(this.id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Cliente', index: 'prospect', valor:response.data.data.client},
                    { concepto: 'Folio', index: 'prospect', valor:response.data.data.folio},
                    { concepto: 'Fecha', index: 'description', valor:response.data.data.date},
                    { concepto: 'Proyecto', index: 'project_name', valor:response.data.data.project},
                    { concepto: 'Vigencia', index: 'validity', valor:response.data.data.validity},
                    { concepto: 'Divisa', index: 'foreign_exchange', valor: response.data.data.foreign_exchange },
                    { concepto: 'Encabezado', index: 'header', valor:response.data.data.header},
                    { concepto: 'Condiciones', index: 'terms', valor:response.data.data.terms},
                    { concepto: 'Almacén', index: 'warehouse', valor:response.data.data.warehouse},
                    { concepto: 'Comentarios', index: 'comments', valor:response.data.data.comments},
                    
                ];

            
                //products 
                this.services = response.data.services.map((x) => {
                    x.amount = Utils.currencyFormat(x.amount.replace(",",""),"")            
                    
                    
                    return x;
                });

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 