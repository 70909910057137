<template>
    <div>
       
        <v-row>
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha de la salida"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.quantity = v; delete serverErrors.quantity}" 
                    :valueInput="values.quantity" 
                    :rule="rules.quantity" 
                    label="Cantidad"
                    :errorMessages="serverErrors.quantity"
                    data-cy="quantity-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.comment = v; delete serverErrors.comment}" 
                    :valueInput="values.comment" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comment"
                    data-cy="comments-input"
                ></textFieldForm>
            </v-col>
        </v-row>
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
              

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            
            

          
        }
    },
   
    mounted(){
        
    },
    methods: {
       
    }
}
</script>

<style>

</style>