var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-2"},[_c('datePickerForm',{attrs:{"label":"Fecha","valueInput":_vm.values.date,"rule":_vm.rules.required,"errorMessages":_vm.serverErrors['date'],"data-cy":"date-input"},on:{"valueChange":(v) => {_vm.values.date = v; delete _vm.serverErrors['date']}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2"},[_c('autocompleteOneForm',{attrs:{"label":"Proveedor","loading":_vm.loadingSuppliers,"valueInput":_vm.values.supplier_id,"rule":_vm.rules.required,"items":_vm.serverItems.suppliers,"errorMessages":_vm.serverErrors.supplier_id,"data-cy":"supplier_id-input"},on:{"valueChange":(v) => {_vm.values.supplier_id = v; this.clear(); this.getProducts(v); delete _vm.serverErrors.supplier_id}}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-3 pb-0 font-weight-bold body-2 mb-0"},[_c('p',{staticClass:"mb-0 pb-0"},[_vm._v("Agregar equipo/consumible")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"9","sm":"10","xl":"11"}},[_c('autocompleteOneForm',{attrs:{"label":"Equipo/Consumible","loading":_vm.loadingProducts,"valueInput":_vm.productSelected,"rule":[],"items":_vm.serverItems.products,"returnObject":true,"data-cy":"productSelected-input"},on:{"valueChange":(v) => {_vm.productSelected = v;}}})],1),_c('v-col',{staticClass:"py-2"},[_c('iconButton',{attrs:{"data-cy":"productSelected-input-btn","props":{
                color:'primary',
                icon:'mdi-plus',
                height:'41',
                
                click: () => {_vm.addProduct();delete _vm.serverErrors.products}
                }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2"},[_c('table-items',{attrs:{"data-cy":"table-items","props":_vm.tableProps,"items":_vm.values.products},on:{"methodHandler":_vm.method_handler},scopedSlots:_vm._u([{key:"item.quantity",fn:function(props){return [_c('div',{staticClass:"table-item"},[_c('textFieldForm',{attrs:{"valueInput":props.item.quantity,"rule":[..._vm.rules.quantity],"data-cy":"table-quantity-input"},on:{"valueChange":(v) => {props.item.quantity = v; delete _vm.serverErrors.products}}})],1)]}}])})],1)],1),(_vm.serverErrors[`products`])?_c('span',{staticClass:"red--text caption"},[_vm._v(_vm._s(_vm.serverErrors[`products`]))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }