<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center" md="8" lg="8" xl="5">
                        <div class="font-weight-bold body-1">
                            Detalles de Órden de compra
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="8" xl="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>

                <!-- PRODUCTS -->
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">EQUIPO/CONSUMIBLES</p>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                        <table-items :props="tableProps" :items="products">
                            
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>


                <!-- SUMMARY --> 
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" cols="12" md="8" lg="8" xl="5">
                        <p class="mb-0 pb-0">RESUMEN ÓRDENES DE ENTRADA</p>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col class="pb-2 pt-0 mt-0" cols="12" md="8" lg="8" xl="5">
                        <table-items :props="tablePropsSummary" :items="summary">
                            
                           
                            
                        </table-items>
                        
                    </v-col>
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" lg="8" xl="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            products: [],
            summary:[],

            tableProps: {
				headers: [
                     {
						text: 'EQUIPO/CONSUMIBLE',
						align: 'left break-words',
						value: 'product',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
						sortable: false,
                        width: "18%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Importe',
						align: 'center',
						value: 'amount',
						sortable: false,
                        width: "18%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Subtotal',
						align: 'center',
						value: 'subtotal',
						sortable: false,
                        width: "18%",
						class: 'table-b-border black--text'
					},
                   
					
				],
				items: [],
				search: '',
				loading: false
			},

            tablePropsSummary: {
				headers: [
                     {
						text: 'EQUIPO/CONSUMIBLE',
						align: 'left break-words',
						value: 'product',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},

                     {
						text: 'Fecha',
						align: 'center',
						value: 'date',
						sortable: false,
                        width: "15%",
						class: 'table-b-border black--text'
					},
					
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'entry',
						sortable: false,
                        width: "10%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Pendiente',
						align: 'center',
						value: 'pending',
						sortable: false,
                        width: "10%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Excedente',
						align: 'center',
						value: 'surplus',
						sortable: false,
                        width: "10%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Total',
						align: 'center',
						value: 'total',
						sortable: false,
                        width: "10%",
						class: 'table-b-border black--text'
					},
                   
					
				],
				items: [],
				search: '',
				loading: false
			},
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "PurchaseOrders",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.purchaseOrder.show(this.id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Proveedor', index: 'sku', valor: response.data.data.supplier },
                    { concepto: 'Fecha', index: 'description', valor:response.data.data.date},
                    { concepto: 'Requisición', index: 'unit', valor: response.data.data.requisition_id??"" },
                    
                ];

                if(response.data.data.invoice != '' && response.data.data.invoice != null){
                    this.items.push(
                        // {concepto: 'Factura', index: 'quotation_file_url', valor: response.data.data.invoice, isFile:true}
                        //! remove when file is available
                        { concepto: 'Adjunto', index: 'slug', valor: '' },
                    );
                }else{
                    this.items.push(
                        { concepto: 'Factura', index: 'slug', valor: '' },
                    );
                }

                //products 
                this.products = response.data.products.map((x) => {
                    
                    x.subtotal = x.quantity * x.amount;
                    
                    x.subtotal = Utils.currencyFormat(x.subtotal, "");
                    x.amount = Utils.currencyFormat(x.amount, "");
                    
                    
                    return x;
                });

                this.summary = response.data.summary;
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 