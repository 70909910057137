<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.business_name = v; delete serverErrors.business_name}" 
                    :valueInput="values.business_name" 
                    :rule="[...rules.required]" 
                    label="Razón social"
                    :errorMessages="serverErrors.business_name"
                    data-cy="business_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.tradename = v; delete serverErrors.tradename}" 
                    :valueInput="values.tradename" 
                    :rule="[...rules.required]" 
                    label="Nombre comercial"
                    :errorMessages="serverErrors.tradename"
                    data-cy="tradename-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.rfc = v; delete serverErrors.rfc}" 
                    :valueInput="values.rfc" 
                    :rule="[...rules.required, ...rules.length]" 
                    label="R.F.C."
                    :errorMessages="serverErrors.rfc"
                    data-cy="rfc-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.credit_days = v; delete serverErrors.credit_days}" 
                    :valueInput="values.credit_days" 
                    :rule="[...rules.credit_days]" 
                    label="Días de crédito"
                    :errorMessages="serverErrors.credit_days"
                    data-cy="credit_days-input"
                ></textFieldForm>
            </v-col>
        </v-row>

       


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.credit_amount = v; delete serverErrors.credit_amount}" 
                    :valueInput="values.credit_amount" 
                    :rule="rules.credit_amount" 
                    label="Monto del crédito"
                    :errorMessages="serverErrors.credit_amount"
                    :prefix="values.credit_amount === '' ? '' : '$'"
                    data-cy="credit_amount-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                    :valueInput="values.email" 
                    :rule="[...rules.email]" 
                    label="Correo"
                    :errorMessages="serverErrors.email"
                    data-cy="email-input"
                ></textFieldForm>
            </v-col>
        </v-row>



       <!-- pais -->
       <!-- estado -->
       <!-- municpio -->
       

        <v-row>
            <v-col class="py-2">
                <addressCodeSelector
                    @valueChange="(v) => {values.address_code = v; delete serverErrors.address_code}" 
                    :valueInput="values.address_code" 
                    :rule="[...rules.required]" 
                
                    :errorMessages="serverErrors.address_code"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.colony = v; delete serverErrors.colony}" 
                    :valueInput="values.colony" 
                    :rule="[...rules.required]" 
                    label="Colonia"
                    :errorMessages="serverErrors.colony"
                    data-cy="colony-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.street = v; delete serverErrors.street}" 
                    :valueInput="values.street" 
                    :rule="[...rules.required]" 
                    label="Calle"
                    :errorMessages="serverErrors.street"
                    data-cy="street-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.external_number = v; delete serverErrors.external_number}" 
                    :valueInput="values.external_number" 
                    :rule="[...rules.required]" 
                    label="No. Exterior"
                    :errorMessages="serverErrors.external_number"
                    data-cy="external_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.internal_number = v; delete serverErrors.internal_number}" 
                    :valueInput="values.internal_number" 
                    :rule="[]" 
                    label="No. Interior"
                    :errorMessages="serverErrors.internal_number"
                    data-cy="internal_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.zipcode = v; delete serverErrors.zipcode}" 
                    :valueInput="values.zipcode" 
                    :rule="[...rules.required]" 
                    label="Código postal"
                    :errorMessages="serverErrors.zipcode"
                    data-cy="zipcode-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone = v; delete serverErrors.phone}" 
                    :valueInput="values.phone" 
                    :rule="[...rules.required]" 
                    label="Teléfono"
                    :errorMessages="serverErrors.phone"
                    data-cy="phone-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-radio-group
                v-model="values.type"
                mandatory
                label="Tipo de proveedor"
                data-cy="type-input"
            >
                <v-col cols="12" class="py-2">
                    <radioItemForm
                        label="Gastos"
                        valueInput="Gastos"
                        title=""
                        data-cy="type-input-gastos-value"
                    >
                    </radioItemForm>
                    
                    
                </v-col>
                <v-col cols="12" class="py-2">
                    <radioItemForm
                        label="Compras"
                        valueInput="Compras"
                        title=""
                        data-cy="type-input-compras-value"
                    >
                    </radioItemForm>
                    
                    
                </v-col>
            </v-radio-group>
            
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.contact_name = v; delete serverErrors.contact_name}" 
                    :valueInput="values.contact_name" 
                    :rule="[]" 
                    label="Nombre de contacto"
                    :errorMessages="serverErrors.contact_name"
                    data-cy="contact_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>



       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showPass: false,
            loadingDepartments: false,
            loadingUnits: false,
            loadingTypes: false,
            loadingSuppliers: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                credit_amount: [
                    v => {
                         if(v === null || v.toString().trim() === '')
                            return true;

                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                       
                        return true;
                    }
                ],

                length: [
                    v => {
                        if(v!= null && v.length < 12)
                            return 'Al menos 12 caracteres';

                        if(v!= null && v.length > 13)
                            return 'No puede ser mayor a 13 caracteres';
                       
                        return true;
                    }
                ],

                credit_days: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
				email: [
                    (v) => {
                        if(v == null || v == "")
                            return true;

                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
				
            },

            serverItems:{
                departments: [],
                units: [],
                types: [],
                suppliers: [],
            }
            

          
        }
    },
   
    mounted(){
        // this.getDepartments();
        // this.getUnits();
        // this.getTypes();
        // this.getSuppliers();
    },
    methods: {
        getSuppliers()
        {
            this.loadingSuppliers = true;
            this.$api.supplier.getSelect()
                .then((resp) => {
                    this.serverItems.suppliers = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSuppliers = false;
                })
            ;
        },

        getTypes()
        {
            this.loadingTypes = true;
            this.$api.type.getSelect()
                .then((resp) => {
                    this.serverItems.types = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingTypes = false;
                })
            ;
        },

        getDepartments()
        {
            this.loadingBusiness = true;
            this.$api.department.getSelect()
                .then((resp) => {
                    this.serverItems.departments = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBusiness = false;
                })
            ;
        },

        getUnits()
        {
            this.loadingUnits = true;
            this.$api.unit.getSelect()
                .then((resp) => {
                    this.serverItems.units = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUnits = false;
                })
            ;
        }
       
    }
}
</script>

<style>

</style>