<template>
    <div>
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.project_name = v; delete serverErrors.project_name}" 
                    :valueInput="values.project_name" 
                    :rule="[...rules.required]" 
                    label="Nombre del proyecto"
                    :errorMessages="serverErrors.project_name"
                    data-cy="project_name-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row >
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>
       

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Almacén"
                    :loading="loadingWarehouses"
                    :valueInput="values.warehouse_id"
                    :rule="rules.required"
                    :items="serverItems.warehouses"
                    :errorMessages="serverErrors.warehouse_id"
                    @valueChange="(v) => {values.warehouse_id = v; delete serverErrors.warehouse_id}"
                    data-cy="warehouse_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Prospecto"
                    :loading="loadingProspect"
                    :valueInput="values.prospect_id"
                    :rule="rules.required"
                    :items="serverItems.prospects"
                    :errorMessages="serverErrors.prospect_id"
                    @valueChange="(v) => {values.prospect_id = v; delete serverErrors.prospect_id}"
                    data-cy="prospect_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row >
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Vigencia"
                    :valueInput="values.validity"
                    :rule="rules.required"
                    @valueChange="(v) => {values.validity = v; delete serverErrors['validity']}"
                    :errorMessages="serverErrors['validity']"
                    data-cy="validity-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.header = v; delete serverErrors.header}" 
                    :valueInput="values.header" 
                    :rule="[...rules.required]" 
                    label="Encabezado"
                    :errorMessages="serverErrors.header"
                    data-cy="header-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.terms = v; delete serverErrors.terms}" 
                    :valueInput="values.terms" 
                    :rule="[...rules.required]" 
                    label="Condiciones"
                    :errorMessages="serverErrors.terms"
                    data-cy="terms-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textAreaForm 
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comments"
                    data-cy="comments-input"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Divisa"
                    data-cy="foreign_exchange-input"
                    :valueInput="values.foreign_exchange"
                    :rule="rules.required"
                    :items="serverItems.currencies"
                    :errorMessages="serverErrors.foreign_exchange"
                    @valueChange="(v) => {values.foreign_exchange = v; updateAmountsByCurrency(v); delete serverErrors.foreign_exchange}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        

        <v-row>
            <v-col class="py-2" cols="9" sm="10"  xl="11">
                <autocompleteOneForm
                    label="Servicios"
                    :loading="loadingServices"
                    :valueInput="serviceSelected"
                    :rule="[]"
                    :items="serverItems.services"
                    :returnObject="true"
                    data-cy="productSelected-input"
                    @valueChange="(v) => {serviceSelected = v;}"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <iconButton
                    data-cy="productSelected-input-btn"
                    :props="{
                    color:'primary',
                    icon:'mdi-plus',
                    height:'41',
                    click: () => {addService();delete serverErrors.services}
                    }"
                ></iconButton>
            </v-col>
        </v-row>

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.services">
                    
                    <template v-slot:item.amount="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.amount = v; delete serverErrors.services}" 
                                :valueInput="props.item.amount" 
                                :rule="[...rules.amount]" 
                                data-cy="table-amount-input"
                                
                            ></textFieldForm>
                        </div>
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`services`]">{{serverErrors[`services`]}}</span>
      
      

        
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            loadingWarehouses:false,
            loadingProspect:false,
            loadingServices:false,
            serviceSelected: {},
            currenciesBanxio: {cad: 0, usd: 0, eur: 0},
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Servicio',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'P.U.',
						align: 'center',
						value: 'amount',
                        width: "18%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
               
                warehouses: [],
                prospects: [],
                services: [],

                currencies: [
                    {value:"mxn", text:"Peso mexicano (MXN)" },
                    {value:"usd", text:"Dólar estadounidense (USD)" },
                    {value:"eur", text:"Euro (EUR)" },
                    {value:"cad", text:"Dólar canadiense (CAD)" },
                ]

            }
            

          
        }
    },
   
    mounted(){
        this.getWarehouses();
        this.getProspects();
        this.getServices();
        this.getCurrencies();
        if(this.edit){
            this.loadServices();
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        loadServices()
        {
            this.values.services = this.values.services.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.service_id, name: item.service, index: 99},
                    service_id: item.service_id,
                    name: item.service,
                    quantity : item.quantity,
                    foreign_exchange: item.foreign_exchange??this.values.foreign_exchange,
                    amount: item.amount
                }
            })
        },
        updateAmountsByCurrency(to)
        {
            for (let i = 0; i < this.values.services.length; i++) {
                this.values.services[i].amount = this.$api.banxioSIE.convertCurrencyTo(this.currenciesBanxio, this.values.services[i].foreign_exchange, to, this.values.services[i].amount)
                this.values.services[i].foreign_exchange = to;
            }
        },
        getCurrencies()
        {
            this.$api.banxioSIE.getCurrencyDataMXN()
                .then((resp) => {
                    console.log(resp.data);
                   
                    this.currenciesBanxio = this.$api.banxioSIE.processInformation(resp.data);
                    console.log(this.currenciesBanxio);
                })
                .catch((error) => {
                    this.currenciesBanxio = {cad: 0, usd: 0, eur: 0};
                })
                .finally(() => {
                    
                })
            ;
        },
        getWarehouses()
        {
            // this.loadingWarehouses = true;
            // this.$api.warehouse.getSelect()
            //     .then((resp) => {
            //         this.serverItems.warehouses = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingWarehouses = false;
            //     })
            // ;
            console.log("ware",this.$store.state.userData.warehousesRaw)
            this.serverItems.warehouses = this.$store.state.userData.warehousesRaw;
        },
        getProspects()
        {
            this.loadingProspect = true;
            this.$api.prospect.getSelect()
                .then((resp) => {
                    //hacer map
                    this.serverItems.prospects = resp.data.map(item => {
                        return {text: item.tradename, value: item.slug}
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProspect = false;
                })
            ;
        },


        addService()
        {
            console.log(this.serviceSelected);

            if(!("value" in this.serviceSelected))
                return;

            let found = this.values.services.find(fItem => fItem.service_id == this.serviceSelected.value)
            if(found != undefined)
                return;

            let item = this.serviceSelected;
            let newServic = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: item.text, index: 99},
                service_id: item.value,
                name: item.text,
                foreign_exchange: item.foreign_exchange??this.values.foreign_exchange,
                quantity : 1,
                amount: item.price,
               
            }
            if(this.values.foreign_exchange != ""){
                newServic.amount = this.$api.banxioSIE.convertCurrencyTo(this.currenciesBanxio, newServic.foreign_exchange, this.values.foreign_exchange, newServic.amount+"".replace(",",""));
                newServic.foreign_exchange = this.values.foreign_exchange;
            }

            this.values.services.push(newServic);

            
        },

       

        getServices()
        {
           
            // if(supplier == "" || supplier == null || supplier == undefined)
            //     return;

            this.loadingServices = true;
            this.$api.service.getSelect()
                .then((resp) => {
                    this.serverItems.services = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingServices = false;
                })
            ;
        },

        deleteItem(item)
        {
            let index = this.values.services.findIndex(prod => prod.service_id == item.id);

            console.log(index, item);
            this.values.services.splice(index, 1);
        }
       
    }
}
</script>

<style>

</style>