<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        data-cy="search-input"
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'clients.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" :key="keyControl">
                        <template v-slot:item.address_code="props" >

                            <p class="pb-0 mb-0">{{getDirection(props.item)}}</p>
                        </template>
                
                    </datatable>
                </v-col>
            </v-row>
            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end">
                        <v-col cols="12" sm="4" data-cy="index-modal-actions">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
        </template>
    </contentCardIndex>
		
</template>
<script>
// import Utils from "../../../helpers/Utils";
export default {
    data(){
		return {
           
            keyControl: 0,
			search: '',
            selectedID: '',
            
			actions: [
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'clients.show',
                    dataCy: "details-link"
				},
               
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'clients.edit',
                    dataCy: "edit-link"
				},
               
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'clients.delete',
                    dataCy: "delete-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Nombre',
						align: 'left break-words',
						value: 'tradename',
                        width: "18%",
						class: 'table-b-border black--text'
					},
					{
						text: 'Direcciones',
						align: 'left',
						value: 'address_code',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Correo',
						align: 'left',
						value: 'email',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Teléfono',
						align: 'left',
						value: 'phone',
						
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
			
            createBtnData: {
                text: "Nuevo Cliente",
                icon: "mdi-plus",
				to: "ClientsCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
           
			// call api
			this.$api.client.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = { name: 'ClientsDetails', params: { id: x.slug } }
                        x.actions[1].action = { name: 'ClientsEdit', params: { id: x.slug } }
						

						x['parameters'] = { id: x.slug, name: x.tradename, index: i};
                        x.address= "";
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getDirection(item)
        {
            // console.log("ok")
            // return "next"+item.address_code;
            if(item.address_code === "")
                return;

            let found = this.searchAddress(item.address_code);
            if(found != false){
                item.address = found.text;
                // this.keyControl = this.keyControl + 1;
                console.log(found);
                return found.text;
            }

            // return "not found"

            this.$api.addressApi.getCity(item.address_code)
            .then((resp) =>{
					let addressText = resp.data;

                    let country = addressText.country ? `${addressText.country.admin} - `: ""
                    
                    let state = addressText.state ? addressText.state.name : addressText.city ? addressText.city.state_code : ""

                    this.$store.state.addresses.push({code: item.address_code, text: `${country} ${state}`});
                    item.address = `${country} ${state}`;
                    return `${country} ${state}`
                    // this.keyControl = this.keyControl + 1;
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
				})
            ;
        },

        searchAddress(item)
        {
            let found = this.$store.state.addresses.find(address => address.code == item);
            if(found == undefined)
                return false;

            return found;
        },

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.client.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>