<template #default="{ errorMessages }">
    <div elevation="0">
    <v-tabs
        v-model="tab"
        centered
        grow
        active-class="font-weight-bold tab-active"
        slider-size="3"
    >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#1" :class="`tab-header   ${stClasses.st1}`" data-cy="tab-1-input">
            Datos Generales
        </v-tab>

        <v-tab href="#2" :class="`tab-header  ${stClasses.st2}`" data-cy="tab-2-input">
            Detalles de producto
        </v-tab>

        <v-tab href="#3" :class="`tab-header  ${stClasses.st3}`" data-cy="tab-3-input">
            Operación del cliente
        </v-tab>
    </v-tabs>

    <!-- STEP 1 "Datos generales" -->
    <v-tabs-items v-model="tab" >
        <v-tab-item value="1" class="mb-10">
            <div class="mt-3">
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Giro"
                            :valueInput="values.clients.business"
                            :rule="rules.required"
                            data-cy="business-input"
                            @valueChange="
                                (v) => {
                                    values.clients.business = v;
                                    delete serverErrors[`clients.business`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.business`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Nombre comercial"
                            :valueInput="values.clients.tradename"
                            :rule="rules.required"
                            data-cy="tradename-input"
                            @valueChange="
                                (v) => {
                                    values.clients.tradename = v;
                                    delete serverErrors[`clients.tradename`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.tradename`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Razón social"
                            :valueInput="values.clients.business_name"
                            data-cy="business_name-input"
                            :rule="rules.required"
                            @valueChange="
                                (v) => {
                                    values.clients.business_name = v;
                                    delete serverErrors[`clients.business_name`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.business_name`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="R.F.C."
                            :valueInput="values.clients.rfc"
                            :rule="rules.required"
                            data-cy="rfc-input"
                            @valueChange="
                                (v) => {
                                    values.clients.rfc = v;
                                    delete serverErrors[`clients.rfc`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.rfc`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2" cols="12">
                        <addressCodeSelector
                            name="p1"
                            @valueChange="(v) => {values.clients.address_code = v; delete serverErrors[`clients.address_code`]}" 
                            :valueInput="values.clients.address_code" 
                            :rule="rules.required" 
                        
                            :serverErrors="serverErrors"
                        />
                        
                    </v-col>
                    <span class="red--text mt-1 px-3">{{serverErrors[`clients.address_code`]}}</span>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.neighborhood = v; delete serverErrors[`clients.neighborhood`]}" 
                            :valueInput="values.clients.neighborhood" 
                            :rule="rules.required" 
                            name="p1"
                            label="Colonia"
                            data-cy="neighborhood-input"
                            :errorMessages="serverErrors[`clients.neighborhood`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.street = v; delete serverErrors[`clients.street`]}" 
                            :valueInput="values.clients.street" 
                            :rule="rules.required" 
                            name="p1"
                            label="Calle"
                            data-cy="street-input"
                            :errorMessages="serverErrors[`clients.street`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2" cols="6">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.external_number = v; delete serverErrors[`clients.external_number`]}" 
                            :valueInput="values.clients.external_number" 
                            :rule="rules.required" 
                            name="p1"
                            label="No. Exterior"
                            data-cy="external_number-input"
                            :errorMessages="serverErrors[`clients.external_number`]"
                        ></textFieldForm>
                    </v-col>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.internal_number = v; delete serverErrors[`clients.internal_number`]}" 
                            :valueInput="values.clients.internal_number" 
                            :rule="[]" 
                            name="p1"
                            label="No. Interior"
                            data-cy="internal_number-input"
                            :errorMessages="serverErrors[`clients.internal_number`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

               

                <v-row>
                    <v-col class="py-2" cols="6">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.zipcode = v; delete serverErrors[`clients.zipcode`]}" 
                            :valueInput="values.clients.zipcode" 
                            :rule="rules.required" 
                            name="p1"
                            label="Código postal"
                            data-cy="zipcode-input"
                            :errorMessages="serverErrors[`clients.zipcode`]"
                        ></textFieldForm>
                    </v-col>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.phone = v; delete serverErrors[`clients.phone`]}" 
                            :valueInput="values.clients.phone" 
                            :rule="rules.onlyNumbers" 
                            name="p1"
                            label="Teléfono"
                            data-cy="phone-input"
                            :errorMessages="serverErrors[`clients.phone`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2" cols="12">
                        <textFieldForm 
                            @valueChange="(v) => {values.clients.cell_phone = v; delete serverErrors[`clients.cell_phone`]}" 
                            :valueInput="values.clients.cell_phone" 
                            :rule="rules.onlyNumbers" 
                            name="p1"
                            label="Celular"
                            data-cy="cell_phone-input"
                            :errorMessages="serverErrors[`clients.cell_phone`]"
                        ></textFieldForm>
                    </v-col>
                    <!-- <v-col class="py-2">
                        <autocompleteOneForm
                            label="Lista de precios"
                            name="p1"
                            :valueInput="values.clients.price_list"
                            :rule="rules.required"
                            data-cy="price_list-input"
                            :items="serverItems.pricesList"
                            :errorMessages="serverErrors[`clients.price_list`]"
                            @valueChange="(v) => {values.clients.price_list = v; delete serverErrors[`clients.price_list`]}"
                        ></autocompleteOneForm>
                    </v-col> -->
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Uso del CFDI"
                            name="p1"
                            :valueInput="values.clients.cfdi"
                            :rule="rules.required"
                            :items="serverItems.cfdiList"
                            data-cy="cfdi-input"
                            :errorMessages="serverErrors[`clients.cfdi`]"
                            @valueChange="(v) => {values.clients.cfdi = v; delete serverErrors[`clients.cfdi`]}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Cuenta bancaria"
                            :valueInput="values.clients.bank_account"
                            data-cy="bank_account-input"
                            :rule="[]"
                            @valueChange="
                                (v) => {
                                    values.clients.bank_account = v;
                                    delete serverErrors[`clients.bank_account`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.bank_account`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Clabe"
                            :valueInput="values.clients.clabe"
                            :rule="[]"
                            data-cy="clabe-input"
                            @valueChange="
                                (v) => {
                                    values.clients.clabe = v;
                                    delete serverErrors[`clients.clabe`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.clabe`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Banco"
                            name="p1"
                            :valueInput="values.clients.bank_id"
                            :loading="loadingBanks"
                            data-cy="bank_id-input"
                            :rule="[]"
                            :items="serverItems.banks"
                            :errorMessages="serverErrors[`clients.bank_id`]"
                            @valueChange="(v) => {values.clients.bank_id = v; delete serverErrors[`clients.bank_id`]}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm
                            name="p1"
                            label="Nombre de contacto"
                            :valueInput="values.clients.contact_name"
                            :rule="rules.required"
                            data-cy="contact_name-input"
                            @valueChange="
                                (v) => {
                                    values.clients.contact_name = v;
                                    delete serverErrors[`clients.contact_name`];
                                }
                            "
                            :errorMessages="serverErrors[`clients.contact_name`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            name="p1"
                            @valueChange="(v) => {values.clients.email = v; delete serverErrors[`clients.email`]}" 
                            :valueInput="values.clients.email" 
                            :rule="[...rules.required, ...rules.email ]" 
                            label="Correo electrónico"
                            data-cy="email-input"
                            :errorMessages="serverErrors[`clients.email`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            name="p1"
                            @valueChange="(v) => {values.clients.invoice_email = v; delete serverErrors[`clients.invoice_email`]}" 
                            :valueInput="values.clients.invoice_email" 
                            :rule="[...rules.required, ...rules.email ]" 
                            label="Correo electrónico para facturas"
                            data-cy="invoice_email-input"
                            :errorMessages="serverErrors[`clients.invoice_email`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            name="p1"
                            @valueChange="(v) => {values.clients.credit_days = v; delete serverErrors[`clients.credit_days`]}" 
                            :valueInput="values.clients.credit_days" 
                            :rule="rules.required" 
                            label="Días de crédito"
                            data-cy="credit_days-input"
                            :errorMessages="serverErrors[`clients.credit_days`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Día de corte"
                            name="p1"
                            :valueInput="values.clients.cutoff_date"
                            :rule="rules.required"
                            :items="serverItems.cutDays"
                            data-cy="cutoff_date-input"
                            :errorMessages="serverErrors[`clients.cutoff_date`]"
                            @valueChange="(v) => {values.clients.cutoff_date = v; delete serverErrors[`clients.cutoff_date`]}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>



                
                
            </div>
        </v-tab-item>

        <!--STEP 2  "Documentación" -->
        <v-tab-item value="2">
            <div class="mt-3">
                <span class="error--text">{{serverErrors['client_products']}}</span>
                <v-row>
                    
                    <v-col class="py-2 text-right">
                        <primary-button
                            data-cy="add-project-btn"
                            :props="{
                            text: 'Agregar proyecto',
                            icon: 'mdi-plus',	
                            click: this.openModalProject,
                            block:false,
                            
                            
                            }"
                        ></primary-button>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">PROYECTOS</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <table-items data-cy="table-projects" :props="tableProps" @methodHandler="method_handler" :items="values.client_products">
                            
                        </table-items>
                        
                    </v-col>
                
                </v-row>
                <modal :props="modalProject" @modalResponse="method_handler">
                    <!-- FORM -->
                    <template slot="cardBody">
                        <v-form v-if="modalProject.visible" ref="form" @submit.prevent="save">
                            <project-form :parentEditMode="this.edit" :controlKey="controlKey" ref="ProyectForm" :values="pForm" @save="addProject" :serverErrors="serverErrors" :edit="editMode"></project-form>
                        </v-form>
                    </template> 
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelProjectModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptProjectModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>
            </div>
        </v-tab-item>

        <!-- STEP 3 "Contratación" -->
        <v-tab-item value="3">
            <div class="my-3">

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Tipo de embalaje"
                            :loading="loadingPackaging"
                            name="p3"
                            :valueInput="values.client_operations.type_id"
                            :rule="rules.required"
                            :items="serverItems.packaging"
                            data-cy="type_id-input"
                            :errorMessages="serverErrors[`client_operations.type_id`]"
                            @valueChange="(v) => {values.client_operations.type_id = v; delete serverErrors[`client_operations.type_id`]}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>

                <!-- <v-row>
                    <v-col cols="4">
                       
                    </v-col>
                    <v-col cols="8">
                        <textFieldForm
                            name="p3"
                            label="Longitud"
                            :valueInput="values.client_operations.length"
                            :rule="rules.required"
                            data-cy="length-input"
                            @valueChange="
                                (v) => {
                                    values.client_operations.length = v;
                                    delete serverErrors[`client_operations.length`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.length`]"
                        ></textFieldForm>
                    </v-col>
                </v-row> -->

                <!-- <v-row>
                    <v-col cols="4">
                        <span>MEDIDAS DE EMBALAJE:</span>
                    </v-col>
                    <v-col cols="8">
                        <textFieldForm
                            name="p3"
                            label="Anchura"
                            :valueInput="values.client_operations.width"
                            data-cy="width-input"
                            :rule="rules.required"
                            @valueChange="
                                (v) => {
                                    values.client_operations.width = v;
                                    delete serverErrors[`client_operations.width`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.width`]"
                        ></textFieldForm>
                    </v-col>
                </v-row> -->

                <!-- <v-row>
                    <v-col cols="4">
                        
                    </v-col>
                    <v-col cols="8">
                        <textFieldForm
                            name="p3"
                            label="Altura"
                            :valueInput="values.client_operations.height"
                            :rule="rules.required"
                            data-cy="height-input"
                            @valueChange="
                                (v) => {
                                    values.client_operations.height = v;
                                    delete serverErrors[`client_operations.height`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.height`]"
                        ></textFieldForm>
                    </v-col>
                </v-row> -->

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Tipo de transporte"
                            name="p3"
                            :valueInput="values.client_operations.transport"
                            :rule="[]"
                            data-cy="transport-input"
                            :items="serverItems.transports"
                            :errorMessages="serverErrors[`client_operations.transport`]"
                            @valueChange="(v) => {values.client_operations.transport = v; delete serverErrors[`client_operations.transport`]}"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col>
                        <textFieldForm
                            name="p3"
                            label="Descripción del manejo del producto"
                            :valueInput="values.client_operations.description"
                            :rule="[]"
                            data-cy="description-input"
                            @valueChange="
                                (v) => {
                                    values.client_operations.description = v;
                                    delete serverErrors[`client_operations.description`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.description`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textFieldForm
                            name="p3"
                            label="Condiciones de almacenaje"
                            :valueInput="values.client_operations.storage_conditions"
                            :rule="rules.required"
                            data-cy="storage_conditions-input"
                            @valueChange="
                                (v) => {
                                    values.client_operations.storage_conditions = v;
                                    delete serverErrors[`client_operations.storage_conditions`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.storage_conditions`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                 <v-row>
                    <v-col>
                        <textFieldForm
                            name="p3"
                            label="Valor de la mercancia a ingresar"
                            :valueInput="values.client_operations.product_value"
                            :rule="rules.product_value"
                            data-cy="product_value-input"
                            @valueChange="
                                (v) => {
                                    values.client_operations.product_value = v;
                                    delete serverErrors[`client_operations.product_value`];
                                }
                            "
                            :errorMessages="serverErrors[`client_operations.product_value`]"
                        ></textFieldForm>
                    </v-col>
                </v-row>


                <v-row justify="center">
                    <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Comercio exterior</p>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="OPERACIÓN DE COMERCIO EXTERIOR"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`foreign trade`]"
                            data-cy="foreign_trade-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`foreign trade`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="IMPORTACIÓN"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`import`]"
                            data-cy="import-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`import`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="EXPORTACIÓN"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`export`]"
                            data-cy="export-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`export`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="CATÁLOGO DE FRACCIÓN ARANCELARIA"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`catalog`]"
                            data-cy="catalog-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`catalog`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="AGENTE ADUANAL"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`customs broker`]"
                            data-cy="customs_broker-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`customs broker`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="INSPECTOR DE CALIDAD"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`customs inspector`]"
                            data-cy="customs_inspector-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`customs inspector`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="PRADRÓN DE IMPORTADORES"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`list of importers`]"
                            data-cy="list_of_importers-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`list of importers`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="MATERIAL PELIGROSO"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`dangerous material`]"
                            data-cy="dangerous_material-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`dangerous material`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>

                <!-- <v-row v-if="values.client_operations.foreign_commerce[`dangerous material`] == 1">
                    <v-col class="py-2">
                        <fileInputForm
                            label="Archivo"
                            :multiple="false"
                            :valueInput="values.client_operations.foreign_commerce[`material catalog`]"
                            :url="values.invoice_path"
                            :rule="[]"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`material catalog`] = v; delete serverErrors[`material catalog`]}"
                            :errorMessages="serverErrors[`material catalog`]"
                            v-on:update:url="values.invoice_path = $event"
                        ></fileInputForm>
                    </v-col>
                </v-row> -->

                <v-row>
                    <v-col class="py-2">
                        <switchForm
                            title="MERCANCÍA SENSIBLE"
                            leftLabel="No"
                            rightLabel="Si"
                            :valueInput="values.client_operations.foreign_commerce[`sensitive merchandise`]"
                            data-cy="sensitive_merchandise-input"
                            @valueChange="(v) => {values.client_operations.foreign_commerce[`sensitive merchandise`] = v}"
                        ></switchForm>
                    </v-col>
                </v-row>




            </div>
        </v-tab-item>
    </v-tabs-items>
    </div>
</template>
<script>
import ProjectForm from "./FormProject.vue";
import { v4 as uuidv4 } from 'uuid';
/* eslint-disable */
export default {
    props: ["values", "stepIn", "stepClasses", "serverErrors", "edit"],
    components: {
        'project-form': ProjectForm,
    },
    model: {
        event: "tabChanged",
    },
    watch: {
        ["values.company"]: function (v) {
            console.log(v, 999);
            this.getPositions(v);
            this.getLocations(v);
        },
        ["values.repeat_schedule"]: function (v) {
            if (v) {
                this.checkRepeat();
            }
        },
        ["values.has_childs"]: function (v) {
            if (!v) {
                this.values.children = [];
            }
        },
        ["modalProject.visible"]: function (v){
            this.selectedID = undefined;
            console.log(v)
            if(!v)
                this.editMode = false;
            if(this.editMode)
                return;
            this.resetForm();
        }
    },
    computed: {
        tab: {
            get: function () {
                return this.stepIn;
            },
            set: function (value) {
                //console.log(value)
                this.setRules(value);
                this.$emit("tabChanged", value);
                //this.tab = stepIn;
            },
        },

        stClasses: {
            get: function () {
                console.log(this.stepClasses, "form step classes")
                return this.stepClasses;
            },
            set: function (value) {
                //console.log(value)
                //  this.$emit('tabChanged', value)
                //this.tab = stepIn;
            },
        },

       
    },
    data() {
        return {
            e1: 1,
            editMode:false,
            controlKey: 50,
            show4: false,
            loadingBanks:false,
            loadingPackaging:false,
            rules: {
                required: [
					v => !!v || 'El campo requerido'
				],
                email: [
                    (v) => {
                        if(v == null || v == "")
                            return true;

                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
                product_value: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        // if(v === null || v.toString().trim() === '')
                        //     return 'El campo es requerido'
                        return true;
                    }
                ],
                onlyNumbers: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
            },
            //!part 1
            rulesOne: {
                required: [
					v => !!v || 'El campo requerido'
				],
            },
            //!part 2
            rulesTwo: {
               
            },
            //!part 3
            rulesThree: {
                
            },
            
            pForm:{
                sku: "",
                project_name: "prOyeCto NomBre",
                general_description: "desCripciOn GenEral",
                business: "Comercial",
                product_type: "Terminado",
                product_rotation: "mEnSuaL",
                prospect: "",
                prospect_id: "",
                fee_id: '',
                folio: '',
                warehouse_eon_id: '',
                client_eon_id: '',
                services: [
                    // {
                        // "service_id: "envio-de-embarque-envio-de-embarque",
                        // "price: 12.00
                    // },
                   
                ],

                price_list:""
            },
            modalProject: {
                visible: false,
				width: '700',
				title: 'Proyecto',
				text: 'Proyecto',
				loading: false,
				bottomLineColor: '',
            },
            cancelProjectModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closeProjectModal()}
            },
			acceptProjectModal: {
                text: "Aceptar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.addProject()}
            },

            actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openEditModalProject',
                    dataCy: "edit-project-link"
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-project-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Nombre',
						align: 'left break-words',
						value: 'project_name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Descripción',
						align: 'center',
						value: 'general_description',
						sortable: false,
                        width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Giro',
						align: 'center',
						value: 'business',
						sortable: false,
                        width: "15%",
						class: 'table-b-border black--text'
					},

                    {
						text: 'Producto',
						align: 'center',
						value: 'product_type',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '12%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            serverItems: {
                banks:[],
                pricesList:[
                    {value:"Regular", text:"Regular"},
                    {value:"Premium", text:"Premium"},
                    {value:"Socio", text:"Socio"},
                ],
                cfdiList:[
                    {text:"G01", value:"G01"},
                    {text:"G02", value:"G02"},
                    {text:"G03", value:"G03"},
                    {text:"I01", value:"I01"},
                    {text:"I02", value:"I02"},
                    {text:"I03", value:"I03"},
                    {text:"I04", value:"I04"},
                    {text:"I05", value:"I05"},
                    {text:"I06", value:"I06"},
                    {text:"I07", value:"I07"},
                    {text:"I08", value:"I08"},
                    {text:"D01", value:"D01"},
                    {text:"D02", value:"D02"},
                    {text:"D03", value:"D03"},
                    {text:"D04", value:"D04"},
                    {text:"D05", value:"D05"},
                    {text:"D06", value:"D06"},
                    {text:"D07", value:"D07"},
                    {text:"D08", value:"D08"},
                    {text:"D09", value:"D09"},
                    {text:"D10", value:"D10"},
                    {text:"P01", value:"P01"},
                ],
                cutDays:[
                    {text:"1", value:1},
                    {text:"2", value:2},
                    {text:"3", value:3},
                    {text:"4", value:4},
                    {text:"5", value:5},
                    {text:"6", value:6},
                    {text:"7", value:7},
                    {text:"8", value:8},
                    {text:"9", value:9},
                    {text:"11", value:11},
                    {text:"10", value:10},
                    {text:"12", value:12},
                    {text:"13", value:13},
                    {text:"14", value:14},
                    {text:"15", value:15},
                    {text:"16", value:16},
                    {text:"17", value:17},
                    {text:"18", value:18},
                    {text:"19", value:19},
                    {text:"20", value:20},
                    {text:"21", value:21},
                    {text:"22", value:22},
                    {text:"23", value:23},
                    {text:"24", value:24},
                    {text:"25", value:25},
                    {text:"26", value:26},
                    {text:"27", value:27},
                    {text:"28", value:28},
                    {text:"29", value:29},
                    {text:"30", value:30},
                    {text:"31", value:31},
                ],
                packaging: [],
                transports:[
                    { text:"aéreo", value:"aéreo"},
                    { text:"marítimo", value:"marítimo"},
                    { text:"terrestre", value:"terrestre"},
                ]
            },
           
        };
    },
    mounted() {
        
        this.setRules(1);
        this.getBanks();
        this.getPackaging();

        if(this.edit)
        {
            this.loadClientProducts();
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},

        loadClientProducts()
        {
            this.values.client_products = this.values.client_products.map(item => {
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: uuidv4(), name: item.project_name, index: 99},
                    ...item
                }
            })

            
        },

        openModalProject()
        {
            delete this.serverErrors.client_products;
            this.modalProject = {
                ...this.modalProject,
				text: ``,
				scrollable:true,
                
			}
           
            delete this.pForm.parameters;
           
            this.pForm.sku = "";

           

            console.log(this.selectedID, this.pForm)
            this.pForm.price_list = this.values.price_list??"Regular";
            this.modalProject.visible = true;
            // this.resetForm();
        },
        openEditModalProject(parameters)
        {
            this.modalProject = {
                ...this.modalProject,
				text: ``,
				scrollable:true,
			}
           
            this.selectedID = parameters.id;
            this.pForm.sku = "";

            if(this.selectedID != undefined){
                this.editMode = true;
                let found = this.values.client_products.find(item => item.parameters.id == this.selectedID)
                console.log(found, "foounddd")
                this.pForm = {...JSON.parse(JSON.stringify(found))}

            }

            console.log(this.selectedID, this.pForm)
            this.pForm.price_list = this.values.price_list??"Regular";
            this.modalProject.visible = true;
            // this.resetForm();
        },
        closeProjectModal()
        {
            this.resetForm();
            this.modalProject.visible = false;
        },
        
        resetForm()
        {
            this.pForm.project_name = this.pForm.general_description = this.pForm.product_rotation = "";
            this.pForm.services = [];
            try {
                this.$refs.form.reset();
                
            } catch (error) {
                //sonothing
            }
        },

      
        validate () {
          
            this.$refs.form.inputs.forEach(input => {
                input.validate()
            });
			if(this.$refs.form.validate())
                return true;
            
            return false;
		},

        addProject()
        {
            if(!this.validate())
                return;

            if(this.editMode){
                let found = this.values.client_products.find(item => item.parameters.id == this.pForm.parameters.id)
                if(found != undefined){
                    found.project_name = this.pForm.project_name;
                    found.general_description = this.pForm.general_description;
                    found.business = this.pForm.business;
                    found.product_type = this.pForm.product_type;
                    found.product_rotation = this.pForm.product_rotation;
                    found.client_eon_id = this.pForm.client_eon_id;
                    found.warehouse_eon_id = this.pForm.warehouse_eon_id;
                    found.services = JSON.parse(JSON.stringify(this.pForm.services));
                    found.actions = JSON.parse(JSON.stringify(this.actions));
                    found.parameters =  { id: new Date().getTime(), name: this.pForm.project_name, index: 99};
                
                    console.log(found, this.pForm)
                    // found = {
                    //     actions: JSON.parse(JSON.stringify(this.actions)),
                    //     parameters: { id: uuidv4(), name: this.pForm.project_name, index: 99},
                    //     ...JSON.parse(JSON.stringify(this.pForm))
                    // }
                    this.modalProject.visible = false;
                    return;
                }
                
               
            }
                
            let newProj = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: new Date().getTime(), name: this.pForm.project_name, index: 99},
                sku: "",
                ...JSON.parse(JSON.stringify(this.pForm))
            }
            this.values.client_products.push(newProj);
            this.modalProject.visible = false;
            console.log(this.values.client_products)
        },

        deleteItem(parameters)
        {
            let index = this.values.client_products.findIndex(prod => prod.parameters.id == parameters.id);

            console.log( index);
            this.values.client_products.splice(index, 1);
            // this.$refs.serviSelect.$children[0].validate()
        },

        /**
         * Set form rules by step
         */
        setRules(step) {
            console.log(step);
            // this.rules = {};
            // if (step == 1) {
            //     this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     };
            //     return;
            // } else if (step == 2) {
            //     this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     };
            //     return;
            // }

            // this.rules = {
            //     ...this.rulesOne,
            //     ...this.rulesTwo,
            //     ...this.rulesThree,
            // };
        },


        getBanks()
        {
            this.loadingBanks = true;
            this.$api.bank.getSelect()
                .then((resp) => {
                    this.serverItems.banks = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBanks = false;
                })
            ;
        },

        getPackaging()
        {
            this.loadingPackaging = true;
            this.$api.packaging.getSelect()
                .then((resp) => {
                    this.serverItems.packaging = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPackaging = false;
                })
            ;
        },

     
      
    },
};
</script>

<style lang="scss">
.tab {
  &-header {
    font-size: 12px !important;
    color: var(--v-normalText-lighten4) !important;
  }

  &-active {
    color: var(--v-normalText-base) !important;
  }

  &-error {
    color: var(--v-warning-base) !important;
  }
}
// .less-margin {
//   margin-bottom: -20px;
// }
</style>