<template>
    <div>
        <v-row justify="center">
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>
       

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proveedor"
                    :loading="loadingSuppliers"
                    :valueInput="values.supplier_id"
                    :rule="rules.required"
                    :items="serverItems.suppliers"
                    :errorMessages="serverErrors.supplier_id"
                    @valueChange="(v) => {values.supplier_id = v; this.clear(); this.getProducts(v); delete serverErrors.supplier_id}"
                    data-cy="supplier_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Agregar equipo/consumible</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="9" sm="10"  xl="11">
                <autocompleteOneForm
                    label="Equipo/Consumible"
                    :loading="loadingProducts"
                    :valueInput="productSelected"
                    :rule="[]"
                    :items="serverItems.products"
                    :returnObject="true"
                    data-cy="productSelected-input"
                    @valueChange="(v) => {productSelected = v;}"
                ></autocompleteOneForm>
            </v-col>
            <v-col class="py-2">
                <iconButton
                    data-cy="productSelected-input-btn"
                    :props="{
                    color:'primary',
                    icon:'mdi-plus',
                    height:'41',
                    
                    click: () => {addProduct();delete serverErrors.products}
                    }"
                ></iconButton>
            </v-col>
        </v-row>

        
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.products">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.products}" 
                                :valueInput="props.item.quantity" 
                                :rule="[...rules.quantity]" 
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                    </template>
                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`products`]">{{serverErrors[`products`]}}</span>
      

        
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
            loadingSuppliers:false,
            loadingProducts:false,
            productSelected: {},
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'EQUIPO/CONSUMIBLE',
						align: 'left break-words',
						value: 'name',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "18%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
               
                suppliers: [],
                products: [],

            }
            

          
        }
    },
   
    mounted(){
        this.getSuppliers();

        if(this.edit){
            this.getProducts(this.values.supplier_id)
            this.loadProducts();
        }
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        loadProducts()
        {
            this.values.products = this.values.products.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                return {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.product_id, name: item.product, index: i},
                    product_id: item.product_id,
                    name: item.product,
                    quantity : item.quantity
                }
            })
        },
        getSuppliers()
        {
            this.loadingSupplier = true;
            this.$api.supplier.getSelect()
                .then((resp) => {
                    this.serverItems.suppliers = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSupplier = false;
                })
            ;
        },

        addProduct()
        {
            if(!("value" in this.productSelected))
                return;

            let found = this.values.products.find(fItem => fItem.product_id == this.productSelected.value)
            if(found != undefined)
                return;

            let item = this.productSelected;
            let newProd = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: item.text, index: 99},
                product_id: item.value,
                name: item.text,
                quantity : 1
            }

            this.values.products.push(newProd);

            
        },

        clear()
        {
            this.serverItems.products = [];
            this.values.products = [];
            this.productSelected = {};
        },
        

        getProducts(supplier)
        {
           
            if(supplier == "" || supplier == null || supplier == undefined)
                return;

            this.loadingProducts = true;
            this.$api.supplier.getProducts(supplier)
                .then((resp) => {
                    this.serverItems.products = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProducts = false;
                })
            ;
        },

        deleteItem(item)
        {
            let index = this.values.products.findIndex(prod => prod.product_id == item.id);

            console.log(index, item);
            this.values.products.splice(index, 1);
        }
       
    }
}
</script>

<style>

</style>