<template>
    <div class="my-5">
    
    <v-expand-transition appear>
          
           
           
            <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.conditions = v; delete serverErrors.conditions}" 
                            :valueInput="values.conditions" 
                            :rule="[]" 
                            label="Comentarios"
                            :errorMessages="serverErrors.conditions"
                            data-cy="conditions-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
            
          
         
    </v-expand-transition>
        

                
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
          
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
              
				
            },

            
            

          
        }
    },
   
    mounted(){
       console.log(this.values)
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>