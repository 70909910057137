<template>
    <div>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :disabled="edit ? true:false"
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.client_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.client_id"
                    @valueChange="(v) => {this.clear(); values.client_id = v; this.getProjects(v); this.getCutoffDates(v); delete serverErrors.client_id}"
                    data-cy="client_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proyecto"
                    :loading="loadingProjects"
                    :valueInput="values.project_id"
                    :rule="rules.required"
                    :items="serverItems.projects"
                    :errorMessages="serverErrors.project_id"
                    @valueChange="(v) => { values.project_id = v; getWarehouseSelected(); delete serverErrors.project_id}"
                    data-cy="project_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    :disabled="true"
                    label="Almacén"
                    :loading="loadingWarehouses"
                    :valueInput="values.warehouse_id"
                    :rule="rules.required"
                    :items="serverItems.warehouses"
                    :errorMessages="serverErrors.warehouse_id"
                    @valueChange="(v) => {values.warehouse_id = v; getSaleOrders(); delete serverErrors.warehouse_id}"
                    data-cy="warehouse_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>



        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.comments = v; delete serverErrors.comments}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Comentarios"
                    :errorMessages="serverErrors.comments"
                    data-cy="comments-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row >
           
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha inicial"
                    :valueInput="values.initial_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.initial_date = v; getSaleOrders(); delete serverErrors['initial_date']}"
                    :errorMessages="serverErrors['initial_date']"
                    :minIn="startDateLimit"
                    :maxIn="endDateLimit"
                    data-cy="initial_date-input"
                ></datePickerForm>
            </v-col>

            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha final"
                    :valueInput="values.ending_date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.ending_date = v; getSaleOrders(); delete serverErrors['ending_date']}"
                    :errorMessages="serverErrors['ending_date']"
                    :minIn="startDateLimit"
                    :maxIn="endDateLimit"
                    data-cy="ending_date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="font-weight-bold body-1 mb-0" >
                <p class="pt-2">Remisiones</p>
            </v-col>

            <v-col class="" >
                <div class="bill-mark-all-cont">
                    <span class="mr-2">Marcar todas</span>
                    <checkboxForm
                        label=""
                        :valueInput="checkedAll"
                        
                        @valueChange="(v) => {checkedAll = v; this.checkAll(); delete serverErrors[`add_invoices`];}"
                        :errorMessages="serverErrors[`add_invoices`]"
                        data-cy="sale-item-all-input"
                    ></checkboxForm>
                </div>
            </v-col>
        </v-row>

        <v-col >
            <div class="billing-sale-container mb-3"  v-for="(item,i) in values.sale_orders" :key="i">
                <checkboxForm
                    label=""
                    :valueInput="item.check"
                    
                    @valueChange="(v) => {item.check = v; }"
                    
                    class="mr-2"
                    data-cy="sale-item-input"
                ></checkboxForm>

                <div class="billing-sale-expansion-container">
                    <template>
                        <v-expansion-panels flat>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    {{item.id}} {{`(${item.date})`}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <datatable data-cy="index-table" :props="item.dataTable" @methodHandler="method_handler" >
                                        
                                
                                    </datatable>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </template>
                </div>

            </div>
        </v-col>
        




        
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            keyTable: 0,
            checkedAll: 0,
            loadingWarehouses: false,
            loadingClients: false,
            loadingProjects: false,
            loadingSales: false,
            serviceSelected: {},

            startDateLimit: "",
            endDateLimit: "",
            
            tableProps: {
				headers: [
                     {
						text: 'Servicio',
						align: 'left break-words',
						value: 'service',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Ref.',
						align: 'center',
						value: 'reference',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cant.',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    
                   
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter: true
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
               
                warehouses: [],
                clients: [],
                projects: [],

                

            }
            

          
        }
    },
   
    mounted(){
        this.getWarehouses();
        this.getClients();
        this.salesToTable();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.client_id = "";
            this.values.project_id = "";
            this.values.warehouse_id = "";
            this.values.sale_orders = [];
        },

        //Set sale_orders to tableProps items
        salesToTable(){
            this.values.sale_orders.forEach(saleOrder => {
                saleOrder.dataTable = JSON.parse(JSON.stringify(this.tableProps));


                saleOrder.dataTable.items = saleOrder.services.map((x,i) => {
                    

                    return x;
                });
            });
        },

        checkAll()
        {
            for (let i = 0; i < this.values.sale_orders.length; i++) {
                this.values.sale_orders[i].check = this.checkedAll;
            }

            this.keyTable = this.keyTable + 1;

            console.log(this.values)
        },

        
        getWarehouses()
        {
            // this.loadingWarehouses = true;
            // this.$api.warehouse.getSelect()
            //     .then((resp) => {
            //         this.serverItems.warehouses = resp.data;
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     })
            //     .finally(() => {
            //         this.loadingWarehouses = false;
            //     })
            // ;
            this.serverItems.warehouses = this.$store.state.userData.warehousesRaw;
        },
       
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelect(this.$store.state.warehouse)
                .then((resp) => {
                    //hacer map
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        getProjects(client_id)
        {
            if(client_id == null || client_id == undefined || client_id == "")
                return;

            this.loadingProjects = true;
            this.$api.client.getProjectsSelect(client_id, this.$store.state.warehouse)
                .then((resp) => {
                    //hacer map
                    this.serverItems.projects = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProjects = false;
                })
            ;


        },

        getCutoffDates(client_id)
        {
            if(client_id == null || client_id == undefined || client_id == "")
                return;
            this.values.initial_date = "";
            this.values.ending_date = "";

            this.loadingProjects = true;
            this.$api.billing.getCutoff(client_id)
                .then((resp) => {
                    //hacer map
                    console.log(resp.data, "dates");
                    this.startDateLimit = resp.data.initial_cutoff_date
                    this.endDateLimit = resp.data.final_cuttoff_date;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProjects = false;
                })
            ;
        },

        getWarehouseSelected()
        {
            if(this.values.project_id == null || this.values.project_id == undefined || this.values.project_id == "")
                return;

            this.loadingProjects = true;
            this.$api.warehouse.getByProject(this.values.project_id)
                .then((resp) => {
                    this.values.warehouse_id = resp.data.value;
                    this.getSaleOrders();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProjects = false;
                })
            ;
        },

        getSaleOrders()
        {
            if(this.values.warehouse_id == "")
                return;
            if(this.values.project_id == "")
                return;
            if(this.values.initial_date == "")
                return;
            if(this.values.ending_date == "")
                return;

            this.loadingSales = true;
            this.values.sale_orders = [];
            this.$api.saleOrder.getBillings(this.values.warehouse_id, this.values.project_id, this.values.initial_date, this.values.ending_date)
                .then((resp) => {
                    resp.data.forEach(item => {
                        this.values.sale_orders.push(
                            {
                                id: item.sale_order_id,
                                check: 0,
                                date:item.date,
                                amount: item.amount,
                                services: item.services,
                                dataTable: {}
                            }
                        );
                    });

                    this.salesToTable();
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSales = false;
                })
            ;
        },

      
    }
}
</script>

<style lang="scss">

    .bill-mark-all-cont{
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
    }

    .billing-sale-container{
        display: flex;
       
    }

    .billing-sale-expansion-container{
        width: -webkit-fill-available;
        border: 2px $lineColor-1 solid;
    }
</style>