<template>
    <div>
        <!-- <v-card class="mb-4 mt-0" elevation="0"> -->
            <!-- <v-card-text> -->
               

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                            :valueInput="values.name" 
                            :rule="[...rules.required]" 
                            label="Nombre"
                            :errorMessages="serverErrors.name"
                            data-cy="name-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                            :valueInput="values.email" 
                            :rule="[...rules.required, ...rules.email]" 
                            label="Usuario"
                            :errorMessages="serverErrors.email"
                            data-cy="email-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.contact_email = v; delete serverErrors.contact_email}" 
                            :valueInput="values.contact_email" 
                            :rule="[...rules.email]" 
                            label="Correo de contacto"
                            :errorMessages="serverErrors.contact_email"
                            data-cy="contact_email-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.job_position = v; delete serverErrors.job_position}" 
                            :valueInput="values.job_position" 
                            :rule="[]" 
                            label="Puesto"
                            :errorMessages="serverErrors.job_position"
                            data-cy="job_position-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {values.phone = v; delete serverErrors[`phone`]}" 
                            :valueInput="values.phone" 
                            :rule="[...rules.onlyNumbers,...rules.limit10]" 
                            label="Teléfono"
                            :errorMessages="serverErrors[`phone`]"
                            data-cy="phone-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-2">
                        <autocompleteOneForm
                            label="Rol"
                            :loading="loadingRoles"
                            :valueInput="values.role"
                            :rule="rules.required"
                            :items="serverItems.roles"
                            :errorMessages="serverErrors.role"
                            @valueChange="(v) => {values.role = v; delete serverErrors.role}"
                            data-cy="roles-input"
                        ></autocompleteOneForm>
                    </v-col>
                </v-row>



                <v-row>
                    <v-col>
                        <autocompleteMultipleForm
                        
                        label="Clientes"
                        :valueInput="values.client_users"
                        :rule="[]"
                        :items="serverItems.clients"
                        :loading="loadingClients"
                        @valueChange="(v) => {values.client_users = v; delete serverErrors.client_users}"
                        :errorMessages="serverErrors.client_users"
                        :selectAll="true"
                        data-cy="client_users-input"
                        ></autocompleteMultipleForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <autocompleteMultipleForm
                        
                        label="almacenes"
                        :valueInput="values.warehouses"
                        :rule="[]"
                        :items="serverItems.warehouses"
                        :loading="loadingWarehouses"
                        @valueChange="(v) => {values.warehouses = v; delete serverErrors.warehouses}"
                        :errorMessages="serverErrors.warehouses"
                        :selectAll="true"
                        data-cy="warehouses-input"
                        ></autocompleteMultipleForm>
                    </v-col>
                </v-row>


                

                <v-row v-if="!edit">
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.password = v; delete serverErrors.password}" 
                            :valueInput="values.password" 
                            :rule="[...rules.required, ...rules.length8]" 
                            label="Contraseña"
                            :errorMessages="serverErrors.password"
                            :count="true"
                            :prependInnerIcon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass ? 'text' : 'password'"
                            @clickAppend="() => {showPass = !showPass}"
                            data-cy="password-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row v-if="!edit">
                    <v-col class="py-2">
                       <textFieldForm 
                            @valueChange="(v) => {values.password_confirmation = v; delete serverErrors.password_confirmation}" 
                            :valueInput="values.password_confirmation" 
                            :rule="[...rules.required, ...rules.samePassword, ...rules.length8]" 
                            label="Confirmar contraseña"
                            :errorMessages="serverErrors.password_confirmation"
                            :count="true"
                            :type="showPass ? 'text' : 'password'"
                            @click:append="showPass = !showPass"
                            data-cy="password_confirmation-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

             
                
                
                
            <!-- </v-card-text> -->
        <!-- </v-card> -->

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showPass: false,
            loadingClients: false,
            loadingRoles: false,
            loadingWarehouses: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                email: [
                    (v) => {
                        if(v == "")
                            return true;
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
                clientsLength: [
					v => v.length > 0 || 'El campo requerido'
				],

                samePassword:[
                    (v) => v == this.values.password || "Las contraseñas no coinciden",
                ],
                length8:[
                    v => !!v || 'El campo requerido',
                    v => (!!v && v.length >= 8) || 'Al menos de 8 caracteres'
                ],

                onlyNumbers: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],

                limit10:[
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(''+v.length > 10)
                            return 'Máximo 10 caracteres';
                        
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
                clients: [],
                roles: [],
                warehouses: [],
            }
            

          
        }
    },

    watch: {
        ["values.client_users"]: function (v) {
            
            if(v.length == this.serverItems.clients.length){
                this.values.type_client_users = 'all';
               
            }else{
                this.values.type_client_users = 'single';
               
            }
            
        },

        ["values.warehouses"]: function (v) {
            
            if(v.length == this.serverItems.warehouses.length){
                this.values.type_warehouses = 'all';
               
            }else{
                this.values.type_warehouses = 'single';
               
            }
            
        },
        
    },
   
    mounted(){
        this.getClients();
        this.getRoles();
        this.getWarehouses();
    },
    methods: {
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelectAll()
                .then((resp) => {
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        getRoles()
        {
            this.loadingRoles = true;
            this.$api.rol.getSelect()
                .then((resp) => {
                    this.serverItems.roles = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingRoles = false;
                })
            ;
        },

        getWarehouses()
        {
            this.loadingWarehouses = true;
            this.$api.warehouse.getSelect()
                .then((resp) => {
                    this.serverItems.warehouses = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingWarehouses = false;
                })
            ;
        },
       
    }
}
</script>

<style>

</style>