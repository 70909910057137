<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-5">
                        <div class="font-weight-bold body-1">
                            Detalles de recordatorio
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" >

                    <details-item :detailsItems="items"></details-item>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../../components/DetailsItem.vue';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            reminder_id: this.$route.params.reminder_id,
            loading: false,
           
            items: [
               
                
            ],
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Reminders",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.prospect.reminderGet(this.reminder_id)
			.then((response) => {
                console.log(response);
                


               
                this.items= [
                    { concepto: 'Mensaje', index: 'message', valor: response.data[0].message },
                    { concepto: 'Comentario', index: 'comment', valor:response.data[0].comment??""},
                    { concepto: 'Fecha', index: 'date', valor: response.data[0].date },
                   
                ];

                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 