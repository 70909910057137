<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6">
                        <div class="font-weight-bold body-1">
                            Editar servicio
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-form ref="form" @submit.prevent="save">
                            <service-form ref="ServiceForm" :values="form" @save="save" :serverErrors="serverErrors"></service-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import ServiceForm from "./Form.vue";
export default {
    components: {
        'service-form': ServiceForm,
    },
	data(){
		return {
            loading: false,
            serverErrors: {},
            id: this.$route.params.id,
            form: {
                key: "",
                name: "",
                unit_id: "",
                base_price: "",
                supplies: []
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Services",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {

        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
        index(){
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.service.get(this.id)
                .then((resp) => {
                    // let permi = [];
                    this.form = {
                        ...this.form, 
                        ...resp.data.data,
                        base_price: ""+resp.data.data.base_price,
                        supplies: resp.data.supplies
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.service.update(this.id, form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                        this.$router.push({name: 'Services'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            for (let i = 0; i < data.supplies.length; i++) {
                delete data.supplies[i].actions;
                delete data.supplies[i].parameters;
                delete data.supplies[i].name;
                
            }
            return data;
        }
    },
}
</script>

<style>

</style>