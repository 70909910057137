<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                        data-cy="search-input"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'purchaseOrders.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" :key="keyControl">
                        
                
                    </datatable>
                </v-col>
            </v-row>
            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            :props="cancelModal"
                            data-cy="cancel-modal-btn"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            :props="acceptModal"
                            data-cy="accept-modal-btn"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>

            <modal data-cy="index-payment-modal" :props="modalPaymentProps" @modalResponse="method_handler">
                <!-- FORM -->
                <template slot="cardBody">
                    <v-form ref="form" @submit.prevent="save">
                        <payment-form ref="PaymentForm" :values="form" @save="payment" :serverErrors="serverErrors"></payment-form>
                    </v-form>
                </template> 
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-payment-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            :props="cancelPaymentModal"
                            data-cy="cancel-payment-modal-btn"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            :props="acceptPaymentModal"
                            data-cy="accept-payment-modal-btn"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>
            
        </template>
    </contentCardIndex>
        
</template>
<script>
import Utils from "../../../helpers/Utils";
import PaymentForm from "./PaymentForm.vue";
export default {
    components: {
        'payment-form': PaymentForm,
    },
    data(){
		return {
           
            keyControl: 0,
			search: '',
            selectedID: '',

            loading: false,
            serverErrors: {},

            form: {
                date: "",
                amount: ""
                    
            },
           
			actions: [
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect mdiCash
					action: '',
                    permissions: 'purchaseOrders.show',
                    dataCy: "details-link"
				},
               
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'purchaseOrders.edit',
                    dataCy: "edit-link"
				},

                {
					name: 'Registrar pago',
					icon: {
						color: 'secondary',
						icon: 'mdi-cash'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect mdi-file-document-outline
					action: 'paymentItemModal',
                    permissions: 'purchaseOrders.store',
                    dataCy: "pay-link"
				},

                {
					name: 'PDF',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect m
					action: 'getPdf',
                    loading:false,
                    permissions: 'purchaseOrders.show',
                    dataCy: "pdf-link"
				},
               
               
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'purchaseOrders.delete',
                    dataCy: "delete-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'sku',
                        width: "10%",
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						width: "10%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proveedor',
						align: 'left',
						value: 'supplier',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Importe',
						align: 'left',
						value: 'total_details_final',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Estatus',
						align: 'left',
						value: 'status',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Pago',
						align: 'left',
						value: 'payment',
						
						class: 'table-b-border black--text'
					},
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy:"sku",
                sortDesc: true,
			},
			
			
            createBtnData: {
                text: "Registrar OC",
                icon: "mdi-plus",
				to: "PurchaseOrdersCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},

            modalPaymentProps: {
                visible: false,
				width: '500',
				title: 'Generar pago',
				text: 'Generar pago',
				loading: false,
				bottomLineColor: 'primary',
            },
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false; this.modalPaymentProps.visible = false}
            },

            cancelPaymentModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.closePaymentModal()}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },

            acceptPaymentModal: {
                text: "ACTUALIZAR",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'black',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.payment()}
            },
		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;
           
			// call api
			this.$api.purchaseOrder.list()
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

                        if(x.invoice_pdf_path != null && x.invoice_pdf_path != ''){
                            x.actions[0].action = x.invoice_pdf_path;
                            x.actions[0].disabled = false;
                        }
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = { name: 'PurchaseOrdersDetails', params: { id: x.sku } }
                        x.actions[1].action = { name: 'PurchaseOrdersEdit', params: { id: x.sku } }
                        x.total_details = x.total_details.replace(",","")
                        x.total_payment = x.total_payment.replace(",","")
                        //payment: ele.total_details - ele.total_payment !== 0 ? 'Sin pagar' : 'Pagada'
						x['parameters'] = { id: x.sku, name: x.sku, index: i};
                        x.payment = (x.total_details - x.total_payment) !== 0 ? "Sin pagar": "Pagada";
                        
                        x.total_details_final = Utils.currencyFormat(x.total_details??0, "");

                        if(x.payment == "Pagada"){
                            // x.actions[2].action = x.invoice_pdf_path;
                            x.actions[2].disabled = true;
                        }
                        
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getPdf(parameters)
		{
            this.selectedID = parameters.id;
            this.tableProps.items[parameters.index].actions[3].loading = true
			//console.log(this.selectedID);
			this.$api.purchaseOrder.pdf(this.selectedID)
                // eslint-disable-next-line
                .then((response) => {
                    console.log(response);
                    let file = new Blob([response.data], {type: 'application/pdf'});
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    // this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
                    // this.index()
                    // eslint-disable-next-line
                }).catch(error => {
                    if(error.response.status === 422){
                        this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al obtener archivo'}`);
                    }else{
                        this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
                    }

                    
                })
                .finally(() => {
                    this.tableProps.items[parameters.index].actions[3].loading = false;
                })
            ;
		},

        resetForm()
        {
            this.form.date = this.form.amount = "";

            try {
                this.$refs.form.reset();
                
            } catch (error) {
                //sonothing
            }
        },

        paymentItemModal(parameters)
        {
            this.modalPaymentProps = {
                ...this.modalPaymentProps,
				text: `GENERAR PAGO - "${parameters.name}"`,
				
			}
            this.modalPaymentProps.visible = true;
            this.selectedID = parameters.id;
            console.log(this.selectedID)
            this.resetForm();
        },

		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},

        closePaymentModal()
        {
            this.resetForm();
            this.modalPaymentProps.visible = false;
        },


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.purchaseOrder.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        payment(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                
                console.log(this.form);
                this.acceptPaymentModal.loading = true;
                // this.$store.state.loading = true;
                this.$api.purchaseOrder.storePayment(this.selectedID, this.form)
                    .then((resp) => {
                        //console.log(response)

                        if("message" in resp.data)
                        {
                            if(resp.data.message == "El pago es superior al costo de la orden de compra"){
                                this.$store.dispatch('snackbarError', resp.data.message);
                                return;
                            }
                        }

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.modalPaymentProps.visible = false;
                        this.index();
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        
                        this.acceptPaymentModal.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },

    }
}
</script>