<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-8">
                        <div class="font-weight-bold body-1">
                            Detalles del cliente
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="8" >

                    

                    <v-tabs
                        v-model="tab"
                        centered
                        grow
                        active-class="font-weight-bold tab-active"
                        slider-size="3"
                    >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#1" :class="`tab-header`">
                            Datos Generales
                        </v-tab>

                        <v-tab href="#2" :class="`tab-header`">
                            Detalles de producto
                        </v-tab>

                        <v-tab href="#3" :class="`tab-header`">
                            Operación del cliente
                        </v-tab>
                    </v-tabs>

                    <!-- STEP 1 "Datos generales" -->
                    <v-tabs-items v-model="tab" class="mt-5" data-cy="data-details-items">
                        <v-tab-item value="1" class="mb-10">
                           <details-item :key="keyCtr" :detailsItems="generalItems"></details-item>
                        </v-tab-item>

                        <!--STEP 2  "Documentación" -->
                        <v-tab-item value="2">
                            
                            <div class="mb-10" v-for="(item, index) in productItems" :key="index">
                                <v-row justify="center">
                                    <v-col class="pt-3 pb-5 font-weight-bold body-2 mb-0 text-center" >
                                        <p class="mb-0 ">{{item.name}}</p>
                                    </v-col>
                                </v-row>
                                <details-item :detailsItems="item.product"></details-item>
                                <!-- table item.services -->
                                <v-row>
                                    <v-col class="py-2">
                                        <table-items :props="tableProps" :items="item.services">
                                           
                                        </table-items>
                                    </v-col>
                                
                                </v-row>
                            </div>
                            
                        </v-tab-item>

                        <!-- STEP 3 "Contratación" -->
                        <v-tab-item value="3">
                           <details-item :detailsItems="operationItems"></details-item>
                        </v-tab-item>
                    </v-tabs-items>


                    </v-col>
                    
                </v-row>
               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            tab: 1,
            loading: false,
            countryValue: "",
            stateValue: "",
            city: "",
            keyCtr: 0,
            generalItems: [
            ],
            productItems:[
                // {name: "", product:[],services:[]}
            ],
            operationItems:[],

            tableProps: {
				headers: [
                     {
						text: 'Servicio',
						align: 'left break-words',
						value: 'service',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Precio',
						align: 'center',
						value: 'price',
						sortable: false,
                        width: "15%",
						class: 'table-b-border black--text'
					},
                    
                   
					
				],
				items: [],
				search: '',
				loading: false,
                sortable:false,
                
			},
           
           
            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Clients",	
                click: () => {}
            },
           
          
		}
    },
    mounted(){
       this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.client.get(this.id)
			.then((response) => {
                console.log(response);
                

                //part 1
                this.generalItems= [
                    { concepto: "Giro", index: "business", valor: response.data.clients.business??""},
                    { concepto: "Nombre comercial", index: "tradename", valor: response.data.clients.tradename??""},
                    { concepto: "Razón social", index: "business_name", valor: response.data.clients.business_name??""},
                    { concepto: "R.F.C.", index: "rfc", valor: response.data.clients.rfc??""},
                    { concepto: 'Pais', index: 'country', valor: this.countryValue??""},
                    { concepto: 'Estado', index: 'state', valor: this.countryValue??""},
                    { concepto: 'Municipio', index: 'city', valor: this.countryValue??""},
                    { concepto: "Colonia", index: "neighborhood", valor: response.data.clients.neighborhood??""},
                    { concepto: "Calle", index: "street", valor: response.data.clients.street??""},
                    { concepto: "No. Exterior", index: "external_number", valor: response.data.clients.external_number??""},
                    { concepto: "No. Interior", index: "internal_number", valor: response.data.clients.internal_number??""},
                    { concepto: "Código postal", index: "zipcode", valor: response.data.clients.zipcode??""},
                    { concepto: "Teléfono", index: "phone", valor: response.data.clients.phone??""},
                    { concepto: "Celular", index: "cell_phone", valor: response.data.clients.cell_phone??""},
                    { concepto: "Precios", index: "price_list", valor: response.data.clients.price_list??""},
                    { concepto: "Uso del CFDI", index: "cfdi", valor: response.data.clients.cfdi??""},
                    { concepto: "Cuenta bancaria", index: "bank_account", valor: response.data.clients.bank_account??""},
                    { concepto: "Clabe", index: "clabe", valor: response.data.clients.clabe??""},
                    { concepto: "Banco", index: "bank", valor: response.data.clients.bank??""},
                    { concepto: "Nombre de contacto", index: "contact_name", valor: response.data.clients.contact_name??""},
                    { concepto: "Correo electrónico", index: "email", valor: response.data.clients.email??""},
                    { concepto: "Correo electrónico para facturas", index: "invoice_email", valor: response.data.clients.invoice_email??""},
                    { concepto: "Días de crédito", index: "credit_days", valor: response.data.clients.credit_days??""},
                    { concepto: "Día de corte", index: "cutoff_date", valor: response.data.clients.cutoff_date??""},
                    
                ];

                this.getCityDetails(response.data.clients.address_code)
               
                //part 2
                for (let i = 0; i < response.data.client_products.length; i++) {
                    const element = response.data.client_products[i];
                    
                    // let item = {product:[],services:[]};

                    let item = {
                        name: element.project_name??"",
                        product: [
                            // { concepto: "Nombre del proyecto", index: "business", valor: element.project_name??""},
                            { concepto: "Descripción del proyecto", index: "business", valor: element.general_description??""},
                            { concepto: "Giro", index: "business", valor: element.business??""},
                            { concepto: "Tipo de producto", index: "business", valor: element.product_type??""},
                            { concepto: "Rotación del producto", index: "business", valor: element.product_rotation??""},
                            { concepto: "Cotización", index: "business", valor: element.folio??""},
                            { concepto: "Almacen", index: "business", valor: element.warehouse??""},
                            { concepto: "ID Cliente EON", index: "business", valor: element.client_eon_id??""},
                        ],
                        services:[]
                    };

                    element.services.forEach(serItem => {
                        item.services.push({
                            service: serItem.service, 
                            price: Utils.currencyFormat(serItem.price, ""),
                        });
                    });
                    this.productItems.push(item);
                }

                //part 3
                this.operationItems= [
                    { concepto: "Tipo de embalaje", index: "business", valor: response.data.client_operations.type??""},
                    { concepto: "Longitud", index: "business", valor: response.data.client_operations.length??""},
                    { concepto: "Anchura", index: "business", valor: response.data.client_operations.width??""},
                    { concepto: "Altura", index: "business", valor: response.data.client_operations.height??""},
                    { concepto: "Tipo de transporte", index: "business", valor: response.data.client_operations.transport??""},
                    { concepto: "Descripción del manejo del producto", index: "business", valor: response.data.client_operations.description??""},
                    { concepto: "Condiciones de almacenaje", index: "business", valor: response.data.client_operations.storage_conditions??""},
                    { concepto: "Valor de la mercancia a ingresar", index: "business", valor: response.data.client_operations.product_value??""},
                    { concepto: "Operación de comercio exterior", index: "business", valor: response.data.client_operations.foreign_commerce[`foreign trade`]??""},
                    { concepto: "Importación", index: "business", valor: response.data.client_operations.foreign_commerce[`import`]??""},
                    { concepto: "Exportación", index: "business", valor: response.data.client_operations.foreign_commerce[`export`]??""},
                    { concepto: "Catálogo de fracción arancelaria", index: "business", valor: response.data.client_operations.foreign_commerce[`catalog`]??""},
                    { concepto: "AGENTE ADUANAL", index: "business", valor: response.data.client_operations.foreign_commerce[`customs broker`]??""},
                    { concepto: "INSPECTOR DE CALIDAD", index: "business", valor: response.data.client_operations.foreign_commerce[`customs inspector`]??""},
                    { concepto: "PRADRÓN DE IMPORTADORES", index: "business", valor: response.data.client_operations.foreign_commerce[`list of importers`]??""},
                    { concepto: "MATERIAL PELIGROSO", index: "business", valor: response.data.client_operations.foreign_commerce[`dangerous material`]??""},
                    { concepto: "MERCANCÍA SENSIBLE", index: "business", valor: response.data.client_operations.foreign_commerce[`sensitive merchandise`]??""},
                    
                ]

			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},

        getCityDetails(code)
        {
            if(code == "" || code == undefined || code == null)
                return

            this.loadingCountries = true;
            this.$api.addressApi.getCity(code)
                .then((resp) => {
                    let foundCountry = this.generalItems.find(item => item.index == "country")
                    let foundState = this.generalItems.find(item => item.index == "state")
                    let foundCity = this.generalItems.find(item => item.index == "city")
                    foundCountry.valor = resp.data.city.country_code;
                    foundState.valor = resp.data.city.state_code;
                    foundCity.valor = resp.data.city.name;
                  
                    this.keyCtr = 5;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingCountries = false;
                })
            ;
        },
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 