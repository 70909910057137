<template>
	<contentCardIndex >
        <template slot="cardContent">
            <v-row align="center">
                <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                    
                    <alternativeTextField2
                        @onChanged="(v) => {trySearch(v);}" 
                        :valueInput="tableProps.search"
                        :rule="[]"
                        :placeholder="'Buscar'"
                        :label="''"
                        data-cy="search-input"
                        :prependInnerIcon="'mdi-magnify'"
                        class="pick-3"
                    ></alternativeTextField2>
                </v-col>
                <v-spacer></v-spacer>
                <v-col  v-permission="'quotations.store'" cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                    <v-row justify="end">
                        <primaryButton data-cy="create-btn" :props="createBtnData"></primaryButton>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="mt-md-0">
                <v-col class="mx-0 px-0">
                    <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler" :key="keyControl">
                        
                
                    </datatable>
                </v-col>
            </v-row>

            <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                <!-- Buttons -->
                <template slot="cardActions">
                    <v-row justify="end" data-cy="index-modal-actions">
                        <v-col cols="12" sm="4">
                            <secondaryButton
                            data-cy="cancel-modal-btn"
                            :props="cancelModal"
                            ></secondaryButton>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <primaryButton
                            data-cy="accept-modal-btn"
                            :props="acceptModal"
                            ></primaryButton>
                        </v-col>
                    </v-row>
                </template> 
            </modal>

            
            
        </template>
    </contentCardIndex>
        
</template>
<script>
// import FeePdf from "./FeePdf";
import QuotationPdf from "./QuotationPdf"
export default {
   
    data(){
		return {
           
            keyControl: 0,
			search: '',
            selectedID: '',

            loading: false,
          

          
			actions: [
                {
					name: 'PDF',
					icon: {
						color: 'secondary',
						icon: 'mdi-file-document-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect m
					action: 'getPdf',
                    loading:false,
                    permissions: 'quotations.show',
                    dataCy: "pdf-link"
				},
               
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect mdiCash
					action: '',
                    permissions: 'quotations.show',
                    dataCy: "details-link"
				},
               
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'quotations.edit',
                    dataCy: "edit-link"
				},
               
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'quotations.delete',
                    dataCy: "delete-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'folio',
                        width: "10%",
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						width: "10%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Almacén',
						align: 'left',
						value: 'warehouse',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cliente',
						align: 'left',
						value: 'client',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proyecto',
						align: 'left',
						value: 'project',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Vigencia',
						align: 'left',
						value: 'validity',
						
						class: 'table-b-border black--text'
					},
                   
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '12%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy:"sku",
                sortDesc: true,
			},
			
			
            createBtnData: {
                text: "Nueva tarifa",
                icon: "mdi-plus",
				to: "QuotationsCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},

			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false;}
            },

         
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },

		}
	},

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;

            if(this.$store.state.warehouse != "all")
                this.tableProps.headers = this.tableProps.headers.filter((item) => item.value != "warehouse")
           
			// call api
			this.$api.quotation.list(this.$store.state.warehouse)
				.then((resp) =>{
					//console.log(resp);
					this.tableProps.items = resp.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))

                        
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[1].action = { name: 'QuotationsDetails', params: { id: x.folio } }
                        x.actions[2].action = { name: 'QuotationsEdit', params: { id: x.folio } }
                       
                        //payment: ele.total_details - ele.total_payment !== 0 ? 'Sin pagar' : 'Pagada'
						x['parameters'] = { id: x.folio, name: x.folio, index: i};
                       
                        
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},

        getPdf(parameters)
		{
            this.selectedID = parameters.id;
            this.tableProps.items[parameters.index].actions[0].loading = true
			//console.log(this.selectedID);
			this.$api.quotation.pdf(this.selectedID)
                // eslint-disable-next-line
                .then( (response) => {
                    this.getAddressInfo(response.data)
                    // FeePdf.openDocument(response.data);
                    
                    // eslint-disable-next-line
                }).catch(error => {
                    this.$store.dispatch('snackbarError', `Error al generar pdf. Intenta de nuevo`);
                    
                })
                .finally(() => {
                    this.tableProps.items[parameters.index].actions[0].loading = false;
                })
            ;
		},

        getAddressInfo(pdfData)
        {
            //Av. Sierra Leona 418, Lomas 2da Secc 78210 San Luis, S.L.P.
            if(pdfData.data.address_code == ""){
                pdfData.data.address_data = {
                    state: "San Luis",
                    city: 'S.L.P',
                    street: 'Av. Sierra Leona',
                    external_number: '418',
                    internal_number: '',
                    neighborhood: 'Lomas 2da Secc',
                    zipcode: '78210'
                }
                // pdfData.data.address_data = 55
                QuotationPdf.openDocument(pdfData);
                return;
            }
            // const result = await addressAPIAxios.get(`/getCityDetails/${pdfData.address_code}`);
            this.$api.addressApi.getCity(pdfData.data.address_code)
                // eslint-disable-next-line
                .then((result) => {
                    pdfData.data.address_data = {
                        state: result.data.state?.name??'N/A',
                        city: result.data.city?.state_code??'N/A',
                        street: pdfData.data.street,
                        external_number: pdfData.data.external_number,
                        internal_number: pdfData.data.internal_number,
                        neighborhood: pdfData.data.neighborhood,
                        zipcode: pdfData.data.zipcode
                    }
                    QuotationPdf.openDocument(pdfData);
                    return;
                    // eslint-disable-next-line
                }).catch(error => {
                    pdfData.data.address_data = {
                        state: "San Luis",
                        city: 'S.L.P',
                        street: 'Av. Sierra Leona',
                        external_number: '418',
                        internal_number: '',
                        neighborhood: 'Lomas 2da Secc',
                        zipcode: '78210'
                    }
                    QuotationPdf.openDocument(pdfData);
                    return;
                })
                
            ;
           
        },

     


		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},

      

		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.quotation.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

    }
}
</script>