<template>
    <v-card height="480" class="overflow-scroll test-scroll" :loading="loading || internalLoading">
        
        <v-card-text>
            <!-- buttons -->
            <v-row>
                <!-- <v-col cols="4" class="pr-0 mx-0">
                    
                </v-col> -->
                <v-col class=" mx-0" >
                    <div class="card-top-buttons ">
                        <p class="card-title">
                            {{title}}
                        </p>
                        
                    </div>
                </v-col>
                <v-col class="text-end pt-4 px-3"  cols="3" md="3">
                
                    <alternativeButton
                        :class="`btn `"
                        :props="backBtn"
                        
                    ></alternativeButton>
                    <alternativeButton
                        :class="`ml-1 btn `"
                        :props="nextBtn"
                        
                    ></alternativeButton>
                </v-col>
                 
            </v-row>
            <!-- buttons -->
            <v-row justify="start">
                <!-- <v-col class="pt-0 mt-0 pr-0 " cols="6">
                    <alternativeAutocompleteOne
                        :items="clients"
                        :valueInput="clientSelectedId"
                        @valueChange="(v) => {clientSelectedId = v; projectSelected = ''; resetPage(); getProjects();}"
                        @onChange="(v) => {}"
                        :label="'Clientes'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 px-1" cols="6">
                    <alternativeAutocompleteOne
                        :items="projects"
                        :valueInput="projectSelected"
                        @valueChange="(v) => {projectSelected = v; resetPage(); makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Proyecto'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 pr-0" cols="4" md="3">
                    <alternativeAutocompleteOne
                        :items="years"
                        :valueInput="yearSelected"
                        @valueChange="(v) => {yearSelected = v; resetPage(); makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Año'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col>
                <v-col class="pt-0 mt-0 pr-0" cols="5" md="3">
                    <alternativeAutocompleteOne
                        :items="months"
                        :valueInput="monthSelected"
                        @valueChange="(v) => {monthSelected = v; resetPage(); makeRequest()}"
                        @onChange="(v) => {}"
                        :label="'Mes'"
                        classesContainer="width-auto mini-mode"
                        
                    ></alternativeAutocompleteOne>
                </v-col> -->
                
                
                
            </v-row>
            

            <!-- graph -->
            <v-row>
                <v-col class="pt-0 mt-0">
                    <Bar
                        :chart-options="chartOptions"
                        :chart-data="chartData"
                        :chart-id="chartId"
                        :dataset-id-key="datasetIdKey"
                        :plugins="plugins"
                        :css-classes="cssClasses"
                        :styles="styles"
                        :width="width"
                        :height="height"
                        ref="bar"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        
    </v-card>

</template>
<script>
import Utils from '../../helpers/Utils'

import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip,  BarElement, CategoryScale, LinearScale, Legend)

export default {
    props: ['valueInput', 'title','color', 'labels', 'chartDataInput', 'lastPage', 'loadData', 'loading', 'clients', 'years', 'months', 'yAxisCurrency'],
    components: { Bar },
    model: {
        prop: 'chartData',
        event: 'valueChange'
    },
   
    computed: {
        // value: {
        //     get: function() {
        //         return this.valueInput
        //     },
        //     set: function(value) {
                
        //         this.$emit('valueChange', value)
        //     }
        // },
        chartData: {
            get: function() {
                return this.chartDataInput
            },
            set: function(value) {
                
                this.$emit('valueChange', value)
            }
        },
    },
    data(){
        return{
            actualDate: null,

            projects: [],
            internalLoading: false,
            clientSelectedId: "",
            yearSelected: "",
            monthSelected:"",
            projectSelected: "",
            
            dateRange: ['2019-09-10', '2019-09-20'],
            currencySelected: "mxn",
            page: 1,
            // chartData: {
            //     labels: this.labels,
            //     datasets: [
            //         {
                        
            //             backgroundColor: this.color,
            //             data: this.values
            //         }
            //     ]
            // },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            // maxRotation: 90,
                            // minRotation: 90,
                            font: {
                                size: 9,
                            },
                            // callback: function(value) {
                            //     return Utils.currencyFormat(value,"")
                            // }
                        }
                    },
                    y: {
                        ticks: {
                            callback:(value) => {
                                if(this.yAxisCurrency)
                                    return Utils.currencyFormat(value,"");
                                return value;
                            }
                        }
                    }
                },
                plugins: {
                    // responsive: true,
                    // maintainAspectRatio: false,
                    // circumference: 180,
                    legend: {
                        display:true,
                        position: 'bottom'
                    },
                    tooltip:{
                        callbacks:{
                            title: (context) =>{
                                return context[0].label.substring(0,20)
                            }
                        }
                    }
                    
                    

                }
            },
            chartId: 'bar-chart',
            datasetIdKey: 'label',
            width: 350,
            height: 380,
            cssClasses: '',
            styles: {
                
                
            },
            plugins: [
                
            ],
            
            

            backBtn: {
                text: "",
                icon: "mdi-chevron-left",
				to: "",
				block: false,
                textClass:"",
                height: 29,
                click: ()=>{this.prevPage();}
            },
            nextBtn: {
                text: "",
                icon: "mdi-chevron-right",
				to: "",
				block: false,
                textClass:"",
                height: 29,
                click: ()=>{this.nextPage();}
            },


          

        }
    },
    mounted(){
        this.getActualDates( new Date());
        
        // if(this.loadData)
        //     this.makeRequest();
    },

    methods:{
        getProjects()
        {
            if(this.clientSelectedId == "")
                return;
            this.internalLoading = true;
            this.projects = [];

            this.$api.client.getProjectsSelect(this.clientSelectedId, this.$store.state.warehouse)
                .then((resp) => {
                    console.log(resp.data);
                    this.projects = resp.data
                    
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.internalLoading = false;
                })
            ;
        },
        resetPage()
        {
            this.page = 1;
        },
        getActualDates(dateToWork){
            this.actualDate = dateToWork;

            this.actualMonth = dateToWork.getMonth() + 1;
            this.actualYear = dateToWork.getFullYear();
            // let actualDate = new Date(actualYear, actualMonth, 1);

            this.yearSelected = ""+this.actualYear;
            this.setMonth(this.actualMonth);
            // let lastDate = Utils.addMonths(actualDate,1)
           
            // this.dateRange = [`${actualYear}-${this.getMonthText(actualMonth)}-01`, `${lastDate.getFullYear()}-${this.getMonthText(lastDate.getMonth())}-01`];
            // console.log(actualMonth, actualYear, actualDate, lastDate);
        },
        setMonth(actualMonth){
            this.monthSelected = actualMonth >= 10 ? ""+actualMonth : "0"+actualMonth;

        },
        getMonthText(monthValue){
           return monthValue >= 9 ? `${monthValue+1}`:`${monthValue+1}`
        },
        getNextRange()
        {
            let activeDate = this.dateRange[1].split("-");
            // let arrayDate = activeDate.
            let dateValue = new Date(activeDate[0], activeDate[1]-1,1);
            // let nextStart = Utils.addMonths(dateValue,1);
            this.getActualDates(dateValue);
        },
        getPrevRange()
        {
            this.actualMonth = (this.actualMonth % 12) + 1;
            console.log(this.actualMonth)
        },
        nextPage()
        {
           
            if(this.page >= this.lastPage)
                return;
            this.page = this.page + 1;
            console.log(this.page, this.lastPage)
            this.makeRequest();
        },
        prevPage()
        {
            
            if(this.page == 1)
                return;
            this.page = this.page - 1;
            console.log(this.page, this.lastPage)
            this.makeRequest();
        },
        checkClickAppend()
        {
            this.$emit('clickAppend');
        },
       
        makeRequest()
        {
            // console.log(this.dateRange, this.currencySelected);
            this.$emit('makeRequest', {clientId: this.clientSelectedId, projectId: this.projectSelected, year: this.yearSelected, month: this.monthSelected, page:this.page});
        }

    }


   
}
</script>

<style lang="scss">
    .card{
        &-title{
            color: #241764;
            font-size: 11px;
            padding-bottom: 0;
            margin-bottom: 0 !important;
            padding-top: 10px;
            padding-left: 5px;
            font-weight: bold;
        }

        &-top-buttons{
            display: flex;
            gap: 5px;
            // justify-content: end;
            justify-content: space-between !important;

            &-cont{
                display: flex;
                gap: 5px;
            }
        }
    }

    .btn-alt{
        min-width: 20px !important;
        padding: 7px !important;
        font-size: 10px !important;
    }

    .overflow-scroll{
        overflow: auto;
    }

    .mini-mode{
        height: 30px;
        align-items: center !important;

        .body-1{
            font-size: 0.65rem !important;
        }

        .v-input{
            font-size: 0.65rem !important;
        }
        
    }
   
    
</style>