<template>
    <div class="my-5">
                
        <v-row justify="center">
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.amount = v; delete serverErrors.amount}" 
                    :valueInput="values.amount" 
                    :rule="rules.amount" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="(values.amount === ''||values.amount == null || values.amount == undefined) ? '' : '$'"
                    data-cy="amount-input"
                ></textFieldForm>
            </v-col>
        </v-row>

                
    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
          
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                amount: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
            },

            
            

          
        }
    },
   
    mounted(){
       console.log(this.values)
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>