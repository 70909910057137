<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-8">
                        <div class="font-weight-bold body-1">
                            Nuevo cliente
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-form ref="form" @submit.prevent="save">
                            <client-form ref="ClientForm" :values="form" @save="save" :serverErrors="serverErrors" :stepIn="step" :stepClasses="stepClasses" @tabChanged="(val) => this.test(val)"></client-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button data-cy="cancel-btn" :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button data-cy="accept-btn" :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import Utils from '../../../helpers/Utils';
import ClientForm from "./Form.vue";
export default {
    components: {
        'client-form': ClientForm,
    },
	data(){
		return {
            step: '1',
            stepClasses: {st1: '', st2: '', st3: ''},
            loading: false,
            serverErrors: {},
            form: {
                clients: {
                    business: '',
                    tradename: '',
                    business_name: '',
                    rfc: '',
                    address_code: '',
                    neighborhood: '',
                    street: '',
                    external_number: '', 
                    internal_number: '',
                    zipcode: '',
                    phone: '',
                    cell_phone: '',
                    price_list: 'Regular',
                    cfdi: '',
                    bank_account: '',
                    clabe: '', 
                    bank_id: '',
                    contact_name: '',
                    email: '',
                    invoice_email: '',
                    credit_days: '',
                    cutoff_date: ''
                },
            
                /*client_products: {
                    project_name: '',
                    general_description: '',
                    catalog_availability: 'archivo',
                    datasheet_availability: 'archivo',
                    business: 'Industrial',
                    product_type: 'Terminado',
                    product_rotation: '',
                    services: [
                        
                    ]
                },*/
                client_products:[
                    
                ],
              
                client_operations: {
                    type_id: '',
                    length: '',
                    width: '',
                    height: '',
                    transport: '',
                    description: '',
                    storage_conditions: '',
                    product_value: '', 
                    foreign_commerce: {
                        "foreign trade": 0, // 'No' | 'Si
                        import: 0,
                        export: 0,
                        catalog: 0,
                        "customs broker": 0,
                        "customs inspector": 0,
                        "list of importers": 0,
                        "dangerous material": 0,
                        "material catalog": 'archivo',
                        "sensitive merchandise": 0
                    }
                }
                    
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "Clients",
                block:false,
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar",
                icon: "mdi-content-save-outline",	
                click: this.nextStep,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        
    },
	methods: {
        test(val){
            console.log(this.step);
           console.log(val);
            this.step = val+'';
            console.log(this.step);
        },

        /**
         * Get name attrubute from html parent
         */
        getParentName(input){
            if("name" in input.$parent.$attrs)
                return input.$parent.$attrs.name;
            else
                return this.getParentName(input.$parent);
            
        },

        /**
         * Set error class to tabs
         */
        setErrorClass(){
            this.clearTabError();
            let errors = Object.entries(this.$refs.form.errorBag);
            //console.log(this.$refs.form.inputs, errors)
            let inputs = this.$refs.form.inputs;
            //onsole.log(inputs[0].label);
            for (let index = 0; index < errors.length; index++) {
                if(errors[index][1]){
                    let found = inputs.find((inpt) => inpt._uid == errors[index][0])
                    //console.log(this.getParentName(found))
                    let parentName = this.getParentName(found);
                    if(parentName == 'p1')
                        this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                    if(parentName == 'p2')
                        this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                    if(parentName == 'p3')
                        this.stepClasses = {...this.stepClasses, st3: 'tab-error'};
                }
            }
           
        },

        /**
         * Remove error class to tabs
         */
        clearTabError(){
            this.stepClasses = {...this.stepClasses, st1: '', st2: '', st3: ''};
        },

        /**
         * Set error to specific tab
         */
        setTabErrorByStep(step){
            if(step == 1){
                this.stepClasses = {...this.stepClasses, st1: 'tab-error'};
                return;
            }
            if(step == 2){
                this.stepClasses = {...this.stepClasses, st2: 'tab-error'};
                return;
            }
            this.stepClasses = {...this.stepClasses, st3: 'tab-error'};
            
        },

        /**
         * Run to the next step
         */
        nextStep(){
           
            if(this.step == '1'){
                if(this.validate()){
                    this.clearTabError();
                    this.serverValidate('clients.');
                    return;
                }
            }else if(this.step == '2'){
                if(this.validate()){
                    this.clearTabError();
                    this.serverValidate('client_products.');
                    return;
                }
                
            }else if(this.step == '3'){
                if(this.validate()){
                    this.clearTabError();
                    this.save();
                    return;
                }
            }
            this.setErrorClass();
            this.$store.dispatch('snackbarError', `Formulario incompleto`);
           
        },

       
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},

        serverValidate(keyStep)
        {
            let form = this.prepareRequest(this.form);
            this.saveBtnData.loading = true;
            this.$store.state.loading = true;
            console.log(JSON.stringify(form));
            this.$api.client.store(form)
                .then((response) => {
                    console.log(response)

                    this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                    this.$router.push({name: 'Clients'});
                    
                })
                .catch((error) => {
                    console.log(error);
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            
                            if(("errors" in error.response.data)){
                                if(error.response.data.errors instanceof Object){
                                    Object.keys(error.response.data.errors).forEach((x) => {
                                        
                                        if(x.startsWith(keyStep)){
                                            console.log(x);
                                            // this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                            this.setTabErrorByStep(this.step);
                                        }
                                       
                                    });
                                    console.log(this.serverErrors, "server errors")
                                }else{
                                    errors.push(error.response.data.errors)
                                }

                                if(Utils.objectIsEmpty(this.serverErrors)){
                                    console.log("ok next")
                                    this.step = parseInt(this.step) + 1 +'';
                                    this.clearTabError();
                                    // this.$refs.EmployeeForm.setRules(this.step);
                                    Utils.scrollToTop();
                                }else{

                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                                
                            }
                            
                        break;
                        
                    }
                    console.log(this.serverErrors)
                    
                })
                .finally(() => {
                    this.saveBtnData.loading = false;
                    this.$store.state.loading = false;
                    
                })
            ;
        },
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                console.log(JSON.stringify(form));
                this.$api.client.store(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'Clients'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("errors" in error.response.data)){
                                    if(error.response.data.errors instanceof Object){
                                        Object.keys(error.response.data.errors).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            if(x.startsWith('clients.'))
                                                this.setTabErrorByStep('1');
                                            if(x.startsWith('client_operations.'))
                                                this.setTabErrorByStep('3');

                                            this.serverErrors = error.response.data.errors;
                                            error.response.data.errors[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.errors)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario incompleto`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));


            //step 2
            for (let i = 0; i < data.client_products.length; i++) {
                delete data.client_products[i].actions;
                delete data.client_products[i].parameters;
                if(data.client_products[i].fee_id !== '' && data.client_products[i].fee_id !== null && data.client_products[i].fee_id !== undefined){
                    console.log('ok in')
                    data.client_products[i].services = [];
                }
                for (let j = 0; j < data.client_products[i].services.length; j++) {
                    delete data.client_products[i].services[j].actions;
                    delete data.client_products[i].services[j].parameters;
                }
            }


            //step 3
            data.client_operations.foreign_commerce[`foreign trade`] = data.client_operations.foreign_commerce[`foreign trade`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`import`] = data.client_operations.foreign_commerce[`import`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`export`] = data.client_operations.foreign_commerce[`export`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`catalog`] = data.client_operations.foreign_commerce[`catalog`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`customs broker`] = data.client_operations.foreign_commerce[`customs broker`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`customs inspector`] = data.client_operations.foreign_commerce[`customs inspector`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`list of importers`] = data.client_operations.foreign_commerce[`list of importers`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`dangerous material`] = data.client_operations.foreign_commerce[`dangerous material`] == 0 ? 'NO' : 'SI';
            data.client_operations.foreign_commerce[`sensitive merchandise`] = data.client_operations.foreign_commerce[`sensitive merchandise`] == 0 ? 'NO' : 'SI';
            

            return data;
        }
    },
}
</script>

<style>

</style>