<template>
    <div>
       <v-row justify="center">
            <v-col cols="12">
                <v-row>
            <v-col  class="py-2">
                <datePickerForm
                    label="Fecha de la salida"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>
       

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Órden de compra"
                    :loading="loadingPurchaseOrders"
                    :valueInput="values.purchase_order_id"
                    :rule="rules.required"
                    data-cy="purchase_order_id-input"
                    :items="serverItems.purchaseOrders"
                    :errorMessages="serverErrors.purchase_order_id"
                    @valueChange="(v) => {values.purchase_order_id = v; getPurchaseOrderDetails(v); delete serverErrors.purchase_order_id; delete serverErrors.products}"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <fileInputForm
                    data-cy="invoice-input"
                    label="Subir imágen"
                    :multiple="false"
                    :valueInput="values.invoice"
                    :url="values.invoice_path"
                    :rule="[]"
                    @valueChange="(v) => {values.invoice = v; delete serverErrors.invoice}"
                    :errorMessages="serverErrors.invoice"
                    v-on:update:url="values.invoice_path = $event"
                ></fileInputForm>
            </v-col>
        </v-row>

            </v-col>
            
        </v-row>


        
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.products">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.products}" 
                                :valueInput="props.item.quantity" 
                                :rule="[...rules.quantity]" 
                                data-cy="table-quantity-input"
                                :errorMessages="serverErrors.sku"
                            ></textFieldForm>
                        </div>
                    </template>
                    
                </table-items>
                
            </v-col>
            <span class="red--text caption" v-if="serverErrors[`products`]">{{serverErrors[`products`]}}</span>
        </v-row>
      
      

        
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
           
           
            loadingPurchaseOrders: false,
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'EQUIPO/CONSUMIBLE',
						align: 'left break-words',
						value: 'name',
                        width: "18%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Pendiente',
						align: 'center',
						value: 'quantity',
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'total',
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
            },

            serverItems:{
               
                purchaseOrders: [],
                
            }
            

          
        }
    },
   
    mounted(){
        this.getPurchaseOrders();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        getPurchaseOrders()
        {
            this.loadingPurchaseOrders = true;
            this.$api.purchaseOrder.getSelect()
                .then((resp) => {
                    this.serverItems.purchaseOrders = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPurchaseOrders = false;
                })
            ;
        },

        getPurchaseOrderDetails(sku)
        {
            if(sku === "")
                return;

            this.values.products = [];
            this.loadingDetails = true;
            this.$api.purchaseOrder.getDetails(sku)
                .then((resp) => {
                    this.values.products = resp.data.map((item,i) => {
                        // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                        return {
                            actions: JSON.parse(JSON.stringify(this.actions)),
                            parameters: { id: item.slug, name: item.sku, index: i},
                            product_id: item.product_id,
                            total: item.quantity - item.entries,
                            name: item.product,
                            quantity : item.quantity - item.entries
                        }
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingDetails = false;
                })
            ;
        },

        deleteItem(item)
        {
            this.values.products.splice(item.index, 1);
        }
       
    }
}
</script>

<style>

</style>