<template>
	<contentCardIndex >
        <template slot="cardContent">
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0  mr-md-2 ">
						<alternativeDateRangePicker
                            label="Fecha"
                            :valueInput="dateRange.start_date"
                            :rule="[]"
                            @valueChange="(v) => {dateRange.start_date = v; index(); delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                            classesContainer="width-auto"
                        ></alternativeDateRangePicker>
					</v-col>
                    <!-- <v-col cols="12" md="3" lg="3" xl="2" class="mx-0 px-0 ">
						<alternativeDateRangePicker
                            label="Fecha fin"
                            :valueInput="dateRange.end_date"
                            :rule="[]"
                            @valueChange="(v) => {dateRange.end_date = v; index(); delete serverErrors['date']}"
                            :errorMessages="serverErrors['date']"
                            classesContainer="width-auto"
                        ></alternativeDateRangePicker>
					</v-col> -->
					<v-spacer></v-spacer>
					<v-col  cols="12" md="5" lg="4" xl="3" class="px-0">
                        <v-row justify="end" class="mx-0" align="center">
                            <iconButton
                                class="mb-2 mb-md-0 mr-0 mr-md-2 flip"
                                data-cy="productSelected-input-btn"
                                :props="{
                                color:'primary',
                                iconColor: 'grey darken-3',
                                icon:'mdi-progress-download',
                                height:'38',
                                disabled:false,
                                click: () => {this.openExportModal()}
                                }"
                            ></iconButton>
                            <alternativeTextField2
                                @onChanged="(v) => {trySearch(v);}" 
                                :valueInput="tableProps.search"
                                :rule="[]"
                                :placeholder="'Buscar'"
                                :label="''"
                                
                                :prependInnerIcon="'mdi-magnify'"
                                class="pick-3 search-input-supervision"
                                data-cy="search-input"
                            ></alternativeTextField2>
                        </v-row>
					</v-col>
				</v-row>
				<v-row class="mt-md-0">
					<v-col class="mx-0 px-0">
						<datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">

                            <!-- <template v-slot:[`item.activity`]="{ item }">
                                <v-icon small :color="getActivityColor(item.activity)" >{{getActivityIcon(item.activity)}}</v-icon>
                               
                            </template> -->

                            <template v-slot:[`item.text`]="{ item }">
                                <v-chip 
                                    label
                                    
                                    :color="getREMColor(item.color)" 
                                    >
                                        <span class="white--text">{{item.text}}</span>
                                </v-chip>
                               
                            </template>

                           
                        </datatable>
					</v-col>
				</v-row>
                <!-- EXPORT FORM -->
                <modal data-cy="index-export-modal" :props="modalExportProps" @modalResponse="method_handler">
                    <!-- form -->
                    <template slot="cardBody">
                        <v-form ref="form">
                            <v-row>
                                <v-col  class="py-2">
                                    <datePickerForm
                                        label="Fecha inicial"
                                        :valueInput="dateExportRange.start_date"
                                        :rule="[...rules.required, ...rules.maxMin30Days]"
                                        @valueChange="(v) => {dateExportRange.start_date = v; this.validate()}"
                                        :errorMessages="serverErrors['date']"
                                        data-cy="date-input"
                                    ></datePickerForm>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col  class="py-2">
                                    <datePickerForm
                                        label="Fecha final"
                                        :valueInput="dateExportRange.end_date"
                                        :rule="[...rules.required, ...rules.maxMin30Days]"
                                        @valueChange="(v) => {dateExportRange.end_date = v; this.validate()}"
                                        :errorMessages="serverErrors['date']"
                                        data-cy="date-input"
                                        
                                        
                                    ></datePickerForm>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="py-2">
                                    <span class="black--text caption">El periodo a descargar no debe ser mayor a 30 días</span>
                                </v-col>
                            </v-row>

                        </v-form>
                    </template>
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>
        </template>
    </contentCardIndex>
</template>
<script>

import Utils from '../../helpers/Utils';
export default {
    data(){
		return {
            options: {},
            total: 10,
			search: '',
            selectedID: '',


            
            dateExportRange: {start_date: Utils.getStartDate30DaysBefore(), end_date: new Date().toISOString().split('T')[0]},
            dateRange: {start_date: new Date().toISOString().split('T')[0]},
			serverErrors: {},
			tableProps: {
				headers: [
					{
						text: 'Ejecutivo',
						align: 'left',
						value: 'user',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cliente',
						align: 'center',
						value: 'client',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Proyecto',
						align: 'center',
						value: 'client_product',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Almacén',
						align: 'center',
						value: 'warehouse',
						class: 'table-b-border black--text'
					},
                    {
						text: 'ENT',
						align: 'center',
						value: 'income',
						class: 'table-b-border black--text'
					},
                    {
						text: 'SAL',
						align: 'center',
						value: 'outcome',
                        
						class: 'table-b-border black--text'
					},
                     {
						text: 'SER',
						align: 'center',
						value: 'service',
                        
						class: 'table-b-border black--text'
					},
                    {
						text: 'FUL',
						align: 'center',
						value: 'fulfillments',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'REM',
						align: 'center',
						value: 'text',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Comentarios',
						align: 'center',
						value: 'comments',
						class: 'table-b-border black--text'
					},
					
				],
				items: [],
				search: '',
				loading: false,
                sortBy:"date",
                sortDesc: true,
			},

            modalExportProps: {
				visible: false,
				width: '500',
				title: 'Exportar',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
			},
			
			cancelModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalExportProps.visible = false}
            },
			acceptModal: {
                text: "Descargar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				// textColor:'grey ',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.export()}
            },

            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                maxMin30Days: [
                    v => {
                        if(v!=null && Utils.validateDateDifference(this.dateExportRange.start_date, this.dateExportRange.end_date))
                            return 'La fecha no es válida';
                      
                        return true;
                    }
                ],


            }
			
			
           

			
		}
	},

    watch: {
        // [`modalExportProps.visible`]() {
        //     try {
        //         console.log('ok');
        //         this.$refs.form.reset();
        //         this.dateExportRange = {start_date: Utils.getStartDate30DaysBefore(), end_date: new Date().toISOString().split('T')[0]};
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }
    },

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        onResize () {
            
            // if(window.innerWidth <= 960){
            //     this.exportBtnData.block = true;
            //     this.pdfBtnData.block = true;
                
            // }
            // else{
                
            //     this.exportBtnData.block = false;
            //     this.pdfBtnData.block = false;
            // }
        },

        validate () {
           
            // if(!Utils.objectIsEmpty(this.serverErrors))
            //     return false;
			return this.$refs.form.validate();
            // return true;
		},

       
        method_handler(object){
            console.log(object);
			this[object.methodName](object.parameters)
		},
        handleOptions(object){
			//console.log(object.options);
			this.options = object.options;
			this.index();
		},
        trySearch(val){
            this.tableProps.search = val;
            
            
        },
		index() {


            if(this.dateRange.start_date == '')
                return;

            
			this.tableProps.loading = true;

            if(this.$store.state.warehouse != "all")
                this.tableProps.headers = this.tableProps.headers.filter((item) => item.value != "warehouse")


           
            this.tableProps.items = [];
			// call api
			this.$api.supervision.index(this.dateRange.start_date, this.$store.state.warehouse)
				.then((resp) =>{
                   
					this.tableProps.items = resp.data.map((x) => {
						
						
                        // let users = '';
                        // x.user.forEach((usr, index) => {
                        //     users = users+`${usr}${index != (x.user.length - 1) ? ', ': ''}`;
                        // });
                        // x.userText = users;

                        // x.date = x.date.split("/").reverse().join("-");

                        // x.sale_orders = x.flag == true ? "Si" : "No";


						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},


        getREMColor(value)
        {
            switch (value) {
                case "green":
                    return "#61C04E";
                case "red":
                    return "#D13130";
                case "orange":
                    return "#F1BB46";
            
                default:
                    return "black"
            }
        },

        getActivityIcon(value)
        {
            switch (value) {
                case "active":
                    return "mdi-circle"
            
                default:
                    return "mdi-circle-outline"
            }
        },

        getformatDate(date)
        {
            return Utils.globalDateFormat(date);
        },


        openExportModal()
		{
			//console.log(parameters);
			
			this.modalExportProps = {
				...this.modalExportProps,
				text: ``,
				
			}
			this.modalExportProps.visible = true;
			
		},

        export()
        {

            if(this.validate()){
                this.acceptModal.loading = true;
                
                this.$api.supervision.exportSupervision(this.$store.state.warehouse, this.dateExportRange.start_date, this.dateExportRange.end_date)
                    .then((resp) => {
                        const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                        const fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        fileLink.setAttribute(
                        "download",
                        `supervision_${this.$store.state.warehouse}_${this.dateExportRange.start_date}_${this.dateExportRange.end_date}.xlsx`
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.acceptModal.loading = false;
                        this.modalExportProps.visible = false;
                    })
                ;
            }else{
                 this.$store.dispatch('snackbarError', `Fechas no válidas`);
            }
           
        },


        

    }
}
</script>

<style lang="scss">
    .search-input-supervision{
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            width: -webkit-fill-available;
        }
    }
</style>