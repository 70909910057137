var render = function render(){var _vm=this,_c=_vm._self._c;return _c('contentCardIndex',[_c('template',{slot:"cardContent"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"12","sm":"12","md":"5","lg":"4","xl":"3"}},[_c('v-row',{attrs:{"justify":"start","no-gutters":""}},[_c('alternativeButton',{attrs:{"data-cy":"back-btn","props":{
                                color:'primary',
                                icon:'mdi-arrow-left',
                                height:'40',
                                to: 'Prospects',
                                iconClass: 'black--text',
                                click: () => {}
                                }}}),_c('v-col',{staticClass:"ml-2"},[_c('alternativeTextField2',{staticClass:"pick-3",attrs:{"valueInput":_vm.tableProps.search,"rule":[],"placeholder":'Buscar',"label":'',"data-cy":"search-input","prependInnerIcon":'mdi-magnify'},on:{"onChanged":(v) => {_vm.trySearch(v);}}})],1)],1)],1),_c('v-spacer'),_c('v-col',{directives:[{name:"permission",rawName:"v-permission",value:('prospects.store'),expression:"'prospects.store'"}],staticClass:"mx-0",attrs:{"cols":"12","md":"3","lg":"3","xl":"2"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('primaryButton',{attrs:{"data-cy":"create-btn","props":_vm.createBtnData}})],1)],1)],1),_c('v-row',{staticClass:"mt-md-0"},[_c('v-col',{staticClass:"mx-0 px-0"},[_c('datatable',{attrs:{"data-cy":"index-table","props":_vm.tableProps},on:{"methodHandler":_vm.method_handler}})],1)],1),_c('modal',{attrs:{"data-cy":"index-modal","props":_vm.modalProps},on:{"modalResponse":_vm.method_handler}},[_c('template',{slot:"cardActions"},[_c('v-row',{attrs:{"justify":"end","data-cy":"index-modal-actions"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('secondaryButton',{attrs:{"data-cy":"cancel-modal-btn","props":_vm.cancelModal}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('primaryButton',{attrs:{"data-cy":"accept-modal-btn","props":_vm.acceptModal}})],1)],1)],1)],2)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }