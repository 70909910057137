<template>
    <div>

        <v-row v-if="edit">
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.id = v; delete serverErrors.id}" 
                    :valueInput="values.id" 
                    :rule="[]" 
                    label="Clave"
                    :errorMessages="serverErrors.id"
                    :disabled="true"
                    data-cy="id-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.price_usd = v; delete serverErrors.price_usd}" 
                    :valueInput="values.price_usd" 
                    :rule="rules.cost"
                    label="Costo en dólares (USD)"
                    :errorMessages="serverErrors.price_usd"
                    :prefix="values.price_usd === '' ? '' : '$'"
                    data-cy="price_usd-input"
                ></textFieldForm>
            </v-col>
        </v-row>

         <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.price_mxn = v; delete serverErrors.price_mxn}" 
                    :valueInput="values.price_mxn" 
                    :rule="rules.cost"
                    label="Costo en pesos (MXN)"
                    :errorMessages="serverErrors.price_mxn"
                    :prefix="values.price_mxn === '' ? '' : '$'"
                    data-cy="price_mxn-input"
                ></textFieldForm>
            </v-col>
        </v-row>
       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

               
              
                
				
            },

            

          
        }
    },
   
    mounted(){
        
    },
    methods: {
        

    }
}
</script>

<style>

</style>