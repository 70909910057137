<template>
    <div>



        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cliente"
                    :loading="loadingClients"
                    :valueInput="values.client_id"
                    :rule="rules.required"
                    :items="serverItems.clients"
                    :errorMessages="serverErrors.client_id"
                    @valueChange="(v) => {values.client_id = v; this.getProjects(v); delete serverErrors.client_id}"
                    data-cy="client_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Proyecto"
                    :loading="loadingProjects"
                    :valueInput="values.client_product_id"
                    :rule="rules.required"
                    :items="serverItems.projects"
                    :errorMessages="serverErrors.client_product_id"
                    @valueChange="(v) => {values.client_product_id = v; delete serverErrors.client_product_id}"
                    data-cy="client_product_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Tipo de contacto"
                    :valueInput="values.type"
                    :rule="rules.required"
                    :items="serverItems.contactTypes"
                    :errorMessages="serverErrors.type"
                    @valueChange="(v) => {values.type = v; delete serverErrors.type}"
                    data-cy="type-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.position = v; delete serverErrors.position}" 
                    :valueInput="values.position" 
                    :rule="[...rules.required]" 
                    label="Puesto"
                    :errorMessages="serverErrors.position"
                    data-cy="position-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.email = v; delete serverErrors.email}" 
                    :valueInput="values.email" 
                    :rule="[...rules.required, ...rules.email]" 
                    label="Correo"
                    :errorMessages="serverErrors.email"
                    data-cy="email-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.phone = v; delete serverErrors[`phone`]}" 
                    :valueInput="values.phone" 
                    :rule="[...rules.required, ...rules.onlyNumbers,...rules.limit10]" 
                    label="Teléfono"
                    :errorMessages="serverErrors[`phone`]"
                    data-cy="phone-input"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.cell_phone = v; delete serverErrors[`cell_phone`]}" 
                    :valueInput="values.cell_phone" 
                    :rule="[...rules.onlyNumbers, ...rules.limit10]" 
                    label="Celular"
                    :errorMessages="serverErrors[`cell_phone`]"
                    data-cy="cell_phone-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        

       

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            loadingClients: false,
            loadingProjects: false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                
                initial_cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],

               
                onlyNumbers: [
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }
                ],
                limit10:[
                    v => {
                        if(v === null || v.toString().trim() === '')
                            return true;
                        if(''+v.length > 10)
                            return 'Máximo 10 caracteres';
                        
                        return true;
                    }
                ],
                email: [
                    (v) => {
                        const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(v) || "Correo no válido";
                    },
                ],
				
				
            },

            serverItems:{
                clients: [],
                projects: [],
                contactTypes: [
                    {value: "manager", text:"DIRECTOR O GERENTE"},
                    {value: "trade", text:"RESPONSABLE COMERCIAL"},
                    {value: "logistic", text:"RESPONSABLE DE OPERACIONES / LOGÍSTICA"},
                    {value: "foreign", text:"RESPONSABLE DE COMERCIO EXTERIOR"},
                    {value: "invoice", text:"RESPONSABLE DE FACTURAS"},
                ]
            }
            

          
        }
    },
   
    mounted(){
        this.getClients();
        if(this.edit)
            this.getProjects(this.values.client_id);
    },
    methods: {
        getClients()
        {
            this.loadingClients = true;
            this.$api.client.getSelect(this.$store.state.warehouse)
                .then((resp) => {
                    this.serverItems.clients = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingClients = false;
                })
            ;
        },

        getProjects(client_id)
        {
            if(client_id == "" || client_id == undefined || client_id == null)
                return;

            this.loadingProjects = true;
            this.$api.client.getProjectsSelect(client_id, this.$store.state.warehouse)
                .then((resp) => {
                    this.serverItems.projects = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProjects = false;
                })
            ;
        },

       
    }
}
</script>

<style>

</style>