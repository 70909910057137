<template>
    <contentCardIndex >
        <template slot="cardContent">
                <v-row align="center">
                    <v-col cols="12" md="3" lg="3" class="mx-0 px-0">
                        
                        <alternativeTextField2
                            @onChanged="(v) => {trySearch(v);}" 
                            :valueInput="tableProps.search"
                            :rule="[]"
                            :placeholder="'Buscar'"
                            :label="''"
                            data-cy="search-input"
                            :prependInnerIcon="'mdi-magnify'"
                            class="pick-3"
                        ></alternativeTextField2>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col  cols="12" md="3" lg="3" xl="2" class="mx-0 ">
                        <v-row justify="end">
                            <iconButton
                                v-permission="'saleOrders.operations'"
                                class="mr-2 flip"
                                data-cy="activity-btn"
                                :props="{
                                    iconClass: 'grey--text text--darken-3',
                                    color:'primary',
                                    icon:'mdi-cancel',
                                    height:'36',
                                    disabled:disableActivity,
                                    click: () => {openActivityModal()}
                                }"
                            ></iconButton>
                            <primaryButton v-permission="'saleOrders.store'" data-cy="create-btn" :props="createBtnData"></primaryButton>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-md-0">
                    <v-col class="mx-0 px-0">
                        <datatable data-cy="index-table" :props="tableProps" @methodHandler="method_handler">
                            <template v-slot:item.date="props">
                                <span >{{getformatDate(props.item.date)}}</span>
                            </template>
                        </datatable>
                    </v-col>
                </v-row>

                <!-- DELETE MODAL -->
                <modal data-cy="index-modal" :props="modalProps" @modalResponse="method_handler">
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>

                <!-- Activity modal -->
                <modal data-cy="index-activity-modal" :props="modalActivityProps" @modalResponse="method_handler">
                    <template slot="cardBody">
                        <v-row class="px-0 px-md-1">
                            <v-col class="pb-6 ">
                                <span class="mb-0">
                                    HOLA <span class="font-weight-bold">{{user}}</span>, MARCA LAS CASILLAS POR PROYECTO SEGÚN CORRESPONDA PARA LAS OPERACIONES DE HOY <span class="font-weight-bold">{{getDate}}</span>
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-md-0 px-0 px-md-5">
                            <v-col class="mx-0 px-0 py-0 my-0">
                                <datatable class="activity-table" data-cy="activity-table" :props="tableActivityProps" @methodHandler="method_handler">
                                    <template v-slot:item.actions="props">
                                        <div class="center-table-item">

                                            <checkboxForm
                                                v-if="props.item.editable"
                                                :disabled="!props.item.editable"
                                                :readOnly="!props.item.editable"
                                                label=""
                                                :valueInput="props.item.checked"
                                                
                                                @valueChange="(v) => {props.item.checked = v;}"
                                                
                                                data-cy="activity-check-input"
                                            ></checkboxForm>
                                            <div class="disabled-box" v-if="!props.item.available && !props.item.editable"></div>
                                        </div>
                                    </template>

                                    <template v-slot:item.actionsExempt="props">
                                        <div class="center-table-item">

                                            <checkboxForm
                                                v-if="props.item.exempt_editable"
                                                :disabled="!props.item.exempt_editable"
                                                :readOnly="!props.item.exempt_editable"
                                                label=""
                                                :valueInput="props.item.exempt_checked"
                                                
                                                @valueChange="(v) => {props.item.exempt_checked = v;}"
                                                
                                                data-cy="activity-exempt-check-input"
                                            ></checkboxForm>
                                            <div class="disabled-box" v-if="!props.item.exempt_available && !props.item.exempt_editable"></div>
                                        </div>
                                    </template>
                                </datatable>
                            </v-col>
                        </v-row>

                    </template>
                    <!-- Buttons -->
                    <template slot="cardActions">
                        <v-row justify="end" data-cy="index-modal-actions">
                            <v-col cols="12" sm="4">
                                <secondaryButton
                                data-cy="cancel-modal-btn"
                                :props="cancelActivityModal"
                                ></secondaryButton>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <primaryButton
                                data-cy="accept-modal-btn"
                                :props="acceptActivityModal"
                                ></primaryButton>
                            </v-col>
                        </v-row>
                    </template> 
                </modal>
        </template>

        
    
    </contentCardIndex>

        
</template>
<script>
import Utils from "../../../helpers/Utils";
export default {
    data(){
		return {
            disableActivity:true,
            ut: new Utils(),
			search: '',
            selectedID: '',
			actions: [
				{
					name: 'Detalles',
					icon: {
						color: 'secondary ',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'saleOrders.show',
                    dataCy: "details-link"
				},
                
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'saleOrders.edit',
                    dataCy: "edit-link"
				},
               
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'saleOrders.delete',
                    dataCy: "delete-link"
				},
			],
			tableProps: {
				headers: [
                     {
						text: 'Folio',
						align: 'left break-words',
						value: 'sku',
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha',
						align: 'left',
						value: 'date',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Almacen',
						align: 'left',
						value: 'warehouse',
						
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cliente',
						align: 'left',
						value: 'client',
						
						class: 'table-b-border black--text'
					},
                   
                    {
						text: 'Proyecto',
						align: 'left',
						value: 'project',
						
						class: 'table-b-border black--text'
					},

                    {
						text: 'Estatus',
						align: 'left',
						value: 'type',
						
						class: 'table-b-border black--text'
					},
                   
                   
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                sortBy:"sku",
                sortDesc: true,
                
			},
            
            tableActivityProps: {
				headers: [
                    {
						text: 'Proyecto',
						align: 'left break-words',
						value: 'client',
                        sortable: false,
						class: 'table-b-border black--text'
					},
					
                   
					{
						text: 'Sin operación',
						align: 'center',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					},

                    {
						text: 'Operación exenta',
						align: 'center',
						sortable: false,
						value: 'actionsExempt',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false,
                hideFooter:true,
                elevation:0,
                disablePagination:true,
                hideHeader: false,
			},
			
            createBtnData: {
                text: "Nueva remisión",
                icon: "mdi-plus",
				to: "SaleOrdersCreate",
				block: false,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.delete()}
            },


			modalActivityProps: {
				visible: false,
				width: '600',
				title: 'Clientes sin operaciones',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				scrollable:true,
               
			},
			
			cancelActivityModal: {
                text: "Cancelar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalActivityProps.visible = false}
            },
			acceptActivityModal: {
                text: "Guardar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
				textColor:'',
                textColorLevel: '0',
				loading:false,
                click: ()=>{this.saveActivity()}
            },
		}
	},
    computed: {
        getDate: function(){
            const actualDate = new Date();
            let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
                actualDate
            );
            let mo = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                actualDate
            );
            let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(actualDate);
            let newDate = `${da}/${mo.charAt(0).toUpperCase() + mo.slice(1)}/${ye}`;
            return newDate;
            // return `${actualDate.getDate()} ${this.months[actualDate.getMonth()]} ${actualDate.getFullYear()}`;
        },
        user: function() {
            return this.$store.state.loggedUsername
        },
    },

	mounted(){
		this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
	},

    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        onResize () {
            
            if(window.innerWidth < 960){
                this.createBtnData.block = true;
                
            }
            else{
                this.createBtnData.block = false;
                
            }
        },
      
        trySearch(val){
            this.tableProps.search = val;
        },
		index() {
			this.tableProps.loading = true;

            if(this.$store.state.warehouse != "all")
                this.tableProps.headers = this.tableProps.headers.filter((item) => item.value != "warehouse")
           
			// call api
			this.$api.saleOrder.list(this.$store.state.warehouse)
				.then((resp) =>{
					//console.log(resp);
                    this.disableActivity = !resp.data.activity;
					this.tableProps.items = resp.data.data.map((x,i) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
                       
						
						// x.actions[0].action = { name: '', params: { slug: x.slug } }
                        x.actions[0].action = { name: 'SaleOrdersDetails', params: { id: x.sku } }
                        x.actions[1].action = { name: 'SaleOrdersEdit', params: { id: x.sku } }
						

						x['parameters'] = { id: x.sku, name: x.sku, index: i};

                        x.type = x.type == "complete" ? "Completa" : "Borrador";

                        // x.date = Utils.globalDateFormat(x.date);
                        x.total = Utils.currencyFormat(x.total.replace(",",""),'');
						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		
		},



		deleteItemModal(parameters)
		{
			//console.log(parameters);
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
            console.log(this.selectedID)
			this.modalProps.visible = true;
			
		},


		delete()
		{
			//console.log(this.selectedID);
			this.acceptModal.loading = true;
			this.$api.saleOrder.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se ha eliminado`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				if(error.response.status === 422){
                    this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al eliminar'}`);
                }else{
                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                }

				
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


        getformatDate(date)
        {
            return Utils.globalDateFormat(date);
        },

        openActivityModal()
        {
            this.tableActivityProps.items = [];
            this.tableActivityProps.loading = true;
            this.modalActivityProps.visible = true;
            

            this.$api.saleOrder.getProductActivity(this.$store.state.warehouse)
				.then((resp) =>{
					console.log(resp.data);
					this.tableActivityProps.items = resp.data.map((x,i) => {

						x['parameters'] = { id: x.client_product_id, name: x.client, index: i};

                        x.checked = x.checked??false;
                        x.exempt_checked = x.exempt_checked??false;

						return x;
					});
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
                    // this.modalActivityProps.loading = true;
					this.tableActivityProps.loading = false;
				})
			;
        },

        saveActivity()
        {
            this.acceptActivityModal.loading = true;
            let requestData = {
                without_operation:[],
                exempt_operation: []
            };
            // let client_product_id = [];
            this.tableActivityProps.items.forEach(elem => {
                if(elem.checked)
                    requestData.without_operation.push(elem.client_product_id);
                if(elem.exempt_checked)
                    requestData.exempt_operation.push(elem.client_product_id);
            });

            this.$api.saleOrder.storeProductActivity(this.$store.state.warehouse, requestData)
				.then(() =>{
					// console.log(resp);
                    this.modalActivityProps.visible = false;
                    this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
				})
				.catch((error) =>{
					if(error?.response?.status === 422){
                        this.$store.dispatch('snackbarError', `${error.response.data.error??'Error al actualizar'}`);
                    }else{
                        this.$store.dispatch('snackbarError', `Error al actualizar. Intenta de nuevo`);
                    }
				})
				.finally(() => {
                    // this.modalActivityProps.loading = true;
					this.acceptActivityModal.loading = false;
				})
			;
        }

    }
}
</script>


<style lang="scss">
    .activity-table table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .activity-table table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row){
        border-bottom: 2px solid rgba(0, 0, 0, 0.7) !important;
    }


    .disabled-box{
        height: 20px;
        width: 20px;
        border: 2px solid darkgray;
        border-radius: 5px;
        background-color: lightgray;
        margin-left: 2px;
    }


    .center-table-item{
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>